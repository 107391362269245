import React, { Component, Fragment } from "react";
import { Redirect, Link, history } from 'react-router-dom'
import { ReactSVG } from "react-svg";
import ReactPlayer from 'react-player'
import { connect } from "react-redux";
import {
  playPost,
  setPlayStatus,
  updatePlayer,
  analyticsPostListenDuration,
  getQueues,
  didReceiveLobbyMessage,
  getNotifications,
  getFriendshipRequests,
  getChatRooms,
  setMiniPlayerMode,
  removeFromQueue,
} from "../../redux/actions";
import ReactSlider from 'react-slider';
import QueueDialog from "../../pages/PlaylistDetail/QueueDialog";
import { Play, Pause, FastForward, Rewind, CornerDownLeft, ChevronDown, ChevronUp } from 'react-feather';
import { getLoggedInUser, getLoggedInUserPk } from './../../helpers/authUtils'
import { BASE_URL, LOBBY_WS_BASE_URL } from "../../redux/constants";
import { toast } from "react-toastify";
import { hasUnreadNotification } from "../../helpers/utils";
import { MINI_PLAYER_MODE_FULL, MINI_PLAYER_MODE_HALF } from "../../redux/player/reducers";


class MiniMediaPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pip: false,
      //playing: true,
      controls: false,
      light: false,
      volume: 1,
      muted: false,
      //played: 0,
      loaded: 0,
      //duration: 0,
      playbackRate: 1.0,
      loop: false,
      navigateToPostDetail: false,
      seekToPlayed: false,
      startTime: 0,
      didLog: false,
      showQueueDialog: false,
    };
    this.playerRef = React.createRef();

    this.notificationCountDown = 0;
    this.timer = 0;
  }


  componentDidMount() {
    this.connect();

    if (getLoggedInUserPk != null) {
      // this.props.getFriendshipRequests();
      // this.props.getNotifications();
      // this.props.getChatRooms();
    }
    // this.startUpdateNotificationTimer()
  }

  componentWillUnmount() {
    this.stopUpdateNotificationTimer()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.seek != this.props.seek) {
      // Update the progress
      console.log(' Seek to position: ' + this.props.seek);
      this.playerRef.current.seekTo(parseFloat(this.props.seek, 'fraction'))
      this.setState({ didLog: prevProps.seek == 0 ? false : true })
    }
    if (prevProps.index != this.props.index) {
      this.analyticsPostListenDuration()
    }
  }

  handlePlayPause = () => {
    //this.setState({ playing: !this.state.playing })
    const status = this.props.status == 1 ? 0 : 1;
    this.props.setPlayStatus(status);
  }

  handleMiniPlayerMode = (e) => {
    e.preventDefault();

    const mode = this.props.miniplayerMode == MINI_PLAYER_MODE_FULL ? MINI_PLAYER_MODE_HALF : MINI_PLAYER_MODE_FULL
    this.props.setMiniPlayerMode(mode, {
      callbackOnBegin: () => {
        // Update loading state?
        this.setState({ loading: true });
      },
      callbackOnFailure: (error) => {
        // Update loading state?
        this.setState({ loading: false });
      },
      callbackOnFinish: () => {
        console.log('callbackOnFinish');
      },
      callbackOnSuccess: (response) => {
      },
    })
  }

  handleQueue = (e) => {
    e.preventDefault();
    this.props.getQueues(null, false, {
      callbackOnBegin: () => {
        // Update loading state?
        this.setState({ loading: true });
      },
      callbackOnFailure: (error) => {
        // Update loading state?
        this.setState({ loading: false });
      },
      callbackOnFinish: () => {
        console.log('callbackOnFinish');
      },
      callbackOnSuccess: (response) => {
        this.setState({ showQueueDialog: true })
      },
    })
  }

  onCloseQueueDlg = () => {
    this.setState({ showQueueDialog: false })
  }

  handleStop = () => {
    this.setState({ url: null, playing: false })
    this.props.setPlayStatus(0);
    this.analyticsPostListenDuration()
  }

  handleToggleControls = () => {
    const url = this.state.url
    this.setState({
      controls: !this.state.controls,
      url: null
    }, () => this.load(url))
  }

  handleToggleLight = () => {
    this.setState({ light: !this.state.light })
  }

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop })
  }

  handleVolumeChange = e => {
    this.setState({ volume: parseFloat(e.target.value) })
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }

  handleSetPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) })
  }

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip })
  }


  handleSkipForward = () => {
    if (this.props.queueing != null) {
      this.removeQueueing()
    }
    else {
      this.getQueueing()
    }
  }

  handleSkipBack = () => {
    var seekToStart = false;
    if (this.props.played * this.props.duration > 2) {
      seekToStart = true;
    }

    if (seekToStart) {
      this.props.updatePlayer({ seek: 0, played: 0 });
    }
    else if (this.props.index > 0) {
      this.props.setPlayStatus(3);
    }
  }

  handlePlay = () => {
    console.log('onPlay')
    this.setState({ playing: true })
  }

  handleEnablePIP = () => {
    console.log('onEnablePIP')
    this.setState({ pip: true })
  }

  handleDisablePIP = () => {
    console.log('onDisablePIP')
    this.setState({ pip: false })
  }

  handlePause = () => {
    console.log('onPause')
    this.setState({ playing: false })
  }

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = percent => {
    //this.setState({ played: parseFloat(percent) / 100 })
    this.props.updatePlayer({ played: parseFloat(percent) / 100 });
  }

  handleSeekMouseUp = percent => {
    this.setState({ seeking: false })
    this.playerRef.current.seekTo(parseFloat(percent / 100, 'fraction'))
  }

  handleProgress = state => {
    //console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.props.updatePlayer({ played: state.played });
      //this.setState(state)
    }

    // if (this.state.seekToPlayed) {
    //   this.setState({ seekToPlayed: false });
    //   this.playerRef.current.seekTo(parseFloat(this.props.played, 'fraction'))
    // }

  }

  handleEnded = () => {
    console.log('onEnded')

    this.analyticsPostListenDuration()

    this.setState({ playing: this.state.loop })

    if (this.props.queueing != null) {
      this.removeQueueing()
    }
    else {
      this.getQueueing()
    }
  }

  removeQueueing = () => {
    let pk = this.props.queueing.pk
    if (pk == null) {
      return
    }

    this.props.removeFromQueue([pk], {
      callbackOnBegin: () => {
        // Update loading state?
        this.setState({ loading: true });
      },
      callbackOnFailure: (error) => {
        // show error to your users or stay quiet
      },
      callbackOnFinish: () => {
        // Update loading state?
        this.setState({ loading: false });
      },
      callbackOnSuccess: (response) => {
        if ((response.posts_in_queue ?? []).length > 0) {
          this.getQueueing()
        }
        else if (this.props.posts && (this.props.index < this.props.posts.length - 1)) {
          // Set status to next track
          this.props.setPlayStatus(2);
        }
        else {
          // Set status to not play
          this.props.setPlayStatus(0);
        }
      },
    })
  }

  getQueueing = () => {
    this.props.getQueues(null, true, {
      callbackOnBegin: () => {
        // Update loading state?
        this.setState({ loading: true });
      },
      callbackOnFailure: (error) => {
        // Update loading state?
        this.setState({ loading: false });
      },
      callbackOnFinish: () => {
        console.log('callbackOnFinish');
      },
      callbackOnSuccess: (response) => {
        if ((response.posts_in_queue ?? []).length > 0) {
          this.playQueueing()
        }
        else if (this.props.posts && (this.props.index < this.props.posts.length - 1)) {
          // Set status to next track
          this.props.setPlayStatus(2);
        }
        else {
          // Set status to not play
          this.props.setPlayStatus(0);
        }
      },
    })
  }

  playQueueing = () => {
    const posts = this.props.posts
    const index = this.props.index
    const played = this.props.played
    const visible = this.props.visible

    this.props.playPost(posts, index, played, visible)
  }

  handleDuration = (duration) => {
    console.log('onDuration', duration)
    this.setState({ duration })
    this.props.setPlayStatus(1)
    this.props.updatePlayer({ duration: duration });
  }

  timeToString = (time) => {
    const mins = (time / 60).toFixed(0);
    const secs = (time % 60).toFixed(0);
    const minsString = mins > 9 ? ('' + mins) : ('0' + mins);
    const secsString = secs > 9 ? ('' + secs) : ('0' + secs);
    return minsString + ':' + secsString;
  }

  playedTime = () => {
    if (this.props.duration > 0) {
      const playedTime = this.props.duration * this.props.played;
      return this.timeToString(playedTime);
    }
    return "00:00"
  }

  remainingTime = () => {
    if (this.props.duration > 0) {
      const playedTime = this.props.duration * this.props.played;
      const remainingTime = this.props.duration - playedTime;
      return this.timeToString(remainingTime);
    }
    return "00:00"
  }

  showPostDetail = (e) => {
    e.preventDefault();
    //this.setState({ navigateToPostDetail: true });
    const single = this.props.queueing?.post ?? (this.props && this.props.posts && (this.props.posts.length > this.props.index) ? this.props.posts[this.props.index] : null);
    if (single) {
      //return <Redirect to={{ pathname: process.env.PUBLIC_URL + "/postdetail/" + single.pk, state: { posts: this.props.posts, index: this.props.index, played: this.state.played } }} />
      this.props.history.push({
        pathname: process.env.PUBLIC_URL + "/postdetail/" + single.pk,
        state: { posts: this.props.posts, index: this.props.index, played: this.state.played }
    });
    }

  }


  startAnalytic = () => {
    this.setState({ didLog: false, startTime: Math.floor(Date.now() / 1000) })
  }

  analyticsPostListenDuration = () => {
    if (this.state.didLog == true || this.state.startTime == null) {
      return
    }

    const posts = this.props && this.props.posts ? this.props.posts : []
    const single = this.props.queueing?.post ?? (posts.length > this.props.index ? posts[this.props.index] : null);
    const pk = single && single.pk
    if (pk == null) {
      return
    }

    const params = {}
    params.start_time_epoch = this.state.startTime
    params.end_time_epoch = Math.floor(Date.now() / 1000);
    params.public_post = pk

    let percentConsumed = this.props.played.toFixed(3)
    if (percentConsumed > 0.99) {
      percentConsumed = 1
    }
    params.percent_consumed = percentConsumed

    this.props.analyticsPostListenDuration(params, {
      callbackOnBegin: () => {
        // Update loading state?
        //this.setState({ loading: true, linkedInMessage: null });
      },
      callbackOnFailure: (error) => {
        // show error to your users or stay quiet
        console.log('analyticsPostListenDuration falsed')
      },
      callbackOnFinish: () => {
        // Update loading state?
      },
      callbackOnSuccess: (response) => {
        // whatever
        console.log('analyticsPostListenDuration successed')
      },
    })
    this.setState({ didLog: true });
  }

  startUpdateNotificationTimer = () => {
    if (this.timer == 0) {
      this.notificationCountDown = 20;
      this.timer = setInterval(this.updateNotifications, 1000);
    }
  }

  stopUpdateNotificationTimer = () => {
    if (this.timer != 0) {
      clearInterval(this.timer);
    }
  }

  updateNotifications = () => {
    this.notificationCountDown -= 1;
    console.log(this.notificationCountDown)
    if (this.notificationCountDown <= 0) {
      this.notificationCountDown = 20
      if (getLoggedInUserPk != null) {
        this.props.getFriendshipRequests();
        this.props.getNotifications();
        this.props.getChatRooms(null, {
          callbackOnBegin: () => {
            // Update loading state?
            //this.setState({ loading: true, linkedInMessage: null });
          },
          callbackOnFailure: (error) => {
            // show error to your users or stay quiet
          },
          callbackOnFinish: () => {
            // Update loading state?
          },
          callbackOnSuccess: (response) => {
            this.getNextChatRooms();
          },
        });
      }
    }
  }

  renderRedirectPostDetail = () => {
    this.setState({ navigateToPostDetail: false });
    const single = this.props.queueing?.post ?? (this.props && this.props.posts && (this.props.posts.length > this.props.index) ? this.props.posts[this.props.index] : null);
    if (single) {
      return <Redirect to={{ pathname: process.env.PUBLIC_URL + "/postdetail/" + single.pk, state: { posts: this.props.posts, index: this.props.index, played: this.state.played } }} />
    }
  }

  // Lobby socket

  /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
  connect = () => {
    //let url = BASE_URL.substring(8) + 'ws/chat/' + this.state.room_guid + '/';
    const user = getLoggedInUser();

    if (user == null) {
      return
    }

    let token = user?.access ?? '';
    let url = LOBBY_WS_BASE_URL + "?bearer=" + token

    console.log(url)
    //let options = {};
    //options.headers= { 'Bearer': token };

    var ws = new WebSocket(url);
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
      console.log("connected websocket main component");

      this.setState({ ws: ws });

      that.timeout = 250; // reset timer to 250 on open of websocket connection 
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    // websocket onclose event listener
    ws.onclose = e => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      );

      that.timeout = that.timeout + that.timeout; //increment retry interval
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.toString(),
        "Closing socket"
      );

      ws.close();
    };

    ws.onmessage = (message) => {
      console.log(message);
      let data = JSON.parse(message?.data);
      if (data.type === 'receive_lobby_message') {
        this.props.didReceiveLobbyMessage(data.room);
      }
    };
  };


  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  render() {
    const { queueing, queues, friendshipRequests, notifications, hasNewNotification, rooms, hasNewMessage, miniplayerMode } = this.props;
    const posts = this.props.posts ?? []
    const index = this.props.index >= 0 ? this.props.index : 0;
    const single = queueing?.post ?? (posts.length > index ? posts[index] : null);
    const { controls, light, volume, muted, loop, playbackRate, pip } = this.state
    let playing = this.props.status == 1;
    //console.log('playing: ' + playing);
    let mediaSource = single?.stream_audio_file ?? '';
    let visible = this.props.visible;
    // 'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa-audio-only.m3u8';
    if (single == null) {
      playing = false;
      mediaSource = process.env.PUBLIC_URL + "/assets/img/player/button_pause.png";
      visible = false;
    }

    var queueExist = (queues?.posts_in_queue ?? []).length > 0

    return (
      <div className={visible ? ('footer-miniplayer-wrapper' + (miniplayerMode == MINI_PLAYER_MODE_FULL ? ' miniplayer-mode-full' : ' miniplayer-mode-half')) : 'footer-player-hidden-wrapper'}>
        {this.state.showQueueDialog &&
          <QueueDialog
            onClose={this.onCloseQueueDlg}
          />}

        {this.state.navigateToPostDetail && this.renderRedirectPostDetail()}

        <ReactPlayer
          url={mediaSource}
          ref={this.playerRef}
          width='0%'
          height='0%'
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playsinline={true}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={() => console.log('onReady')}
          onStart={() => { this.startAnalytic() }}
          onPlay={this.handlePlay}
          onEnablePIP={this.handleEnablePIP}
          onDisablePIP={this.handleDisablePIP}
          onPause={this.handlePause}
          onBuffer={() => console.log('onBuffer')}
          onSeek={e => console.log('onSeek', e)}
          onEnded={this.handleEnded}
          onError={e => console.log('onError', e)}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
        />

        {single && visible &&
          <div className={'footer-player-control-wrapper disable-dbl-tap-zoom' + (miniplayerMode == MINI_PLAYER_MODE_FULL ? ' bottom-line' : '')}>

            <div className='box-side full-width'>
              <div className={'box-center size-px-40 pointer-style ml-2'} onClick={this.handleMiniPlayerMode}>
                {this.props.miniplayerMode == MINI_PLAYER_MODE_FULL &&
                  <ChevronDown className='size-px-20 object-fit-contain'
                    src={process.env.PUBLIC_URL + "/assets/img/player/queue.png"}
                  />
                }
                {this.props.miniplayerMode == MINI_PLAYER_MODE_HALF &&
                  <ChevronUp className='size-px-20 object-fit-contain'
                    src={process.env.PUBLIC_URL + "/assets/img/player/queue.png"}
                  />
                }

              </div>

              <div className={'box-center'}>
                <button className={'sub_button disable-dbl-tap-zoom mr-3'} color='red' onClick={this.handleSkipBack}>
                  <img
                    src={process.env.PUBLIC_URL + (index == 0 ? "/assets/img/player/button_previoustrack.png" : "/assets/img/player/button_previoustrack_pressed.png")}
                  />
                </button>
                <button className={'main_button disable-dbl-tap-zoom'} onClick={this.handlePlayPause}>
                  {playing ? <img
                    src={process.env.PUBLIC_URL + "/assets/img/player/button_pause.png"}
                  /> : <img
                    src={process.env.PUBLIC_URL + "/assets/img/player/button_play.png"}
                  />}
                </button>

                <button className={'sub_button disable-dbl-tap-zoom ml-3'} onClick={this.handleSkipForward}>
                  <img
                    src={process.env.PUBLIC_URL + (posts.length - 1 > index ? "/assets/img/player/button_nexttrack_pressed.png" : "/assets/img/player/button_nexttrack.png")}
                  />
                </button>
              </div>

              <button className={'common-button disable-dbl-tap-zoom mr-2'} onClick={this.handleQueue} disabled={!queueExist}>
                {queueExist && <img className='size-px-40'
                  src={process.env.PUBLIC_URL + "/assets/img/player/queue.png"}
                />}
              </button>
            </div>

          </div>
        }

        {single && visible &&
          <div className={'footer-player-info-wrapper'}>
            {miniplayerMode == MINI_PLAYER_MODE_FULL &&
              <div className="footer-player-container" onClick={this.showPostDetail}>
                <img className='footer-player'
                  src={single?.cover_art_file} />
              </div>
            }

            {miniplayerMode == MINI_PLAYER_MODE_FULL &&
              <div className="footer-player-info">
                <div className="info-area">
                  <div className="name-area" onClick={this.showPostDetail}>
                    <span className="songname">
                      {single ? single.song_name : 'song_name'}
                    </span>

                    <span className="username">
                      {single ? single.artist_name : 'artist_name'}
                    </span>
                  </div>

                </div>
                <div className="sider-controls">
                  <div className="progress-controls">
                    <span className="time">{this.playedTime()}</span>
                    <ReactSlider
                      className="progress-slider horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      value={this.props.played * 100}
                      disabled={false}
                      ariaLabel={['Leftmost thumb', 'Middle thumb', 'Rightmost thumb']}
                      pearling
                      minDistance={10}
                      onBeforeChange={this.handleSeekMouseDown}
                      onAfterChange={this.handleSeekMouseUp}
                      onChange={this.handleSeekChange}
                      id="slider"
                    />
                    <span className="time">{this.remainingTime()}</span>
                  </div>
                </div>
              </div>
            }

          </div>}
      </div>

    );
  }

}


const mapStateToProps = (state) => {
  const { queues } = state.Queue;
  const { queueing, posts, index, duration, played, seek, visible, miniplayerMode, isOpeningQueueDialog, loading, status, error } = state.Player;
  const { rooms, hasNewMessage } = state.Chat;
  const { friendshipRequests, notifications, hasNewNotification } = state.Notification;
  return { queueing, queues, posts, index, duration, played, seek, visible, miniplayerMode, isOpeningQueueDialog, rooms, friendshipRequests, notifications, loading, status, error };
};

export default connect(mapStateToProps, {
  playPost,
  setPlayStatus,
  updatePlayer,
  analyticsPostListenDuration,
  getQueues,
  didReceiveLobbyMessage,
  getNotifications,
  getFriendshipRequests,
  getChatRooms,
  setMiniPlayerMode,
  removeFromQueue,
})(MiniMediaPlayer);
