// @flow
import React from "react";
import {
    PLAY_POST,
    PLAY_POST_SUCCESS,
    PLAY_POST_FAILED,

    SET_PLAYSTATUS,
    SET_PLAYSTATUS_SUCCESS,
    SET_PLAYSTATUS_FAILED,

    SET_MINIPLAYERVISIBLE,
    SET_MINIPLAYERVISIBLE_SUCCESS,
    SET_MINIPLAYERVISIBLE_FAILED,

    SET_MINIPLAYER_MODE,
    SET_MINIPLAYER_MODE_SUCCESS,
    SET_MINIPLAYER_MODE_FAILED,

    UPDATE_PLAYER,
    UPDATE_PLAYER_SUCCESS,
    UPDATE_PLAYER_FAILED,

} from './constants';

import {
    GET_QUEUES,
    GET_QUEUES_SUCCESS,
    GET_QUEUES_FAILED,

    REMOVE_FROM_QUEUE,
    REMOVE_FROM_QUEUE_SUCCESS,
    REMOVE_FROM_QUEUE_FAILED,
} from '../queue/constants';

export const MINI_PLAYER_MODE_FULL = 2;
export const MINI_PLAYER_MODE_HALF = 1;

const INIT_STATE = {
    posts: [],
    index: 0,
    loading: false,
    status: 0,  // 0: Pause; 1: Play; 2: Next Song; 3: Prev song
    played: 0,
    duration: 0,
    playing: false,
    visible: false,
    seek: 0,
    error: null,
    miniplayerMode: MINI_PLAYER_MODE_FULL,
    queueing: null,
};

const Player = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PLAY_POST:
            return { ...state, played: 0, duration: 0, loading: true };
        case PLAY_POST_SUCCESS:
            return {
                ...state, posts: action.payload.posts, index: action.payload.index,
                played: action.payload.played, status: 1, visible: action.payload.showMiniPlayer, loading: false, error: null
            };
        case PLAY_POST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SET_PLAYSTATUS:
            return { ...state, loading: true };
        case SET_PLAYSTATUS_SUCCESS:
            // status: 0: Pause; 1: Play; 2: Next Song; 3: Prev song
            if (action.payload == 2) {
                if (state.index < state.posts.length - 1) {
                    return { ...state, status: 1, index: state.index + 1, loading: false, error: null };
                }
                else {
                    return { ...state, loading: false };
                }
            }
            else if (action.payload == 3) {
                if (state.index > 0) {
                    return { ...state, status: 1, index: state.index - 1, loading: false, error: null };
                }
                else {
                    return { ...state, loading: false };
                }
            }
            else {
                return { ...state, status: action.payload, loading: false, error: null };
            }
        case SET_PLAYSTATUS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SET_MINIPLAYERVISIBLE:
            return { ...state, loading: true };
        case SET_MINIPLAYERVISIBLE_SUCCESS:
            return { ...state, visible: action.payload, loading: false, error: null };
        case SET_MINIPLAYERVISIBLE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SET_MINIPLAYER_MODE:
            return { ...state, loading: true };
        case SET_MINIPLAYER_MODE_SUCCESS:
            return { ...state, miniplayerMode: action.payload, loading: false, error: null };
        case SET_MINIPLAYER_MODE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPDATE_PLAYER:
            return { ...state, loading: true };
        case UPDATE_PLAYER_SUCCESS:
            const duration = (action.payload.duration != null) ? action.payload.duration : state.duration;
            var played = (action.payload.played != null) ? action.payload.played : state.played;
            const seek = (action.payload.seek != null) ? action.payload.seek : state.seek;
            return { ...state, duration: duration ? duration : 0, played: played ? played : 0, seek: seek, loading: false, error: null };
        case UPDATE_PLAYER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_QUEUES:
            return { ...state, loading: true };
        case GET_QUEUES_SUCCESS:
            if (action.payload?.updateQueueing ?? false) {
                const queues = action.payload.results.posts_in_queue ?? []
                if (queues.length > 0) {
                    return { ...state, queueing: queues[0], loading: false, error: null };
                }
            }
            return { ...state, loading: false, error: null };
        case GET_QUEUES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case REMOVE_FROM_QUEUE:
            return { ...state, loading: true };
        case REMOVE_FROM_QUEUE_SUCCESS:
            const removedQueue = action.payload;
            if (removedQueue.length == 0) {
                return { ...state, queueing: null, loading: false, error: null };
            }

            var current = state.queueing;
            if (current && current.pk && removedQueue.includes(current.pk)) {
                return { ...state, queueing: null, loading: false, error: null };
            }
            return { ...state, loading: false, error: null };
        case REMOVE_FROM_QUEUE_FAILED:
            return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Player;
