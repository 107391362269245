// @flow
import {
    PLAY_POST,
    PLAY_POST_SUCCESS,
    PLAY_POST_FAILED,

    SET_PLAYSTATUS,
    SET_PLAYSTATUS_SUCCESS,
    SET_PLAYSTATUS_FAILED,

    SET_MINIPLAYERVISIBLE,
    SET_MINIPLAYERVISIBLE_SUCCESS,
    SET_MINIPLAYERVISIBLE_FAILED,

    UPDATE_PLAYER,
    UPDATE_PLAYER_SUCCESS,
    UPDATE_PLAYER_FAILED,
    
    SET_MINIPLAYER_MODE,
    SET_MINIPLAYER_MODE_SUCCESS,
    SET_MINIPLAYER_MODE_FAILED
    
} from './constants';


export const playPost = (posts, index, played, showMiniPlayer) => ({
    type: PLAY_POST,
    payload: {posts, index, played, showMiniPlayer},
});

export const playPostSuccess = (post) => ({
    type: PLAY_POST_SUCCESS,
    payload: post,
});

export const playPostFailed = (error) => ({
    type: PLAY_POST_FAILED,
    payload: error,
});

// status: 0: Pause; 1: Play; 2: Next Song; 3: Prev song
export const setPlayStatus = ( status ) => ({
    type: SET_PLAYSTATUS,
    payload: status,
});

export const setPlayStatusSuccess = (post) => ({
    type: SET_PLAYSTATUS_SUCCESS,
    payload: post,
});

export const setPlayStatusFailed = (error) => ({
    type: SET_PLAYSTATUS_FAILED,
    payload: error,
});

export const setMiniPlayerVisible = ( visible ) => ({
    type: SET_MINIPLAYERVISIBLE,
    payload: visible,
});

export const setMiniPlayerVisibleSuccess = (visible) => ({
    type: SET_MINIPLAYERVISIBLE_SUCCESS,
    payload: visible,
});

export const setMiniPlayerVisibleFailed = (error) => ({
    type: SET_MINIPLAYERVISIBLE_FAILED,
    payload: error,
});

export const setMiniPlayerMode = ( mode ) => ({
    type: SET_MINIPLAYER_MODE,
    payload: mode,
});

export const setMiniPlayerModeSuccess = (mode) => ({
    type: SET_MINIPLAYER_MODE_SUCCESS,
    payload: mode,
});

export const setMiniPlayerModeFailed = (error) => ({
    type: SET_MINIPLAYER_MODE_FAILED,
    payload: error,
});

export const updatePlayer = ( {duration, played, seek} ) => ({
    type: UPDATE_PLAYER,
    payload: {duration, played, seek},
});

export const updatePlayerSuccess = ({duration, played, seek}) => ({
    type: UPDATE_PLAYER_SUCCESS,
    payload: {duration, played, seek},
});

export const updatePlayerFailed = (error) => ({
    type: UPDATE_PLAYER_FAILED,
    payload: error,
});