// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    GET_CHATROOMS,
    CREATE_CHATROOM,
    GET_CHATROOM_DETAILS,
    UPDATE_CHATROOM_DETAILS,
    DELETE_CHATROOM,
    PATCH_CHATROOM_MEMBERS,
    GET_MESSAGES,
    DELETE_MESSAGE,
    GET_LINKPREVIEW,
    MUTE_CHATROOM,
    UPLOAD_ATTACHMENT,
    UPDATE_LATEST_MESSAGE_IN_ROOM,
    DID_RECEIVE_LOBBY_MESSAGE,
    GET_UPLOADED_FILES,
    GET_SHARED_POSTS,
    EDIT_CAPTION,
    DELETE_ATTACHMENT,
    ADD_MESSAGES,
    RECEIVED_MESSAGES,
} from './constants';
import { BASE_URL } from './../constants';
import { getLoggedInUser } from '../../helpers/authUtils';

import {
    getChatRoomsSuccess,
    getChatRoomsFailed,
    getMessagesSuccess,
    getMessagesFailed,

    deleteMessageSuccess,
    deleteMessageFailed,

    createChatRoomSuccess,
    createChatRoomFailed,

    getChatRoomDetailsSuccess,
    getChatRoomDetailsFailed,

    updateChatRoomDetailsSuccess,
    updateChatRoomDetailsFailed,

    muteChatRoomSuccess,
    muteChatRoomFailed,

    deleteChatRoomSuccess,
    deleteChatRoomFailed,

    patchChatRoomMembersSuccess,
    patchChatRoomMembersFailed,

    getLinkPreviewSuccess,
    getLinkPreviewFailed,
    uploadAttachmentSuccess,
    uploadAttachmentFailed,
    updateLatestMessageInRoomSuccess,
    updateLatestMessageInRoomFailed,
    didReceiveLobbyMessageSuccess,
    didReceiveLobbyMessageFailed,
    getUploadedFilesSuccess,
    getUploadedFilesFailed,
    getSharedPostsSuccess,
    getSharedPostsFailed,
    editCaptionSuccess, editCaptionFailed,
    deleteAttachmentSuccess, deleteAttachmentFailed,
    addMessageSuccess, receivedMessageSuccess
} from './actions';
import { updateLobbyRoom } from '../../helpers/lobbyUtils';

/**
 * Get chat rooms
 * @param {*} payload - none
 */
function* getChatRoomsInternal({ payload: { url_slug, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = url_slug ? (url_slug.startsWith(BASE_URL) ? url_slug : BASE_URL + url_slug.substring(1)) : BASE_URL + 'chat/room/';
        const response = yield call(fetchJSON, url, options);
        yield put(getChatRoomsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getChatRoomsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Get messages
 * @param {*} payload - none
 */
function* getMessagesInternal({ payload: { room_guid, url_slug, callbacks } }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    try {
        const url = url_slug ? (url_slug.startsWith(BASE_URL) ? url_slug : BASE_URL + url_slug.substring(1)) : BASE_URL + 'chat/' + room_guid + '/';
        const response = yield call(fetchJSON, url, options);
        yield put(getMessagesSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getMessagesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Delete message
 * @param {*} payload - none
 */
function* deleteMessageInternal({ payload: { message_id, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'DELETE',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'chat/message/' + message_id + '/', options);
        yield put(deleteMessageSuccess(message_id));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteMessageFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Create chat room
 * @param {*} payload - none
 */
function* createChatRoomInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const formData = new FormData();
    const data = {};
    data.user_list = params.user_list;
    if (params.name) {
        data.name = params.name;
    }

    formData.append("data", JSON.stringify(data));
    if (params.chat_room_photo) {
        formData.append("chat_room_photo", params.chat_room_photo);
    }

    const optionsUpload = {
        body: formData,
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'chat/room/', optionsUpload);
        yield put(createChatRoomSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(createChatRoomFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Get chat room details
 * @param {*} payload - none
 */
function* getChatRoomDetailsInternal({ payload: { room_guid, url_slug, callbacks } }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    try {
        const base_url = BASE_URL + "chat/room/" + room_guid + "/"
        const url = url_slug != null ? (url_slug.startsWith(BASE_URL) ? url_slug : BASE_URL + url_slug.substring(1)) : base_url;
        const response = yield call(fetchJSON, url, options);
        yield put(getChatRoomDetailsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getChatRoomDetailsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Update chat room details
 * @param {*} payload - none
 */
function* updateChatRoomDetailsInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const formData = new FormData();
    const data = {};
    data.action = "update_meta"
    if (params.name != null) {
        data.name = params.name;
    }

    formData.append("data", JSON.stringify(data));
    if (params.chat_room_photo != null) {
        formData.append("chat_room_photo", params.chat_room_photo);
    }
    const optionsUpload = {
        body: formData,
        method: 'PATCH',
        requestToken: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'chat/room/' + params.room_guid + '/', optionsUpload);
        yield put(updateChatRoomDetailsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(updateChatRoomDetailsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Mute chat room
 * @param {*} payload - none
 */
function* muteChatRoomInternal({ payload: { guid, is_muted, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const optionsUpload = {
        body: { action: is_muted ? 'mute_notification' : 'unmute_notification' },
        method: 'PATCH',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'chat/room/' + guid + '/', optionsUpload);
        yield put(muteChatRoomSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(muteChatRoomFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Delete chat room
 * @param {*} payload - none
 */
function* deleteChatRoomInternal({ payload: { room_guid, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'DELETE',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'chat/room/' + room_guid + '/', options);
        yield put(deleteChatRoomSuccess(room_guid));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, room_guid);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteChatRoomFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Patch chat room members
 * @param {*} payload - none
 */
function* patchChatRoomMembersInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'PATCH',
        body: { action: params.action, user_list: params.user_list },
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = BASE_URL + 'chat/room/' + params.room_guid + '/';
        const response = yield call(fetchJSON, url, options);
        yield put(patchChatRoomMembersSuccess(params));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, params);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(patchChatRoomMembersFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Get link preview
 * @param {*} payload - none
 */
function* getLinkPreviewInternal({ payload: { url, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'POST',
        requestToken: false,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ url })
    };

    try {
        const api = 'https://lpdg.herokuapp.com/parse/link'
        const response = yield call(fetchJSON, api, options);
        yield put(getLinkPreviewSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getLinkPreviewFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Upload attachment
 * @param {*} payload - none
 */
function* uploadAttachmentsInternal({ payload: { uploadInfo, roomId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    try {
        var responses = [];
        for (var i = 0; i < uploadInfo.media_files.length; i++) {

            const formData = new FormData();
            const data = {};
            data.caption = uploadInfo.caption;


            formData.append("file", uploadInfo.media_files[i]);
            formData.append("caption", uploadInfo.captions[i]);
            formData.append("data", JSON.stringify(data));
            const optionsUpload = {
                body: formData,
                method: 'POST',
                requestToken: true,
                headers: { 'Content-Type': 'multipart/form-data' }
            };
            const response = yield call(fetchJSON, BASE_URL + "chat/room/" + roomId + "/upload/", optionsUpload);

            responses.push(response);

        }

        yield put(uploadAttachmentSuccess(responses));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, responses);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(uploadAttachmentFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Update latest message in room
 * @param {*} payload - room info
 */
 function* updateLatestMessageInRoomInternal({ payload: { roomId, messageId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    try {
        updateLobbyRoom(roomId, messageId)
        yield put(updateLatestMessageInRoomSuccess(roomId, messageId));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, roomId);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(updateLatestMessageInRoomFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Did receive lobby message
 * @param {*} payload - room info
 */
 function* didReceiveLobbyMessageInternal({ payload: { room } }) {
    try {
        yield put(didReceiveLobbyMessageSuccess(room));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(didReceiveLobbyMessageFailed(message));
    } 
}

/**
 * Get uploaded files in chat room
 * @param {*} payload - roomId
 */
 function* getUploadedFilesInternal({ payload: { roomId, urlSlug, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'GET',
        requestToken: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    };

    try {
        const url = urlSlug ?? (BASE_URL + 'chat/room/' + roomId + '/files/');
        const response = yield call(fetchJSON, url, options);
        yield put(getUploadedFilesSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getUploadedFilesFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Get shared posts in chat room
 * @param {*} payload - roomId
 */
 function* getSharedPostsInternal({ payload: { roomId, urlSlug, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'GET',
        requestToken: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    };

    try {
        const url = urlSlug ?? (BASE_URL + 'chat/room/' + roomId + '/posts/');
        const response = yield call(fetchJSON, url, options);
        yield put(getSharedPostsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getSharedPostsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Edit Chat message's Attachment
 * @param {*} payload - none
 */
 function* editCaptionInternal({ payload: { attachmentId, caption, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const formData = new FormData();
    formData.append("caption", caption);

    const optionsUpload = {
        body: formData,
        method: 'PATCH',
        requestToken: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'chat/file/' + attachmentId + '/', optionsUpload);
        yield put(editCaptionSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editCaptionFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Delete chat message's attachment
 * @param {*} payload - none
 */
 function* deleteAttachmentInternal({ payload: { messageId, attachmentId, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'DELETE',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        // https://ugs-backend-staging.com/chat/file/357/
        const url = BASE_URL + 'chat/file/' + attachmentId + '/';
        const response = yield call(fetchJSON, url, options);
        yield put(deleteAttachmentSuccess(messageId, attachmentId));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, messageId, attachmentId);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteAttachmentFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Add message to list
 * @param {*} payload - none
 */
 function* addMessagesInternal({ payload: { message } }) {
    yield put(addMessageSuccess(message));
}

/**
 * Received message to list
 * @param {*} payload - none
 */
 function* receivedMessagesInternal({ payload: { message } }) {
    yield put(receivedMessageSuccess(message));
}


export function* watchGetChatRooms() {
    yield takeEvery(GET_CHATROOMS, getChatRoomsInternal);
}

export function* watchGetMessages() {
    yield takeEvery(GET_MESSAGES, getMessagesInternal);
}

export function* watchDeleteMessage() {
    yield takeEvery(DELETE_MESSAGE, deleteMessageInternal);
}

export function* watchCreateChatRoom() {
    yield takeEvery(CREATE_CHATROOM, createChatRoomInternal);
}

export function* watchGetChatRoomDetails() {
    yield takeEvery(GET_CHATROOM_DETAILS, getChatRoomDetailsInternal);
}

export function* watchUpdateChatRoomDetails() {
    yield takeEvery(UPDATE_CHATROOM_DETAILS, updateChatRoomDetailsInternal);
}

export function* watchMuteChatRoom() {
    yield takeEvery(MUTE_CHATROOM, muteChatRoomInternal);
}

export function* watchDeleteChatRoom() {
    yield takeEvery(DELETE_CHATROOM, deleteChatRoomInternal);
}

export function* watchPatchChatRoomMembers() {
    yield takeEvery(PATCH_CHATROOM_MEMBERS, patchChatRoomMembersInternal);
}

export function* watchGetLinkPreview() {
    yield takeEvery(GET_LINKPREVIEW, getLinkPreviewInternal);
}

export function* watchUploadAttachment() {
    yield takeEvery(UPLOAD_ATTACHMENT, uploadAttachmentsInternal);
}

export function* watchUpdateLatestMessageInRoom() {
    yield takeEvery(UPDATE_LATEST_MESSAGE_IN_ROOM, updateLatestMessageInRoomInternal);
}

export function* watchDidReceiveLobbyMessage() {
    yield takeEvery(DID_RECEIVE_LOBBY_MESSAGE, didReceiveLobbyMessageInternal);
}

export function* watchGetUploadedFiles() {
    yield takeEvery(GET_UPLOADED_FILES, getUploadedFilesInternal);
}

export function* watchGetSharedPosts() {
    yield takeEvery(GET_SHARED_POSTS, getSharedPostsInternal);
}

export function* watchEditCaption() {
    yield takeEvery(EDIT_CAPTION, editCaptionInternal);
}

export function* watchDeleteAttachment() {
    yield takeEvery(DELETE_ATTACHMENT, deleteAttachmentInternal);
}

export function* watchAddMessages() {
    yield takeEvery(ADD_MESSAGES, addMessagesInternal);
}

export function* watchReceivedMessages() {
    yield takeEvery(RECEIVED_MESSAGES, receivedMessagesInternal);
}

function* chatSaga() {
    yield all([
        fork(watchGetChatRooms),
        fork(watchGetMessages),
        fork(watchDeleteMessage),
        fork(watchCreateChatRoom),
        fork(watchGetChatRoomDetails),
        fork(watchUpdateChatRoomDetails),
        fork(watchMuteChatRoom),
        fork(watchDeleteChatRoom),
        fork(watchPatchChatRoomMembers),
        fork(watchGetLinkPreview),
        fork(watchUploadAttachment),
        fork(watchUpdateLatestMessageInRoom),
        fork(watchDidReceiveLobbyMessage),
        fork(watchGetUploadedFiles),
        fork(watchGetSharedPosts),
        fork(watchEditCaption),
        fork(watchDeleteAttachment),
        fork(watchAddMessages),
        fork(watchReceivedMessages)
    ]);
}

export default chatSaga;
