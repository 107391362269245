// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    RESET_POSTDETAIL, GET_POSTDETAIL, GET_REVIEWS, UP_VOTE, GET_REVIEWDETAILS,
    HIDE_REPLIES, UP_VOTE_REPLY, REPLY_REVIEW, POST_REVIEW, ADD_TO_ARCHIVE, ADD_TO_PLAYLIST,
    ADD_TO_MULTIPLE_PLAYLISTS, EDIT_REVIEW, DELETE_REVIEW, EDIT_REPLY, DELETE_REPLY, GET_DIGFEEDS, GET_DIGFEEDS_BY_TAG, EDIT_POST, DELETE_POST, REPORT, GET_FOLLOWING_POSTS, FOLLOW_USER
} from './constants';
import { BASE_URL } from './../constants';
import { getLoggedInUser } from '../../helpers/authUtils';

import {
    resetPostDetailSuccess,
    resetPostDetailFailed,
    getPostDetailSuccess,
    getPostDetailFailed,
    getReviewsSuccess,
    getReviewsFailed,
    upVoteSuccess,
    upVoteFailed,
    getReviewDetailsSuccess,
    getReviewDetailsFailed,
    hideRepliesSuccess,
    hideRepliesFailed,
    upVoteReplySuccess,
    upVoteReplyFailed,
    replyReviewSuccess,
    replyReviewFailed,
    postReviewSuccess,
    postReviewFailed,
    addToArchiveSuccess,
    addToArchiveFailed,
    addToPlaylistSuccess,
    addToPlaylistFailed,
    addToMultiplePlaylistsSuccess,
    addToMultiplePlaylistsFailed,
    editReviewSuccess,
    editReviewFailed,
    deleteReviewSuccess,
    deleteReviewFailed,
    editReplySuccess,
    editReplyFailed,
    deleteReplySuccess,
    deleteReplyFailed,
    getDigFeedsSuccess,
    getDigFeedsFailed,
    getDigFeedsByTagSuccess,
    getDigFeedsByTagFailed,
    editPostSuccess,
    editPostFailed,
    deletePostSuccess,
    deletePostFailed,
    getFollowingPostsSuccess,
    getFollowingPostsFailed,
    reportPostSuccess,
    reportPostFailed,
    reportFailed,
    reportSuccess,
    followUserSuccess,
    followUserFailed,
} from './actions';
import { toast } from 'react-toastify';

/**
 * Reset post details
 * @param {*} payload - none
 */
function* resetPostDetailInternal() {
    yield put(resetPostDetailSuccess());
}

/**
 * Get profile
 * @param {*} payload - none
 */
function* getPostDetailInternal({ payload: { post_url_slug, callbacks } }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    try {
        if (callbackOnBegin) {
            yield call(callbackOnBegin);
        }
        const response = yield call(fetchJSON, BASE_URL + post_url_slug, options);
        yield put(getPostDetailSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getPostDetailFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Get reviews
 * @param {*} payload - none
 */
function* getReviewsInternal({ payload: urlslug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + urlslug, options);
        yield put(getReviewsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getReviewsFailed(message));
    }
}

/**
 * Upvote
 * @param {*} payload - none
 */
function* upVoteInternal({ payload: { parent_review_pk, action } }) {
    const options = {
        body: JSON.stringify({ "parent_review_pk": parent_review_pk, "action": action }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/vote/reviews/', options);
        yield put(upVoteSuccess({ parent_review_pk: parent_review_pk, upVote: response }));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(upVoteFailed(message));
    }
}

/**
 * Get playlists
 * @param {*} payload - none
 */
function* getReviewDetailsInternal({ payload: urlslug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = urlslug.startsWith(BASE_URL) ? urlslug : BASE_URL + urlslug.substring(1);
        const response = yield call(fetchJSON, url, options);
        yield put(getReviewDetailsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getReviewDetailsFailed(message));
    }
}

/**
 * Hide replies
 * @param {*} payload - none
 */
function* hideRepliesInternal({ payload: parent_review_pk }) {
    yield put(hideRepliesSuccess(parent_review_pk));
}

/**
 * Upvote Reply
 * @param {*} payload - none
 */
function* upVoteReplyInternal({ payload: { parent_review_pk, parent_reply_pk, action } }) {
    const options = {
        body: JSON.stringify({ "parent_reply_pk": parent_reply_pk, "action": action }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'vote/reply/', options);
        yield put(upVoteReplySuccess({ parent_review_pk: parent_review_pk, parent_reply_pk: parent_reply_pk, upVote: response }));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(upVoteReplyFailed(message));
    }
}

/**
 * Reply review
 * @param {*} payload - none
 */
function* replyReviewInternal({ payload: { parent_review_pk, comment, post_url_slug } }) {
    const options = {
        body: JSON.stringify({ "parent_review": parent_review_pk, "comment": comment }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'review-reply/', options);
        yield put(replyReviewSuccess({ parent_review_pk: parent_review_pk, reply: response }));

        // Reload post details
        //yield call(getPostDetailInternal, { payload: post_url_slug });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(replyReviewFailed(message));
    }
}

/**
 * Post review
 * @param {*} payload - none
 */
function* postReviewInternal({ payload: { parent_post, rating_given, comment, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: JSON.stringify({ "parent_post": parent_post, "rating_given": rating_given, "comment": comment }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/reviews/', options);
        yield put(postReviewSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(postReviewFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Add post to archive
 * @param {*} payload - none
 */
function* addToArchiveInternal({ payload: { pks, action, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    const options = {
        body: JSON.stringify({ "posts": pks, "action": action }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/archive/', options);
        yield put(addToArchiveSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
        toast.info(action === 'add' ? 'Added to Library' : 'Removed from Library')

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(addToArchiveFailed(message));
    }
}

/**
 * Add posts to a playlist
 * @param {*} payload - none
 */
function* addToPlaylistInternal({ payload: { postPks, playlistPk, action, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: JSON.stringify({ "parent_playlist": playlistPk, "posts_to_action": postPks, "action": action }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/edit-playlist/', options);
        yield put(addToPlaylistSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
        toast.info(action === 'add' ? 'Added to playlist' : 'Removed from playlist')
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(addToPlaylistFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Add a post to multiple playlists
 * @param {*} payload - none
 */
function* addToMultiplePlaylistsInternal({ payload: { postPk, playlistPks, action, callbacks } }) {
    const options = {
        body: { "post_to_action": postPk, "parent_playlist_list": playlistPks, "action": action },
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/edit-multiple-playlist/', options);
        yield put(addToMultiplePlaylistsSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
        toast.info(action === 'add' ? 'Added to playlists' : 'Removed from playlists')

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(addToMultiplePlaylistsFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, message);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }

    }
}


/**
 * Edit Review
 * @param {*} payload - none
 */
function* editReviewInternal({ payload: { parent_post, pk, rating, comment, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: JSON.stringify({ "rating_given": rating, "comment": comment }),
        method: 'PATCH',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/reviews/' + pk + '/', options);
        yield put(editReviewSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, parent_post);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editReviewFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, message);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }

    }
}

/**
 * Delete Review
 * @param {*} payload - none
 */
function* deleteReviewInternal({ payload: { parent_post, pk, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'DELETE',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/reviews/' + pk + '/', options);
        yield put(deleteReviewSuccess(pk));

        // Reload post details
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, parent_post);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteReviewFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Edit Reply
 * @param {*} payload - none
 */
function* editReplyInternal({ payload: { pk, comment } }) {
    const options = {
        body: JSON.stringify({ "comment": comment }),
        method: 'PATCH',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'review-reply/' + pk + '/', options);
        yield put(editReplySuccess(response));

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editReplyFailed(message));
    }
}


/**
 * Delete Reply
 * @param {*} payload - none
 */
function* deleteReplyInternal({ payload: { pk } }) {
    const options = {
        method: 'DELETE',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'review-reply/' + pk + '/', options);
        yield put(deleteReplySuccess(pk));

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteReplyFailed(message));
    }
}


/**
 * Get did feed
 * @param {*} payload - none
 */
function* getDigFeedsInternal({ payload: url_slug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = url_slug ? (url_slug.startsWith(BASE_URL) ? url_slug : BASE_URL + url_slug.substring(1)) : BASE_URL + 'music/dig/';
        const response = yield call(fetchJSON, url, options);
        yield put(getDigFeedsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getDigFeedsFailed(message));
    }
}

/**
 * Get did feed by tag
 * @param {*} payload - none
 */
function* getDigFeedsByTagInternal({ payload: { url_slug, tags } }) {
    const options = {
        method: 'POST',
        body: { type: "post", tags: tags },
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = url_slug ? (url_slug.startsWith(BASE_URL) ? url_slug : BASE_URL + url_slug.substring(1)) : BASE_URL + 'music/tags/';
        const response = yield call(fetchJSON, url, options);
        yield put(getDigFeedsByTagSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getDigFeedsByTagFailed(message));
    }
}

/**
 * Get following posts
 * @param {*} payload - none
 */
function* getFollowingPostsInternal({ payload: url_slug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = url_slug ? (url_slug.startsWith(BASE_URL) ? url_slug : BASE_URL + url_slug.substring(1)) : BASE_URL + 'music/following/';
        const response = yield call(fetchJSON, url, options);
        yield put(getFollowingPostsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getFollowingPostsFailed(message));
    }
}

function* editPostInternal({ payload: { uploadInfo, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const formData = new FormData();
    const data = {};
    if (uploadInfo.song_name) {
        data.song_name = uploadInfo.song_name;
    }
    if (uploadInfo.artist_name) {
        data.artist_name = uploadInfo.artist_name;
    }
    if (uploadInfo.tags) {
        data.tags = uploadInfo.tags;
    }
    if (uploadInfo.description) {
        data.description = uploadInfo.description;
    }

    formData.append("data", JSON.stringify(data));
    if (uploadInfo.cover_art_file) {
        formData.append("cover_art_file", uploadInfo.cover_art_file);
    }

    const optionsUpload = {
        body: formData,
        method: 'PATCH',
        requestToken: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    };

    try {
        const responseUpload = yield call(fetchJSON, BASE_URL + "music/post/public/" + uploadInfo.pk + "/", optionsUpload);
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, responseUpload);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editPostFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Delete post
 * @param {*} payload - post_url_slug
 */
function* deletePostInternal({ payload: { pk, post_url_slug } }) {
    const options = {
        method: 'DELETE',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = post_url_slug.startsWith(BASE_URL) ? post_url_slug : BASE_URL + post_url_slug.substring(1);
        const response = yield call(fetchJSON, url, options);
        yield put(deletePostSuccess(pk));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deletePostFailed(message));
    }
}

function* reportInternal({ payload: { uploadInfo, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    var route = 'report/user-report/';
    const formData = new FormData();

    formData.append("offending_user_account", uploadInfo.offending_user_account);
    formData.append("classification", uploadInfo.classification);
    if (uploadInfo.offending_post) {
        formData.append("offending_post", uploadInfo.offending_post);
        route = 'report/content/';
    }
    if (uploadInfo.offending_review) {
        formData.append("offending_review", uploadInfo.offending_review);
        route = 'report/review/';
    }
    if (uploadInfo.offending_reply) {
        formData.append("offending_reply", uploadInfo.offending_reply);
        route = 'report/reply/';
    }
    if (uploadInfo.description) {
        formData.append("description", uploadInfo.description);
    }
    if (uploadInfo.report_upload_1) {
        formData.append("report_upload_1", uploadInfo.report_upload_1);
    }
    if (uploadInfo.report_upload_2) {
        formData.append("report_upload_2", uploadInfo.report_upload_2);
    }
    if (uploadInfo.report_upload_3) {
        formData.append("report_upload_3", uploadInfo.report_upload_3);
    }
    if (uploadInfo.report_upload_4) {
        formData.append("report_upload_4", uploadInfo.report_upload_4);
    }

    const optionsUpload = {
        body: formData,
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    };

    try {
        const responseUpload = yield call(fetchJSON, BASE_URL + route, optionsUpload);
        yield put(reportSuccess(responseUpload));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, responseUpload);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(reportFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Follow user
 * @param {*} payload - none
 */
function* followUserInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'POST',
        body: { to_user_pk: params.userPk, action: params.isFollowing ? "follow" : "unfollow" },
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = BASE_URL + 'user/following/';
        const response = yield call(fetchJSON, url, options);
        yield put(followUserSuccess(params));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(followUserFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchResetPostDetails() {
    yield takeEvery(RESET_POSTDETAIL, resetPostDetailInternal);
}

export function* watchGetProfile() {
    yield takeEvery(GET_POSTDETAIL, getPostDetailInternal);
}

export function* watchgetReviews() {
    yield takeEvery(GET_REVIEWS, getReviewsInternal);
}

export function* watchUpVote() {
    yield takeEvery(UP_VOTE, upVoteInternal);
}

export function* watchGetReviewDetails() {
    yield takeEvery(GET_REVIEWDETAILS, getReviewDetailsInternal);
}

export function* watchHideReplies() {
    yield takeEvery(HIDE_REPLIES, hideRepliesInternal);
}

export function* watchUpVoteReply() {
    yield takeEvery(UP_VOTE_REPLY, upVoteReplyInternal);
}

export function* watchReplyReview() {
    yield takeEvery(REPLY_REVIEW, replyReviewInternal);
}

export function* watchPostReview() {
    yield takeEvery(POST_REVIEW, postReviewInternal);
}

export function* watchAddToArchive() {
    yield takeEvery(ADD_TO_ARCHIVE, addToArchiveInternal);
}

export function* watchAddToPlaylist() {
    yield takeEvery(ADD_TO_PLAYLIST, addToPlaylistInternal);
}

export function* watchAddToMultiplePlaylists() {
    yield takeEvery(ADD_TO_MULTIPLE_PLAYLISTS, addToMultiplePlaylistsInternal);
}

export function* watchEditReview() {
    yield takeEvery(EDIT_REVIEW, editReviewInternal);
}

export function* watchDeleteReview() {
    yield takeEvery(DELETE_REVIEW, deleteReviewInternal);
}

export function* watchEditReply() {
    yield takeEvery(EDIT_REPLY, editReplyInternal);
}

export function* watchDeleteReply() {
    yield takeEvery(DELETE_REPLY, deleteReplyInternal);
}

export function* watchGetDigFeeds() {
    yield takeEvery(GET_DIGFEEDS, getDigFeedsInternal);
}

export function* watchGetDigFeedsByTag() {
    yield takeEvery(GET_DIGFEEDS_BY_TAG, getDigFeedsByTagInternal);
}

export function* watchGetFollowingPosts() {
    yield takeEvery(GET_FOLLOWING_POSTS, getFollowingPostsInternal);
}

export function* watchEditPost() {
    yield takeEvery(EDIT_POST, editPostInternal);
}

export function* watchDeletePost() {
    yield takeEvery(DELETE_POST, deletePostInternal);
}

export function* watchReport() {
    yield takeEvery(REPORT, reportInternal);
}

export function* watchFollowUser() {
    yield takeEvery(FOLLOW_USER, followUserInternal);
}

function* postSaga() {
    yield all([
        fork(watchResetPostDetails),
        fork(watchGetProfile), fork(watchgetReviews), fork(watchUpVote),
        fork(watchGetReviewDetails), fork(watchHideReplies), fork(watchUpVoteReply),
        fork(watchReplyReview), fork(watchPostReview),
        fork(watchAddToArchive),
        fork(watchAddToPlaylist), fork(watchAddToMultiplePlaylists),
        fork(watchEditReview), fork(watchDeleteReview),
        fork(watchEditReply), fork(watchDeleteReply),
        fork(watchGetDigFeeds),
        fork(watchGetDigFeedsByTag),
        fork(watchGetFollowingPosts),
        fork(watchEditPost),
        fork(watchDeletePost),
        fork(watchReport),
        fork(watchFollowUser)]);
}

export default postSaga;
