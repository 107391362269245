import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { isMobile } from 'react-device-detect';
import ReactPlayer from "react-player";

import {
  getChatRooms,
  search,
  share
} from "../../redux/actions";
import { XCircle } from "react-feather";

export const SHARE_NOTHING = -1;
export const SHARE_POST_ACTION = 0;
export const SHARE_PROFILE_ACTION = 1;
export const SHARE_PLAYLIST_ACTION = 2;

class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
    };
    this.inputOpenFileRef = React.createRef()
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }



  onBack = () => {
    this.props.onClose()
  }


  render() {
    const bg = {
      modal: {
        background: "#000000",
        width: isMobile ? '90%' : '500px'
      },
      overlay: {
        background: "#c0c0c060"
      }
    };

    let content = (
      <Modal open={this.state.modalIsOpen} onClose={this.onBack} center
        styles={bg} showCloseIcon={true} closeIcon={<XCircle color="#ffffff"/>}>
        <ReactPlayer
          url={this.props.url ?? ''}    //"https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
          width="100%"
          height="calc(100vh - 100px)"
          controls={true}
          playing={true}
        />

      </Modal>
    );

    return <Fragment>{content}</Fragment>;
  }
}



const mapStateToProps = (state) => {
  const { rooms, loading, error } = state.Chat;
  return { rooms, loading, error };
};

export default connect(mapStateToProps, { getChatRooms, search, share })(Player);
