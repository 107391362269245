// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,

    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILED,

    LOGOUT_USER,

    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,

    REGISTER_VERIFICATION,
    REGISTER_VERIFICATION_SUCCESS,
    REGISTER_VERIFICATION_FAILED,

    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,

    CONFIRM_PASSWORD,
    CONFIRM_PASSWORD_SUCCESS,
    CONFIRM_PASSWORD_FAILED,

    RESEND_CODE,
    RESEND_CODE_SUCCESS,
    RESEND_CODE_FAILED,
    
} from './constants';

import { getLoggedInUser, setLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    profile: null,
    loading: false,
};


const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case LOGIN_USER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case REFRESH_TOKEN:
            return { ...state, loading: true };
        case REFRESH_TOKEN_SUCCESS:
            let current = state.user;
            if (action.payload.access) {
                current.access = action.payload.access;
            }
            setLoggedInUser(current)
            return { ...state, user: current, loading: false, error: null };
        case REFRESH_TOKEN_FAILED:
            return { ...state, error: action.payload, loading: false };

        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, error: null };
        case REGISTER_USER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case REGISTER_VERIFICATION:
            return { ...state, loading: true };
        case REGISTER_VERIFICATION_SUCCESS:
            return { ...state, loading: false, error: null };
        case REGISTER_VERIFICATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case LOGOUT_USER:
            return { ...state, user: null };

        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };
        case FORGET_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CONFIRM_PASSWORD:
            return { ...state, loading: true };
        case CONFIRM_PASSWORD_SUCCESS:
            return { ...state, passwordConfirmStatus: action.payload, loading: false, error: null };
        case CONFIRM_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case RESEND_CODE:
            return { ...state, loading: true };
        case RESEND_CODE_SUCCESS:
            return { ...state, loading: false, error: null };
        case RESEND_CODE_FAILED:
            return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Auth;
