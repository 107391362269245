// @flow
import { act } from 'react-dom/test-utils';
import {
    ADD_TO_QUEUE,
    ADD_TO_QUEUE_SUCCESS,
    ADD_TO_QUEUE_FAILED,

    REMOVE_FROM_QUEUE,
    REMOVE_FROM_QUEUE_SUCCESS,
    REMOVE_FROM_QUEUE_FAILED,

    MOVE_QUEUE,
    MOVE_QUEUE_SUCCESS,
    MOVE_QUEUE_FAILED,

    GET_QUEUES,
    GET_QUEUES_SUCCESS,
    GET_QUEUES_FAILED

} from './constants';

const INIT_STATE = {
    queues: null,
    loading: false,
    error: null
};


const Queue = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_TO_QUEUE:
            return { ...state, loading: true };
        case ADD_TO_QUEUE_SUCCESS:            
            return { ...state, queues: action.payload, loading: false, error: null };
        case ADD_TO_QUEUE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_QUEUES:
            return { ...state, loading: true };
        case GET_QUEUES_SUCCESS:
            var current = state.queues;
            var results = action.payload.results;
            if (current) {
                current.next_page = results.next_page;
                current.previous_page = results.previous_page;
                if (results.previous_page == null) {
                    current.posts_in_queue = []
                }

                results.posts_in_queue.forEach(post => {
                    var isExisting = false;
                    for (var i = 0; i < current.posts_in_queue.length; i++) {
                        if (current.posts_in_queue[i].pk == post.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        current.posts_in_queue.push(post);
                    }
                });
            }
            else {
                current = results;
            }
            return { ...state, queues: current, loading: false, error: null };
        case GET_QUEUES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case REMOVE_FROM_QUEUE:
            return { ...state, loading: true };
        case REMOVE_FROM_QUEUE_SUCCESS:
            var current = state.queues;
            if (current) {
                const removedQueue = action.payload
                if (removedQueue.length == 0) {
                    current.posts_in_queue = []
                }
                else {
                    removedQueue.forEach(postPk => {
                        current.posts_in_queue = current.posts_in_queue.filter(function (post) {
                            return post.pk != postPk;
                        });
                    });
                }
                return { ...state, queues: current, loading: false, error: null };
            }
            return { ...state, loading: false, error: null };
        case REMOVE_FROM_QUEUE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case MOVE_QUEUE:
            const { params } = action.payload
            var current = state.queues;
            if (current) {
                let pk = params.queue_song_pk;
                let position = params.to_position;

                const sources = current.posts_in_queue.filter(function (post) {
                    return post.pk == pk;
                });

                if (sources.length > 0) {
                    const sourceItem = sources[0]

                    current.posts_in_queue = current.posts_in_queue.filter(function (post) {
                        return post.pk != pk;
                    });

                    if (position != null) {
                        current.posts_in_queue.splice(position, 0, sourceItem)
                    }
                    else {
                        current.posts_in_queue.splice(0, 0, sourceItem)

                    }
                }

                return { ...state, queues: current, loading: true };
            }
            return { ...state, queues: current, loading: true };
        case MOVE_QUEUE_SUCCESS:
            // var current = state.queues;
            // if (current) {
            //     let pk = action.payload.queue_song_pk;
            //     let position = action.payload.to_position;

            //     const sources = current.posts_in_queue.filter(function (post) {
            //         return post.pk == pk;
            //     });

            //     if (sources.length > 0) {
            //         const sourceItem = sources[0]

            //         current.posts_in_queue = current.posts_in_queue.filter(function (post) {
            //             return post.pk != pk;
            //         });

            //         if (position != null) {
            //             current.posts_in_queue.splice(position, 0, sourceItem)
            //         }
            //         else {
            //             current.posts_in_queue.splice(0, 0, sourceItem)

            //         }
            //     }

            //     return { ...state, queues: current, loading: false, error: null };
            // }
            return { ...state, loading: false, error: null };
        case MOVE_QUEUE_FAILED:
            return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Queue;
