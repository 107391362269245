// @flow

import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Profile from './profile/reducers';
import Post from './post/reducers';
import Playlist from './playlist/reducers';
import Notification from './notification/reducers';
import Player from './player/reducers';
import Support from './support/reducers';
import Search from './search/reducers';
import Analytic from './analytic/reducers';
import Queue from './queue/reducers';
import Chat from './chat/reducers';

export default combineReducers({
    Auth,
    Profile,
    Post,
    Playlist,
    Notification,
    Player,
    Support,
    Search,
    Analytic,
    Queue,
    Chat
});
