// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    GET_PLAYLISTDETAIL, CREATE_PLAYLIST, EDIT_PLAYLIST_DETAILS, DELETE_PLAYLIST, HEART_PLAYLIST, MOVE_PLAYLIST_SONG
} from './constants';
import { BASE_URL } from './../constants';

import {
    getPlaylistDetailSuccess,
    getPlaylistDetailFailed,
    createPlaylistSuccess,
    createPlaylistFailed,
    editPlaylistDetailsSuccess,
    editPlaylistDetailsFailed,
    deletePlaylistSuccess,
    deletePlaylistFailed,
    heartPlaylistSuccess,
    heartPlaylistFailed,
    movePlaylistSongSuccess,
    movePlaylistSongFailed
} from './actions';

/**
 * Get profile
 * @param {*} payload - none
 */
function* getPlaylistDetailInternal({ payload: url_slug, callbacks }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    try {
        const url = url_slug.startsWith(BASE_URL) ? url_slug : BASE_URL + url_slug.substring(1);
        const response = yield call(fetchJSON, url, options);
        yield put(getPlaylistDetailSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getPlaylistDetailFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Create playlist
 * @param {*} payload - none
 */
function* createPlaylistInternal({ payload: { playlist, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const formData = new FormData();
    const data = {};
    data.playlist_name = playlist.playlist_name;
    data.is_public = playlist.is_public;
    if (playlist.tags) {
        data.tags = playlist.tags;
    }
   
    formData.append("playlist_cover_art_file", playlist.playlist_cover_art_file);
    formData.append("data", JSON.stringify(data));
    const optionsUpload = {
        body: formData,
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/playlist/', optionsUpload);
        yield put(createPlaylistSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(createPlaylistFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Edit playlist
 * @param {*} payload - none
 */
 function* editPlaylistDetailsInternal({ payload: { playlist, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const formData = new FormData();
    const data = {};
    if (playlist.playlist_name != null) {
        data.playlist_name = playlist.playlist_name;
    }
    if (playlist.tags != null) {
        data.tags = playlist.tags;
    }
    if (playlist.is_public != null) {
        data.is_public = playlist.is_public;
    }
    if (playlist.playlist_cover_art_file != null) {
        formData.append("playlist_cover_art_file", playlist.playlist_cover_art_file);
    }  
    formData.append("data", JSON.stringify(data));
    const optionsUpload = {
        body: formData,
        method: 'PATCH',
        requestToken: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/playlist/edit-meta/' + playlist.pk + '/', optionsUpload);
        yield put(editPlaylistDetailsSuccess(response, playlist.pk));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(editPlaylistDetailsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Delete playlist
 * @param {*} payload - none
 */
 function* deletePlaylistInternal({ payload: { playlistPk, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'DELETE',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/playlist/' + playlistPk + '/', options);
        yield put(deletePlaylistSuccess(playlistPk));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deletePlaylistFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Heart playlist
 * @param {*} payload - none
 */
 function* heartPlaylistInternal({ payload: { playlistPk, heart, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const optionsUpload = {
        body: JSON.stringify({ "playlist_pk":playlistPk, "action": heart ? 'heart' : 'unheart'}),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'music/heart-playlist/', optionsUpload);
            yield put(heartPlaylistSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(heartPlaylistFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Move Playlist Song
 * @param {*} payload - none
 */
 function* movePlaylistSongInternal({ payload: {params, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    var dictionary = {parent_playlist:params.parent_playlist, playlist_song_pk:params.playlist_song_pk}
    if (params.to_position != null) {
        dictionary.action = 'move'
        dictionary.to_position = params.to_position
    }
    else {
        dictionary.action = 'move_to_top'
    }

    const options = {
        method: 'POST',
        body: dictionary,
        requestToken: true,
        headers: { 'Content-Type': 'application/json'}
    };

    try {
        const url = BASE_URL + 'music/edit-playlist/';
        const response = yield call(fetchJSON, url, options);
        yield put(movePlaylistSongSuccess(params));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(movePlaylistSongFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


export function* watchGetPlaylistDetail() {
    yield takeEvery(GET_PLAYLISTDETAIL, getPlaylistDetailInternal);
}

export function* watchCreatePlaylist() {
    yield takeEvery(CREATE_PLAYLIST, createPlaylistInternal);
}

export function* watchEditPlaylistDetails() {
    yield takeEvery(EDIT_PLAYLIST_DETAILS, editPlaylistDetailsInternal);
}

export function* watchDeletePlaylist() {
    yield takeEvery(DELETE_PLAYLIST, deletePlaylistInternal);
}

export function* watchHeartPlaylist() {
    yield takeEvery(HEART_PLAYLIST, heartPlaylistInternal);
}

export function* watchMovePlaylistSong() {
    yield takeEvery(MOVE_PLAYLIST_SONG, movePlaylistSongInternal);
}

function* playlistSaga() {
    yield all([
        fork(watchGetPlaylistDetail), 
        fork(watchCreatePlaylist), 
        fork(watchEditPlaylistDetails), 
        fork(watchDeletePlaylist), 
        fork(watchHeartPlaylist),
        fork(watchMovePlaylistSong)
    ]);
}

export default playlistSaga;
