// @flow
import {
    ADD_TO_QUEUE,
    ADD_TO_QUEUE_SUCCESS,
    ADD_TO_QUEUE_FAILED,

    REMOVE_FROM_QUEUE,
    REMOVE_FROM_QUEUE_SUCCESS,
    REMOVE_FROM_QUEUE_FAILED,

    MOVE_QUEUE,
    MOVE_QUEUE_SUCCESS,
    MOVE_QUEUE_FAILED,

    GET_QUEUES,
    GET_QUEUES_SUCCESS,
    GET_QUEUES_FAILED

} from './constants';


//-- Add to Queue --
export const addToQueue = (postPks,callbacks) => ({
    type: ADD_TO_QUEUE,
    payload: {postPks, callbacks},
});

export const addToQueueSuccess = (status) => ({
    type: ADD_TO_QUEUE_SUCCESS,
    payload: status,
});

export const addToQueueFailed = (error) => ({
    type: ADD_TO_QUEUE_FAILED,
    payload: error,
});
//----


//-- Remove from Queue --
export const removeFromQueue = (postPks,callbacks) => ({
    type: REMOVE_FROM_QUEUE,
    payload: {postPks, callbacks},
});

export const removeFromQueueSuccess = (status) => ({
    type: REMOVE_FROM_QUEUE_SUCCESS,
    payload: status,
});

export const removeFromQueueFailed = (error) => ({
    type: REMOVE_FROM_QUEUE_FAILED,
    payload: error,
});
//----


//-- Move Queue --
export const moveQueue = (params, callbacks) => ({
    type: MOVE_QUEUE,
    payload: {params, callbacks},
});

export const moveQueueSuccess = (status) => ({
    type: MOVE_QUEUE_SUCCESS,
    payload: status,
});

export const moveQueueFailed = (error) => ({
    type: MOVE_QUEUE_FAILED,
    payload: error,
});
//----


//-- Get Queues --
export const getQueues = (url_slug, updateQueueing, callbacks) => ({
    type: GET_QUEUES,
    payload: {url_slug, updateQueueing, callbacks},
});

export const getQueuesSuccess = (results, updateQueueing) => ({
    type: GET_QUEUES_SUCCESS,
    payload: {results, updateQueueing},
});

export const getQueuesFailed = (error) => ({
    type: GET_QUEUES_FAILED,
    payload: error,
});
//----