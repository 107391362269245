// @flow
import {
    GET_PROFILE,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAILED,

    GET_VAULT,
    GET_VAULT_SUCCESS,
    GET_VAULT_FAILED,

    GET_ARCHIVE,
    GET_ARCHIVE_SUCCESS,
    GET_ARCHIVE_FAILED,

    GET_PLAYLISTS,
    GET_PLAYLISTS_SUCCESS,
    GET_PLAYLISTS_FAILED,

    GET_OWNED_PLAYLISTS,
    GET_OWNED_PLAYLISTS_SUCCESS,
    GET_OWNED_PLAYLISTS_FAILED,

    GET_USER_REVIEWS,
    GET_USER_REVIEWS_SUCCESS,
    GET_USER_REVIEWS_FAILED,

    GET_FRIENDLIST,
    GET_FRIENDLIST_SUCCESS,
    GET_FRIENDLIST_FAILED,

    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILED,

    UPLOAD_MUSIC,
    UPLOAD_MUSIC_SUCCESS,
    UPLOAD_MUSIC_FAILED,

    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILED,

    MODIFY_USER_FRIENDSHIP,
    MODIFY_USER_FRIENDSHIP_SUCCESS,
    MODIFY_USER_FRIENDSHIP_FAILED,

    SUBSCRIBE,
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_FAILED,

    UNSUBSCRIBE,
    UNSUBSCRIBE_SUCCESS,
    UNSUBSCRIBE_FAILED,

} from './constants';

import {
    ADD_TO_ARCHIVE,
    ADD_TO_ARCHIVE_SUCCESS,
    ADD_TO_ARCHIVE_FAILED,

    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILED,

    FOLLOW_USER,
    FOLLOW_USER_SUCCESS,
    FOLLOW_USER_FAILED

} from '../post/constants';

import {

    EDIT_PLAYLIST_DETAILS,
    EDIT_PLAYLIST_DETAILS_SUCCESS,
    EDIT_PLAYLIST_DETAILS_FAILED,

    DELETE_PLAYLIST,
    DELETE_PLAYLIST_SUCCESS,
    DELETE_PLAYLIST_FAILED


} from '../playlist/constants';

const INIT_STATE = {
    profile: null,
    vaults: null,
    archives: null,
    playlists: null,
    ownedPlaylists: null,
    reviews: null,
    friendlist: null,
    loading: false,
    loadingVaults: false,
    loadingArchives: false,
    loadingPlaylists: false,
    loadingOwnedPlaylists: false,
    loadingReviews: false,
    error: null,
    errorUploading: null
};


const Profile = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROFILE:
            return {
                ...state, profile: null,
                vaults: null,
                archives: null,
                playlists: null,
                reviews: null, loading: true
            };
        case GET_PROFILE_SUCCESS:
            return { ...state, profile: action.payload, loading: false, error: null };
        case GET_PROFILE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_VAULT:
            return { ...state, loadingVaults: true };
        case GET_VAULT_SUCCESS:
            var currentVaults = state.vaults;
            if (currentVaults) {
                currentVaults.next_page = action.payload.next_page;
                currentVaults.previous_page = action.payload.previous_page;
                if (action.payload.previous_page == null) {
                    currentVaults.posts_in_vault = []
                }
                //currentVaults.posts_in_vault = currentVaults.posts_in_vault.concat(action.payload.posts_in_vault);
                action.payload.posts_in_vault.forEach(post => {
                    var isExisting = false;
                    for (var i = 0; i < currentVaults.posts_in_vault.length; i++) {
                        if (currentVaults.posts_in_vault[i].post.pk == post.post.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        currentVaults.posts_in_vault.push(post);
                    }
                });
            }
            else {
                currentVaults = action.payload;
            }

            return { ...state, vaults: currentVaults, loadingVaults: false, error: null };
        case GET_VAULT_FAILED:
            return { ...state, error: action.payload, loadingVaults: false };

        case GET_ARCHIVE:
            return { ...state, loadingArchives: true };
        case GET_ARCHIVE_SUCCESS:
            var currentArchives = state.archives;
            if (currentArchives) {
                currentArchives.next_page = action.payload.next_page;
                currentArchives.previous_page = action.payload.previous_page;
                if (action.payload.previous_page == null) {
                    currentArchives.posts_in_archive = []
                }
                //currentArchives.posts_in_archive = currentArchives.posts_in_archive.concat(action.payload.posts_in_archive);
                action.payload.posts_in_archive.forEach(post => {
                    var isExisting = false;
                    for (var i = 0; i < currentArchives.posts_in_archive.length; i++) {
                        if (currentArchives.posts_in_archive[i].post.pk == post.post.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        currentArchives.posts_in_archive.push(post);
                    }
                });
            }
            else {
                currentArchives = action.payload;
            }

            return { ...state, archives: currentArchives, loadingArchives: false, error: null };
        case GET_ARCHIVE_FAILED:
            return { ...state, error: action.payload, loadingArchives: false };

        case ADD_TO_ARCHIVE:
            return { ...state, loading: true };
        case ADD_TO_ARCHIVE_SUCCESS:
            const response = action.payload
            if (response.action == null) {
                return { ...state, loading: false, error: null };
            }
            const pks = response.posts ? response.posts : [];

            currentArchives = state.archives;
            if (currentArchives?.posts_in_archive) {
                for (let i = 0; i < pks.length; i++) {
                    let index = currentArchives.posts_in_archive.findIndex((post) => { return post.post?.pk == pks[i] })
                    if (index >= 0) {
                        currentArchives.posts_in_archive.splice(index, 1);
                    }
                }
            }
            return { ...state, archives: currentArchives, loading: false, error: null };
        case ADD_TO_ARCHIVE_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };

        case GET_PLAYLISTS:
            if (action.isFirstPage) {
                return { ...state, playlists: null, loadingPlaylists: true };
            }
            else {
                return { ...state, loadingPlaylists: true };
            }
        case GET_PLAYLISTS_SUCCESS:
            var currentPlaylists = state.playlists;
            if (currentPlaylists) {
                currentPlaylists.next_page = action.payload.next_page;
                currentPlaylists.previous_page = action.payload.previous_page;
                if (action.payload.previous_page == null) {
                    currentPlaylists.results = []
                }
                //currentPlaylists.results = currentPlaylists.results.concat(action.payload.results);

                action.payload.results.forEach(element => {
                    var isExisting = false;
                    for (var i = 0; i < currentPlaylists.results.length; i++) {
                        if (currentPlaylists.results[i].pk == element.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        currentPlaylists.results.push(element);
                    }
                });

            }
            else {
                currentPlaylists = action.payload;
            }
            return { ...state, playlists: currentPlaylists, loadingPlaylists: false, error: null };
        case GET_PLAYLISTS_FAILED:
            return { ...state, error: action.payload, loadingPlaylists: false };


        case EDIT_PLAYLIST_DETAILS:
            return { ...state, loading: true };
        case EDIT_PLAYLIST_DETAILS_SUCCESS:
            var currentPlaylists = state.playlists;
            if (currentPlaylists) {
                let playlistPk = action.pk
                let newDetails = action.payload
                for (var i = 0; i < currentPlaylists.results.length; i++) {
                    if (currentPlaylists.results[i].pk == playlistPk) {
                        currentPlaylists.results[i].is_public = newDetails.is_public
                        currentPlaylists.results[i].playlist_cover_art_file = newDetails.playlist_cover_art_file
                        currentPlaylists.results[i].playlist_name = newDetails.playlist_name
                        currentPlaylists.results[i].tags = newDetails.tags
                        break;
                    }
                }
            }
            return { ...state, playlists: currentPlaylists, loadingPlaylists: false, error: null };
        case EDIT_PLAYLIST_DETAILS_FAILED:
            return { ...state, error: action.payload, loadingPlaylists: false };


        case DELETE_PLAYLIST:
            return { ...state, loading: true };
        case DELETE_PLAYLIST_SUCCESS:
            var currentPlaylists = state.playlists;
            if (currentPlaylists) {
                let playlistPk = action.payload
                for (var i = 0; i < currentPlaylists.results.length; i++) {
                    if (currentPlaylists.results[i].pk == playlistPk) {
                        currentPlaylists.results.splice(i, 1);
                        break;
                    }
                }
            }
            return { ...state, playlists: currentPlaylists, loadingPlaylists: false, error: null };
        case DELETE_PLAYLIST_FAILED:
            return { ...state, error: action.payload, loadingPlaylists: false };


        case GET_OWNED_PLAYLISTS:
            if (action.isFirstPage) {
                return { ...state, ownedPlaylists: null, loadingOwnedPlaylists: true };
            }
            else {
                return { ...state, loadingOwnedPlaylists: true };
            }
        case GET_OWNED_PLAYLISTS_SUCCESS:
            var currentPlaylists = state.ownedPlaylists;
            if (currentPlaylists) {
                currentPlaylists.next_page = action.payload.next_page;
                currentPlaylists.previous_page = action.payload.previous_page;
                if (action.payload.previous_page == null) {
                    currentPlaylists.results = []
                }
                //currentPlaylists.results = currentPlaylists.results.concat(action.payload.results);

                action.payload.results.forEach(element => {
                    var isExisting = false;
                    for (var i = 0; i < currentPlaylists.results.length; i++) {
                        if (currentPlaylists.results[i].pk == element.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        currentPlaylists.results.push(element);
                    }
                });

            }
            else {
                currentPlaylists = action.payload;
            }
            return { ...state, ownedPlaylists: currentPlaylists, loadingOwnedPlaylists: false, error: null };
        case GET_OWNED_PLAYLISTS_FAILED:
            return { ...state, error: action.payload, loadingOwnedPlaylists: false };

        case GET_USER_REVIEWS:
            return { ...state, loadingReviews: true };
        case GET_USER_REVIEWS_SUCCESS:
            var currentReviews = state.reviews;
            if (currentReviews) {
                currentReviews.next_page = action.payload.next_page;
                currentReviews.previous_page = action.payload.previous_page;
                if (action.payload.previous_page == null) {
                    currentReviews.results = []
                }
                currentReviews.results = currentReviews.results.concat(action.payload.results);
            }
            else {
                currentReviews = action.payload;
            }

            return { ...state, reviews: currentReviews, loadingReviews: false, error: null };
        case GET_USER_REVIEWS_FAILED:
            return { ...state, error: action.payload, loadingReviews: false };

        case GET_FRIENDLIST:
            return { ...state, loadingPlaylists: true };
        case GET_FRIENDLIST_SUCCESS:
            var current = state.friendlist;
            if (current) {
                if (current.results == null || action.payload.previous_page == null) {
                    current.results = action.payload.results
                }
                else {
                    current.results = current.results.concat(action.payload.results);
                }
                current.next_page = action.payload.next_page;
                current.previous_page = action.payload.previous_page;
            }
            else {
                current = action.payload;
            }
            return { ...state, friendlist: current, loadingPlaylists: false, error: null };
        case GET_FRIENDLIST_FAILED:
            return { ...state, error: action.payload, loadingPlaylists: false };

        case UPDATE_PROFILE:
            return { ...state, loading: true };
        case UPDATE_PROFILE_SUCCESS:
            return { ...state, profile: action.payload.profile, loading: false, error: null };
        case UPDATE_PROFILE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPLOAD_MUSIC:
            return { ...state, loading: true };
        case UPLOAD_MUSIC_SUCCESS:
            return { ...state, loading: true, error: null };
        case UPLOAD_MUSIC_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };

        case CHANGE_PASSWORD:
            return { ...state, loading: true };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loading: true, error: null };
        case CHANGE_PASSWORD_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };


        case FOLLOW_USER:
            return { ...state, loading: true };
        case FOLLOW_USER_SUCCESS:
            const author = action.payload.userPk
            const isFollowing = action.payload.isFollowing

            var current = state.profile;
            if (current.user_pk == author) {
                current.is_following = isFollowing
            }

            return { ...state, profile: current, loading: false };
        case FOLLOW_USER_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };

        case MODIFY_USER_FRIENDSHIP:
            return { ...state, loading: true };
        case MODIFY_USER_FRIENDSHIP_SUCCESS:

            if (action && action.payload) {
                const userPk = action.payload.userPk;

                var current = state.profile;
                if (current.user_pk == userPk) {
                    switch (action.payload.action) {
                        case 'remove':
                            const num_of_connections = current.num_of_connections ?? 0
                            current.num_of_connections = (num_of_connections - 1) < 0 ? 0 : (num_of_connections - 1)
                            current.is_friend = false
                            current.connection_requested = false
                            current.friend_pk = null
                            break
                        case 'block':
                            if (current.is_friend ?? false) {
                                const num_of_connections = current.num_of_connections ?? 0
                                current.num_of_connections = (num_of_connections - 1) < 0 ? 0 : (num_of_connections - 1)

                            }
                            current.is_friend = false
                            current.connection_requested = false
                            current.friend_pk = null
                            current.is_blocked = true
                            break
                        case 'unblock':
                            current.is_blocked = false
                            break
                        default:
                            break
                    }
                }

                return { ...state, profile: current, loading: false };
            }

            return { ...state, loading: false };
        case MODIFY_USER_FRIENDSHIP_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };

        case DELETE_POST:
            return { ...state, loading: true };
        case DELETE_POST_SUCCESS:
            currentVaults = state.vaults;
            const postPk = action.payload;
            for (var i = 0; i < currentVaults.posts_in_vault.length; i++) {
                if (currentVaults.posts_in_vault[i].pk == postPk) {
                    currentVaults.posts_in_vault.splice(i, 1);
                    break;
                }
            }
            return { ...state, vaults: currentVaults, loading: false, error: null };
        case DELETE_POST_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };


        case SUBSCRIBE:
            return { ...state, loading: true };
        case SUBSCRIBE_SUCCESS:
            var current = state.profile;
            if (current.user_pk == action.payload.artist) {
                current.is_notified = true
            }
            return { ...state, profile: current, loading: false };
        case SUBSCRIBE_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };

        case UNSUBSCRIBE:
            return { ...state, loading: true };
        case UNSUBSCRIBE_SUCCESS:
            var current = state.profile;
            if (current.user_pk == action.payload.artist) {
                current.is_notified = false
            }
            return { ...state, profile: current, loading: false };
        case UNSUBSCRIBE_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Profile;
