// @flow
import {
    SEARCH_CATEGORY_CHANGE,
    SEARCH_CATEGORY_CHANGE_SUCCESS,
    SEARCH_CATEGORY_CHANGE_FAILED,

    SEARCH_KEY_CHANGE,
    SEARCH_KEY_CHANGE_SUCCESS,
    SEARCH_KEY_CHANGE_FAILED,    

    SEARCH,
    SEARCH_SUCCESS,
    SEARCH_FAILED,

    REQUEST_FRIENDSHIP,
    REQUEST_FRIENDSHIP_SUCCESS,
    REQUEST_FRIENDSHIP_FAILED,

    DELETE_FRIENDSHIP_REQUEST,
    DELETE_FRIENDSHIP_REQUEST_SUCCESS,
    DELETE_FRIENDSHIP_REQUEST_FAILED,

    PATCH_FRIENDSHIP_REQUEST,
    PATCH_FRIENDSHIP_REQUEST_SUCCESS,
    PATCH_FRIENDSHIP_REQUEST_FAILED,

    CHARTS,
    CHARTS_SUCCESS,
    CHARTS_FAILED,

    SHARE,
    SHARE_SUCCESS,
    SHARE_FAILED

} from './constants';

export const changeSearchCategory = (category, callbacks) => ({
    type: SEARCH_CATEGORY_CHANGE,
    payload: {category, callbacks},
});

export const changeSearchCategorySuccess = (category) => ({
    type: SEARCH_CATEGORY_CHANGE_SUCCESS,
    payload: {category},
});

export const changeSearchCategoryFailed = (error) => ({
    type: SEARCH_CATEGORY_CHANGE_FAILED,
    payload: error,
});

export const changeSearchKeyWord = (key_word, callbacks) => ({
    type: SEARCH_KEY_CHANGE,
    payload: {key_word, callbacks},
});

export const changeSearchKeyWordSuccess = (key_word) => ({
    type: SEARCH_KEY_CHANGE_SUCCESS,
    payload: {key_word},
});

export const changeSearchKeyWordFailed = (error) => ({
    type: SEARCH_KEY_CHANGE_FAILED,
    payload: error,
});

export const search = (url, type, keyword, callbacks) => ({
    type: SEARCH,
    payload: {url, type, keyword, callbacks},
});

export const searchSuccess = (type, result) => ({
    type: SEARCH_SUCCESS,
    payload: {type, result},
});

export const searchFailed = (error) => ({
    type: SEARCH_FAILED,
    payload: error,
});

export const requestFriendship = ( params, callbacks ) => ({
    type: REQUEST_FRIENDSHIP,
    payload: {params, callbacks},
});

export const requestFriendshipSuccess = (request) => ({
    type: REQUEST_FRIENDSHIP_SUCCESS,
    payload: request,
});

export const requestFriendshipFailed = (error) => ({
    type: REQUEST_FRIENDSHIP_FAILED,
    payload: error,
});

export const deleteFriendshipRequest = ( params, callbacks ) => ({
    type: DELETE_FRIENDSHIP_REQUEST,
    payload: {params, callbacks},
});

export const deleteFriendshipRequestSuccess = (request_pk) => ({
    type: DELETE_FRIENDSHIP_REQUEST_SUCCESS,
    payload: request_pk,
});

export const deleteFriendshipRequestFailed = (error) => ({
    type: DELETE_FRIENDSHIP_REQUEST_FAILED,
    payload: error,
});


export const patchFriendshipRequest = ( params, callbacks ) => ({
    type: PATCH_FRIENDSHIP_REQUEST,
    payload: {params, callbacks},
});

export const patchFriendshipRequestSuccess = (results) => ({
    type: PATCH_FRIENDSHIP_REQUEST_SUCCESS,
    payload: results,
});

export const patchFriendshipRequestFailed = (error) => ({
    type: PATCH_FRIENDSHIP_REQUEST_FAILED,
    payload: error,
});


export const getCharts = (url, timeframe, category, callbacks) => ({
    type: CHARTS,
    payload: {url, timeframe, category, callbacks},
});

export const getChartsSuccess = (result) => ({
    type: CHARTS_SUCCESS,
    payload: {result},
});

export const getChartsFailed = (error) => ({
    type: CHARTS_FAILED,
    payload: error,
});


export const share = (object_pk, action, list_of_users, callbacks) => ({
    type: SHARE,
    payload: {object_pk, action, list_of_users, callbacks},
});

export const shareSuccess = (result) => ({
    type: SHARE_SUCCESS,
    payload: {result},
});

export const shareFailed = (error) => ({
    type: SHARE_FAILED,
    payload: error,
});