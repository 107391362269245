// @flow
import {
    GET_PLAYLISTDETAIL,
    GET_PLAYLISTDETAIL_SUCCESS,
    GET_PLAYLISTDETAIL_FAILED,
    CREATE_PLAYLIST,
    CREATE_PLAYLIST_SUCCESS,
    CREATE_PLAYLIST_FAILED,
    EDIT_PLAYLIST_DETAILS,
    EDIT_PLAYLIST_DETAILS_SUCCESS,
    EDIT_PLAYLIST_DETAILS_FAILED,
    DELETE_PLAYLIST,
    DELETE_PLAYLIST_SUCCESS,
    DELETE_PLAYLIST_FAILED,
    HEART_PLAYLIST,
    HEART_PLAYLIST_SUCCESS,
    HEART_PLAYLIST_FAILED,
    MOVE_PLAYLIST_SONG,
    MOVE_PLAYLIST_SONG_SUCCESS,
    MOVE_PLAYLIST_SONG_FAILED
    /*
    UP_VOTE,
    UP_VOTE_SUCCESS,
    UP_VOTE_FAILED,
    GET_REVIEWDETAILS,
    GET_REVIEWDETAILS_SUCCESS,
    GET_REVIEWDETAILS_FAILED,
    HIDE_REPLIES,
    HIDE_REPLIES_SUCCESS,
    HIDE_REPLIES_FAILED,
    UP_VOTE_REPLY,
    UP_VOTE_REPLY_SUCCESS,
    UP_VOTE_REPLY_FAILED*/
} from './constants';

import {
    ADD_TO_ARCHIVE,
    ADD_TO_ARCHIVE_SUCCESS,
    ADD_TO_ARCHIVE_FAILED,

} from '../post/constants';

const INIT_STATE = {
    playlistdetail: null,
    loading: false,
    error: null
};


const Playlist = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYLISTDETAIL:
            if (action.isFirstPage) {
                return { ...state, playlistdetail: null, loading: true };
            }
            else {
                return { ...state, loading: true };
            }
        case GET_PLAYLISTDETAIL_SUCCESS:
            var current = state.playlistdetail;
            if (current) {
                if (current.posts == null) {
                    current.posts = action.payload.posts;
                }
                else {
                    current.posts = current.posts.concat(action.payload.posts);
                }

                current.next_page = action.payload.next_page;
                current.previous_page = action.payload.previous_page;
            }
            else {
                current = action.payload;
            }
            return { ...state, playlistdetail: current, loading: false, error: null };
        case GET_PLAYLISTDETAIL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CREATE_PLAYLIST:
            return { ...state, loading: true };
        case CREATE_PLAYLIST_SUCCESS:
            return { ...state, loading: false, error: null };
        case CREATE_PLAYLIST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_PLAYLIST_DETAILS:
            return { ...state, loading: true };
        case EDIT_PLAYLIST_DETAILS_SUCCESS:
            return { ...state, loading: false, error: null };

        case EDIT_PLAYLIST_DETAILS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_PLAYLIST:
            return { ...state, loading: true };
        case DELETE_PLAYLIST_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_PLAYLIST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case HEART_PLAYLIST:
            return { ...state, loading: true };
        case HEART_PLAYLIST_SUCCESS:
            var playlistdetail = state.playlistdetail;
            const meta = playlistdetail && playlistdetail.playlist_meta
            meta.hearted_by_me = action.payload.hearted_by_me
            meta.num_of_hearts = action.payload.num_of_hearts
            meta.num_of_tracks = action.payload.num_of_tracks
            playlistdetail.playlist_meta = meta
            return { ...state, playlistdetail: playlistdetail, loading: false, error: null };
        case HEART_PLAYLIST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case MOVE_PLAYLIST_SONG:
            const { params } = action.payload
            var current = state.playlistdetail;
            let playlistPk = params.parent_playlist;
            if (current && current.playlist_meta && current.playlist_meta.pk && current.playlist_meta.pk == playlistPk) {
                let songPk = params.playlist_song_pk;
                let position = params.to_position;
                const sources = current.posts.filter(function (post) {
                    return post.pk == songPk;
                });

                if (sources.length > 0) {
                    const sourceItem = sources[0]

                    current.posts = current.posts.filter(function (post) {
                        return post.pk != songPk;
                    });

                    if (position != null) {
                        current.posts.splice(position, 0, sourceItem)
                    }
                    else {
                        current.posts.splice(0, 0, sourceItem)
                    }
                }
                return { ...state, playlistdetail: current, loading: true };
            }

            return { ...state, loading: true };
        case MOVE_PLAYLIST_SONG_SUCCESS:
            // var current = state.playlistdetail;
            // let playlistPk = action.payload.parent_playlist;
            // if (current && current.pk && current.pk == playlistPk) {
            //     let songPk = action.payload.playlist_song_pk;
            //     let position = action.payload.to_position;
            //     const sources = current.posts.filter(function (post) {
            //         return post.pk == songPk;
            //     });

            //     if (sources.length > 0) {
            //         const sourceItem = sources[0]

            //         current.posts = current.posts.filter(function (post) {
            //             return post.pk != songPk;
            //         });

            //         if (position != null) {
            //             current.posts.splice(position, 0, sourceItem)
            //         }
            //         else {
            //             current.posts.splice(0, 0, sourceItem)
            //         }
            //     }

            //     return { ...state, playlistdetail: current, loading: false, error: null };
            // }
            return { ...state, loading: false, error: null };
        case MOVE_PLAYLIST_SONG_FAILED:
            return { ...state, error: action.payload, loading: false };


        case ADD_TO_ARCHIVE:
            return { ...state, loading: true };
        case ADD_TO_ARCHIVE_SUCCESS:
            const response = action.payload
            if (response.action == null) {
                return { ...state, loading: false, error: null };
            }

            const isAdded = response.action ? (response.action == 'add') : false
            const pks = response.posts ?? [];

            var current = state.playlistdetail;
            if (current && current.posts, pks.length > 0) {
                for (let i = 0; i < current.posts.length; i++) {
                    if (current.posts[i].post?.pk && current.posts[i].post.pk == pks[0]) {
                        current.posts[i].post.archived_by_me = isAdded
                        break
                    }
                }
                return { ...state, playlistdetail: current, loading: false, error: null };
            }
            return { ...state, loading: false, error: null };
        case ADD_TO_ARCHIVE_FAILED:
            return { ...state, error: action.payload, loading: false };


        /*
        case UP_VOTE:
            return { ...state, loading: true };
        case UP_VOTE_SUCCESS:
            var pd = state.postdetail; //Object.assign({}, state.postdetail);
            var review = pd.reviews.find(element => element.pk == action.payload.parent_review_pk);
            review.current_user_upvoted = action.payload.upVote.current_user_upvoted;
            review.current_user_downvoted = action.payload.upVote.current_user_downvoted;
            review.num_upvotes = action.payload.upVote.num_upvotes;
            review.num_downvotes = action.payload.upVote.num_downvotes;

            return { ...state, postdetail: pd, loading: false, error: null };
        case UP_VOTE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_REVIEWDETAILS:
            return { ...state, loading: true };
        case GET_REVIEWDETAILS_SUCCESS:
            var pd = state.postdetail;
            var review = pd.reviews.find(element => element.pk == action.payload.parent_review.pk);
            if (review.replies  == null){
                review.replies = [];
            }
            review.replies = review.replies.concat(action.payload.replies);
            review.next_page = action.payload.next_page;
            return { ...state, postdetail: pd, loading: false, error: null };
        case GET_REVIEWDETAILS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case HIDE_REPLIES:
            return { ...state, loading: true };
        case HIDE_REPLIES_SUCCESS:
            var pd = state.postdetail;//Object.assign({}, state.postdetail);
            var review = pd.reviews.find(element => element.pk == action.payload);
            review.replies = [];
            review.next_page = null;
            return { ...state, postdetail: pd, loading: false, error: null };
        case HIDE_REPLIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UP_VOTE_REPLY:
            return { ...state, loading: true };
        case UP_VOTE_REPLY_SUCCESS:
            var pd = state.postdetail; //Object.assign({}, state.postdetail);
            var review = pd.reviews.find(element => element.pk == action.payload.parent_review_pk);
            var reply = review.replies.find(element => element.pk == action.payload.parent_reply_pk);
            reply.current_user_upvoted = action.payload.upVote.current_user_upvoted;
            reply.current_user_downvoted = action.payload.upVote.current_user_downvoted;
            reply.num_upvotes = action.payload.upVote.num_upvotes;
            reply.num_downvotes = action.payload.upVote.num_downvotes;

            return { ...state, postdetail: pd, loading: false, error: null };
        case UP_VOTE_REPLY_FAILED:
            return { ...state, error: action.payload, loading: false };
*/
        default:
            return { ...state };
    }
};

export default Playlist;
