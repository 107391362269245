// @flow
import {
    RESET_POSTDETAIL,
    RESET_POSTDETAIL_SUCCESS,
    RESET_POSTDETAIL_FAILED,

    GET_POSTDETAIL,
    GET_POSTDETAIL_SUCCESS,
    GET_POSTDETAIL_FAILED,
    GET_REVIEWS,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAILED,
    UP_VOTE,
    UP_VOTE_SUCCESS,
    UP_VOTE_FAILED,
    GET_REVIEWDETAILS,
    GET_REVIEWDETAILS_SUCCESS,
    GET_REVIEWDETAILS_FAILED,
    HIDE_REPLIES,
    HIDE_REPLIES_SUCCESS,
    HIDE_REPLIES_FAILED,
    UP_VOTE_REPLY,
    UP_VOTE_REPLY_SUCCESS,
    UP_VOTE_REPLY_FAILED,
    REPLY_REVIEW,
    REPLY_REVIEW_SUCCESS,
    REPLY_REVIEW_FAILED,
    POST_REVIEW,
    POST_REVIEW_SUCCESS,
    POST_REVIEW_FAILED,

    ADD_TO_ARCHIVE,
    ADD_TO_ARCHIVE_SUCCESS,
    ADD_TO_ARCHIVE_FAILED,

    ADD_TO_PLAYLIST,
    ADD_TO_PLAYLIST_SUCCESS,
    ADD_TO_PLAYLIST_FAILED,

    ADD_TO_MULTIPLE_PLAYLISTS,
    ADD_TO_MULTIPLE_PLAYLISTS_SUCCESS,
    ADD_TO_MULTIPLE_PLAYLISTS_FAILED,

    EDIT_REVIEW,
    EDIT_REVIEW_SUCCESS,
    EDIT_REVIEW_FAILED,

    DELETE_REVIEW,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAILED,

    EDIT_REPLY,
    EDIT_REPLY_SUCCESS,
    EDIT_REPLY_FAILED,

    DELETE_REPLY,
    DELETE_REPLY_SUCCESS,
    DELETE_REPLY_FAILED,

    GET_DIGFEEDS,
    GET_DIGFEEDS_SUCCESS,
    GET_DIGFEEDS_FAILED,

    GET_DIGFEEDS_BY_TAG,
    GET_DIGFEEDS_BY_TAG_SUCCESS,
    GET_DIGFEEDS_BY_TAG_FAILED,

    GET_FOLLOWING_POSTS,
    GET_FOLLOWING_POSTS_SUCCESS,
    GET_FOLLOWING_POSTS_FAILED,

    EDIT_POST,
    EDIT_POST_SUCCESS,
    EDIT_POST_FAILED,

    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILED,

    REPORT,
    REPORT_SUCCESS,
    REPORT_FAILED,

    FOLLOW_USER,
    FOLLOW_USER_SUCCESS,
    FOLLOW_USER_FAILED,

} from './constants';

export const resetPostDetail = () => ({
    type: RESET_POSTDETAIL,
});

export const resetPostDetailSuccess = () => ({
    type: RESET_POSTDETAIL_SUCCESS,
});

export const resetPostDetailFailed = (error) => ({
    type: RESET_POSTDETAIL_FAILED,
    payload: error,
});

export const getPostDetail = (post_url_slug, callbacks) => ({
    type: GET_POSTDETAIL,
    payload: { post_url_slug, callbacks },
});

export const getPostDetailSuccess = (user) => ({
    type: GET_POSTDETAIL_SUCCESS,
    payload: user,
});

export const getPostDetailFailed = (error) => ({
    type: GET_POSTDETAIL_FAILED,
    payload: error,
});

export const getReviews = (urlslug) => ({
    type: GET_REVIEWS,
    payload: urlslug,
});

export const getReviewsSuccess = (reviews) => ({
    type: GET_REVIEWS_SUCCESS,
    payload: reviews,
});

export const getReviewsFailed = (error) => ({
    type: GET_REVIEWS_FAILED,
    payload: error,
});

export const upVote = ({ parent_review_pk, action }) => ({
    type: UP_VOTE,
    payload: { parent_review_pk, action },
});

export const upVoteSuccess = ({ parent_review_pk, upVote }) => ({
    type: UP_VOTE_SUCCESS,
    payload: { parent_review_pk, upVote },
});

export const upVoteFailed = (error) => ({
    type: UP_VOTE_FAILED,
    payload: error,
});

export const getReviewDetails = (urlslug) => ({
    type: GET_REVIEWDETAILS,
    payload: urlslug,
});

export const getReviewDetailsSuccess = (vault) => ({
    type: GET_REVIEWDETAILS_SUCCESS,
    payload: vault,
});

export const getReviewDetailsFailed = (error) => ({
    type: GET_REVIEWDETAILS_FAILED,
    payload: error,
});

export const hideReplies = (parent_review_pk) => ({
    type: HIDE_REPLIES,
    payload: parent_review_pk,
});

export const hideRepliesSuccess = (parent_review_pk) => ({
    type: HIDE_REPLIES_SUCCESS,
    payload: parent_review_pk,
});

export const hideRepliesFailed = (error) => ({
    type: HIDE_REPLIES_FAILED,
    payload: error,
});


export const upVoteReply = ({ parent_review_pk, parent_reply_pk, action }) => ({
    type: UP_VOTE_REPLY,
    payload: { parent_review_pk, parent_reply_pk, action },
});

export const upVoteReplySuccess = ({ parent_review_pk, parent_reply_pk, upVote }) => ({
    type: UP_VOTE_REPLY_SUCCESS,
    payload: { parent_review_pk, parent_reply_pk, upVote },
});

export const upVoteReplyFailed = (error) => ({
    type: UP_VOTE_REPLY_FAILED,
    payload: error,
});

export const replyReview = ({ parent_review_pk, comment, post_url_slug }) => ({
    type: REPLY_REVIEW,
    payload: { parent_review_pk, comment, post_url_slug },
});

export const replyReviewSuccess = ({ parent_review_pk, reply }) => ({
    type: REPLY_REVIEW_SUCCESS,
    payload: { parent_review_pk, reply },
});

export const replyReviewFailed = (error) => ({
    type: REPLY_REVIEW_FAILED,
    payload: error,
});


export const postReview = (parent_post, rating_given, comment, callbacks) => ({
    type: POST_REVIEW,
    payload: { parent_post, rating_given, comment, callbacks },
});

export const postReviewSuccess = (review) => ({
    type: POST_REVIEW_SUCCESS,
    payload: review,
});

export const postReviewFailed = (error) => ({
    type: POST_REVIEW_FAILED,
    payload: error,
});


export const addToArchive = (pks, action, callbacks) => ({
    type: ADD_TO_ARCHIVE,
    payload: { pks, action, callbacks },
});

export const addToArchiveSuccess = (response) => ({
    type: ADD_TO_ARCHIVE_SUCCESS,
    payload: response,
});

export const addToArchiveFailed = (error) => ({
    type: ADD_TO_ARCHIVE_FAILED,
    payload: error,
});


export const addToPlaylist = (postPks, playlistPk, action, callbacks) => ({
    type: ADD_TO_PLAYLIST,
    payload: { postPks, playlistPk, action, callbacks },
});

export const addToPlaylistSuccess = (action) => ({
    type: ADD_TO_PLAYLIST_SUCCESS,
    payload: action,
});

export const addToPlaylistFailed = (error) => ({
    type: ADD_TO_PLAYLIST_FAILED,
    payload: error,
});

export const addToMultiplePlaylists = (postPk, playlistPks, action, callbacks) => ({
    type: ADD_TO_MULTIPLE_PLAYLISTS,
    payload: { postPk, playlistPks, action, callbacks },
});

export const addToMultiplePlaylistsSuccess = (action) => ({
    type: ADD_TO_MULTIPLE_PLAYLISTS_SUCCESS,
    payload: action,
});

export const addToMultiplePlaylistsFailed = (error) => ({
    type: ADD_TO_MULTIPLE_PLAYLISTS_FAILED,
    payload: error,
});

//-- Edit Review --
export const editReview = (parent_post, pk, rating, comment, callbacks) => ({
    type: EDIT_REVIEW,
    payload: { parent_post, pk, rating, comment, callbacks },
});

export const editReviewSuccess = (action) => ({
    type: EDIT_REVIEW_SUCCESS,
    payload: action,
});

export const editReviewFailed = (error) => ({
    type: EDIT_REVIEW_FAILED,
    payload: error,
});
//----

//-- Delete Review --
export const deleteReview = (parent_post, pk, callbacks) => ({
    type: DELETE_REVIEW,
    payload: { parent_post, pk, callbacks },
});

export const deleteReviewSuccess = (pk) => ({
    type: DELETE_REVIEW_SUCCESS,
    payload: pk,
});

export const deleteReviewFailed = (error) => ({
    type: DELETE_REVIEW_FAILED,
    payload: error,
});
//----

//-- Edit Reply --
export const editReply = (pk, comment) => ({
    type: EDIT_REPLY,
    payload: { pk, comment },
});

export const editReplySuccess = (action) => ({
    type: EDIT_REPLY_SUCCESS,
    payload: action,
});

export const editReplyFailed = (error) => ({
    type: EDIT_REPLY_FAILED,
    payload: error,
});
//----

//-- Delete Reply --
export const deleteReply = (pk) => ({
    type: DELETE_REPLY,
    payload: { pk },
});

export const deleteReplySuccess = (pk) => ({
    type: DELETE_REPLY_SUCCESS,
    payload: pk,
});

export const deleteReplyFailed = (error) => ({
    type: DELETE_REPLY_FAILED,
    payload: error,
});
//----


export const getDigFeeds = (url_slug) => ({
    type: GET_DIGFEEDS,
    payload: url_slug,
});

export const getDigFeedsSuccess = (feeds) => ({
    type: GET_DIGFEEDS_SUCCESS,
    payload: feeds,
});

export const getDigFeedsFailed = (error) => ({
    type: GET_DIGFEEDS_FAILED,
    payload: error,
});

export const getDigFeedsByTag = (url_slug, tags) => ({
    type: GET_DIGFEEDS_BY_TAG,
    payload: { url_slug, tags },
});

export const getDigFeedsByTagSuccess = (feeds) => ({
    type: GET_DIGFEEDS_BY_TAG_SUCCESS,
    payload: feeds,
});

export const getDigFeedsByTagFailed = (error) => ({
    type: GET_DIGFEEDS_BY_TAG_FAILED,
    payload: error,
});

export const getFollowingPosts = (url_slug) => ({
    type: GET_FOLLOWING_POSTS,
    payload: url_slug,
});

export const getFollowingPostsSuccess = (posts) => ({
    type: GET_FOLLOWING_POSTS_SUCCESS,
    payload: posts,
});

export const getFollowingPostsFailed = (error) => ({
    type: GET_FOLLOWING_POSTS_FAILED,
    payload: error,
});

//-- Edit Post --
export const editPost = (uploadInfo, callbacks) => ({
    type: EDIT_POST,
    payload: { uploadInfo, callbacks },
});

export const editPostSuccess = (post) => ({
    type: EDIT_POST_SUCCESS,
    payload: post,
});

export const editPostFailed = (error) => ({
    type: EDIT_POST_FAILED,
    payload: error,
});
//----

//-- Delete Post --
export const deletePost = (pk, post_url_slug, callbacks) => ({
    type: DELETE_POST,
    payload: { pk, post_url_slug, callbacks },
});

export const deletePostSuccess = (results) => ({
    type: DELETE_POST_SUCCESS,
    payload: results,
});

export const deletePostFailed = (error) => ({
    type: DELETE_POST_FAILED,
    payload: error,
});
//----

//-- Report Post --
export const report = (uploadInfo, callbacks) => ({
    type: REPORT,
    payload: { uploadInfo, callbacks },
});

export const reportSuccess = (status) => ({
    type: REPORT_SUCCESS,
    payload: status,
});

export const reportFailed = (error) => ({
    type: REPORT_FAILED,
    payload: error,
});
//----

//-- Follow User --
export const followUser = (params, callbacks) => ({
    type: FOLLOW_USER,
    payload: { params, callbacks },
});

export const followUserSuccess = (status) => ({
    type: FOLLOW_USER_SUCCESS,
    payload: status,
});

export const followUserFailed = (error) => ({
    type: FOLLOW_USER_FAILED,
    payload: error,
});
//----