// @flow
import {
    GET_CHATROOMS,
    GET_CHATROOMS_SUCCESS,
    GET_CHATROOMS_FAILED,
    GET_MESSAGES,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAILED,

    DELETE_MESSAGE,
    DELETE_MESSAGE_SUCCESS,
    DELETE_MESSAGE_FAILED,

    CREATE_CHATROOM,
    CREATE_CHATROOM_SUCCESS,
    CREATE_CHATROOM_FAILED,

    GET_CHATROOM_DETAILS,
    GET_CHATROOM_DETAILS_SUCCESS,
    GET_CHATROOM_DETAILS_FAILED,

    UPDATE_CHATROOM_DETAILS,
    UPDATE_CHATROOM_DETAILS_SUCCESS,
    UPDATE_CHATROOM_DETAILS_FAILED,

    MUTE_CHATROOM,
    MUTE_CHATROOM_SUCCESS,
    MUTE_CHATROOM_FAILED,

    DELETE_CHATROOM,
    DELETE_CHATROOM_SUCCESS,
    DELETE_CHATROOM_FAILED,

    PATCH_CHATROOM_MEMBERS,
    PATCH_CHATROOM_MEMBERS_SUCCESS,
    PATCH_CHATROOM_MEMBERS_FAILED,

    GET_LINKPREVIEW,
    GET_LINKPREVIEW_SUCCESS,
    GET_LINKPREVIEW_FAILED,

    UPLOAD_ATTACHMENT,
    UPLOAD_ATTACHMENT_SUCCESS,
    UPLOAD_ATTACHMENT_FAILED,

    UPDATE_LATEST_MESSAGE_IN_ROOM,
    UPDATE_LATEST_MESSAGE_IN_ROOM_SUCCESS,
    UPDATE_LATEST_MESSAGE_IN_ROOM_FAILED,
    
    DID_RECEIVE_LOBBY_MESSAGE,
    DID_RECEIVE_LOBBY_MESSAGE_SUCCESS,
    DID_RECEIVE_LOBBY_MESSAGE_FAILED,

    GET_UPLOADED_FILES,
    GET_UPLOADED_FILES_SUCCESS,
    GET_UPLOADED_FILES_FAILED,

    GET_SHARED_POSTS,
    GET_SHARED_POSTS_SUCCESS,
    GET_SHARED_POSTS_FAILED,

    EDIT_CAPTION,
    EDIT_CAPTION_SUCCESS,
    EDIT_CAPTION_FAILED,

    DELETE_ATTACHMENT,
    DELETE_ATTACHMENT_SUCCESS,
    DELETE_ATTACHMENT_FAILED,

    ADD_MESSAGES,
    ADD_MESSAGES_SUCCESS,
    RECEIVED_MESSAGES,
    RECEIVED_MESSAGES_SUCCESS

} from './constants';


export const getChatRooms = (url_slug, callbacks) => ({
    type: GET_CHATROOMS,
    payload: {url_slug, callbacks},
});

export const getChatRoomsSuccess = (rooms) => ({
    type: GET_CHATROOMS_SUCCESS,
    payload: rooms,
});

export const getChatRoomsFailed = (error) => ({
    type: GET_CHATROOMS_FAILED,
    payload: error,
});

export const getMessages = (room_guid, url_slug, callbacks) => ({
    type: GET_MESSAGES,
    payload: {room_guid, url_slug, callbacks},
});

export const getMessagesSuccess = (messages) => ({
    type: GET_MESSAGES_SUCCESS,
    payload: messages,
});

export const getMessagesFailed = (error) => ({
    type: GET_MESSAGES_FAILED,
    payload: error,
});

export const addMessage = (message) => ({
    type: ADD_MESSAGES,
    payload: {message},
});

export const addMessageSuccess = (message) => ({
    type: ADD_MESSAGES_SUCCESS,
    payload: message,
});

export const receivedMessage = (message) => ({
    type: RECEIVED_MESSAGES,
    payload: {message},
});

export const receivedMessageSuccess = (message) => ({
    type: RECEIVED_MESSAGES_SUCCESS,
    payload: message,
});


export const deleteMessage = (message_id, callbacks) => ({
    type: DELETE_MESSAGE,
    payload: {message_id, callbacks},
});

export const deleteMessageSuccess = (messages) => ({
    type: DELETE_MESSAGE_SUCCESS,
    payload: messages,
});

export const deleteMessageFailed = (error) => ({
    type: DELETE_MESSAGE_FAILED,
    payload: error,
});

export const createChatRoom = (params, callbacks) => ({
    type: CREATE_CHATROOM,
    payload: {params, callbacks},
});

export const createChatRoomSuccess = (chatroom) => ({
    type: CREATE_CHATROOM_SUCCESS,
    payload: chatroom,
});

export const createChatRoomFailed = (error) => ({
    type: CREATE_CHATROOM_FAILED,
    payload: error,
});

export const getChatRoomDetails = (room_guid, url_slug, callbacks) => ({
    type: GET_CHATROOM_DETAILS,
    payload: {room_guid, url_slug, callbacks},
});

export const getChatRoomDetailsSuccess = (details, callbacks) => ({
    type: GET_CHATROOM_DETAILS_SUCCESS,
    payload: { details, callbacks },
});

export const getChatRoomDetailsFailed = (error) => ({
    type: GET_CHATROOM_DETAILS_FAILED,
    payload: error,
});

export const updateChatRoomDetails = (params, callbacks) => ({
    type: UPDATE_CHATROOM_DETAILS,
    payload: {params, callbacks},
});

export const updateChatRoomDetailsSuccess = (details, callbacks) => ({
    type: UPDATE_CHATROOM_DETAILS_SUCCESS,
    payload: {details, callbacks},
});

export const updateChatRoomDetailsFailed = (error) => ({
    type: UPDATE_CHATROOM_DETAILS_FAILED,
    payload: error,
});

export const muteChatRoom = (guid, is_muted, callbacks) => ({
    type: MUTE_CHATROOM,
    payload: {guid, is_muted, callbacks},
});

export const muteChatRoomSuccess = (details, callbacks) => ({
    type: MUTE_CHATROOM_SUCCESS,
    payload: {details, callbacks},
});

export const muteChatRoomFailed = (error) => ({
    type: MUTE_CHATROOM_FAILED,
    payload: error,
});

export const deleteChatRoom = (room_guid, callbacks) => ({
    type: DELETE_CHATROOM,
    payload: {room_guid, callbacks},
});

export const deleteChatRoomSuccess = (room_guid) => ({
    type: DELETE_CHATROOM_SUCCESS,
    payload: room_guid,
});

export const deleteChatRoomFailed = (error) => ({
    type: DELETE_CHATROOM_FAILED,
    payload: error,
});

export const patchChatRoomMembers = (params, callbacks) => ({
    type: PATCH_CHATROOM_MEMBERS,
    payload: {params, callbacks},
});

export const patchChatRoomMembersSuccess = (params) => ({
    type: PATCH_CHATROOM_MEMBERS_SUCCESS,
    payload: params,
});

export const patchChatRoomMembersFailed = (error) => ({
    type: PATCH_CHATROOM_MEMBERS_FAILED,
    payload: error,
});

export const getLinkPreview = (url, callbacks) => ({
    type: GET_LINKPREVIEW,
    payload: {url, callbacks},
});

export const getLinkPreviewSuccess = (preview) => ({
    type: GET_LINKPREVIEW_SUCCESS,
    payload: preview,
});

export const getLinkPreviewFailed = (error) => ({
    type: GET_LINKPREVIEW_FAILED,
    payload: error,
});

export const uploadAttachment = (uploadInfo, roomId, callbacks) => ({
    type: UPLOAD_ATTACHMENT,
    payload: {uploadInfo, roomId, callbacks},
});

export const uploadAttachmentSuccess = (uploadInfo) => ({
    type: UPLOAD_ATTACHMENT_SUCCESS,
    payload: uploadInfo,
});

export const uploadAttachmentFailed = (error) => ({
    type: UPLOAD_ATTACHMENT_FAILED,
    payload: error,
});

export const updateLatestMessageInRoom = (roomId, messageId, callbacks) => ({
    type: UPDATE_LATEST_MESSAGE_IN_ROOM,
    payload: {roomId, messageId, callbacks},
});

export const updateLatestMessageInRoomSuccess = (roomId, messageId) => ({
    type: UPDATE_LATEST_MESSAGE_IN_ROOM_SUCCESS,
    payload: {roomId, messageId},
});

export const updateLatestMessageInRoomFailed = (error) => ({
    type: UPDATE_LATEST_MESSAGE_IN_ROOM_FAILED,
    payload: error,
});


export const didReceiveLobbyMessage = (room) => ({
    type: DID_RECEIVE_LOBBY_MESSAGE,
    payload: {room},
});

export const didReceiveLobbyMessageSuccess = (room) => ({
    type: DID_RECEIVE_LOBBY_MESSAGE_SUCCESS,
    payload: {room},
});

export const didReceiveLobbyMessageFailed = (error) => ({
    type: DID_RECEIVE_LOBBY_MESSAGE_FAILED,
    payload: error,
});

export const getUploadedFiles = (roomId, urlSlug, callbacks) => ({
    type: GET_UPLOADED_FILES,
    payload: {roomId, urlSlug, callbacks},
});

export const getUploadedFilesSuccess = (files) => ({
    type: GET_UPLOADED_FILES_SUCCESS,
    payload: {files},
});

export const getUploadedFilesFailed = (error) => ({
    type: GET_UPLOADED_FILES_FAILED,
    payload: error,
});

export const getSharedPosts = (roomId, urlSlug, callbacks) => ({
    type: GET_SHARED_POSTS,
    payload: {roomId, urlSlug, callbacks},
});

export const getSharedPostsSuccess = (posts) => ({
    type: GET_SHARED_POSTS_SUCCESS,
    payload: {posts},
});

export const getSharedPostsFailed = (error) => ({
    type: GET_SHARED_POSTS_FAILED,
    payload: error,
});

export const editCaption = (attachmentId, caption, callbacks) => ({
    type: EDIT_CAPTION,
    payload: {attachmentId, caption, callbacks},
});

export const editCaptionSuccess = (message) => ({
    type: EDIT_CAPTION_SUCCESS,
    payload: {message},
});

export const editCaptionFailed = (error) => ({
    type: EDIT_CAPTION_FAILED,
    payload: error,
});

export const deleteAttachment = (messageId, attachmentId, callbacks) => ({
    type: DELETE_ATTACHMENT,
    payload: {messageId, attachmentId, callbacks},
});

export const deleteAttachmentSuccess = (messageId, attachmentId) => ({
    type: DELETE_ATTACHMENT_SUCCESS,
    payload: {messageId, attachmentId},
});

export const deleteAttachmentFailed = (error) => ({
    type: DELETE_ATTACHMENT_FAILED,
    payload: error,
});