// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    GET_PROFILE, GET_VAULT, GET_ARCHIVE, GET_PLAYLISTS,
    UPDATE_PROFILE, UPLOAD_MUSIC, GET_FRIENDLIST, CHANGE_PASSWORD, GET_USER_REVIEWS, GET_OWNED_PLAYLISTS, MODIFY_USER_FRIENDSHIP,
    SUBSCRIBE, UNSUBSCRIBE,

} from './constants';
import { BASE_URL } from './../constants';
import { getLoggedInUser, setLoggedInUserPk } from '../../helpers/authUtils';

import {
    getProfileSuccess,
    getProfileFailed,
    getVaultSuccess,
    getVaultFailed,
    getArchiveSuccess,
    getArchiveFailed,
    getPlaylistsSuccess,
    getPlaylistsFailed,
    getOwnedPlaylistsSuccess,
    getOwnedPlaylistsFailed,
    getUserReviewsSuccess,
    getUserReviewsFailed,
    getFriendlistSuccess,
    getFriendlistFailed,
    updateProfileSuccess,
    updateProfileFailed,
    uploadMusicSuccess,
    uploadMusicFailed,
    changePasswordSuccess,
    changePasswordFailed,
    getUserReviews,
    modifyUserFrienshipSuccess,
    modifyUserFrienshipFailed,
    subscribeToArtistSuccess,
    subscribeToArtistFailed,
    unsubscribeToArtistSuccess,
    unsubscribeToArtistFailed,

} from './actions';

/**
 * Get profile
 * @param {*} payload - none
 */
function* getProfileInternal({ payload: { urlslug, callbacks } }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    try {
        const url = urlslug ? (urlslug.startsWith(BASE_URL) ? urlslug : BASE_URL + urlslug.substring(1)) : BASE_URL + 'user/profile'
        const response = yield call(fetchJSON, url, options);
        yield put(getProfileSuccess(response));
        if (urlslug == null) {
            setLoggedInUserPk(response.user_pk);
        }

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

        // Get vault, archives, playlists
        yield call(getVaultInternal, { payload: { user_pk: response.user_pk, urlslug: BASE_URL + 'music/vault/' } });
        yield call(getArchiveInternal, { payload: BASE_URL + response.archive_url_slug.substring(1) });
        const playlistUrl = (urlslug == null) ? BASE_URL + 'music/playlist/' : BASE_URL + response.playlists_url_slug.substring(1);
        yield call(getPlaylistsInternal, { payload: playlistUrl });
        const reviewsUrl = (urlslug == null) ? BASE_URL + 'music/user-reviews/' + response.user_pk + '/' : BASE_URL + response.reviews_url_slug.substring(1);
        yield call(getUserReviewsInternal, { payload: reviewsUrl });
        yield call(getFriendlistInternal, { payload: { from_user: response.user_pk, urlslug: null } });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getProfileFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Get vault
 * @param {*} payload - none
 */
function* getVaultInternal({ payload: { user_pk, urlslug } }) {
    const options = {
        body: JSON.stringify({ "user_pk": user_pk }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = urlslug ? urlslug : BASE_URL + 'music/vault/';
        const response = yield call(fetchJSON, url, options);
        yield put(getVaultSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getVaultFailed(message));
    }
}

/**
 * Get archive
 * @param {*} payload - none
 */
function* getArchiveInternal({ payload: urlslug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, urlslug, options);
        yield put(getArchiveSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getArchiveFailed(message));
    }
}

/**
 * Get playlists
 * @param {*} payload - none
 */
function* getPlaylistsInternal({ payload: urlslug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, urlslug, options);
        yield put(getPlaylistsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getPlaylistsFailed(message));
    }
}

/**
 * Get owned playlists
 * @param {*} payload - none
 */
function* getOwnedPlaylistsInternal({ payload: urlslug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    const url = urlslug == null ? (BASE_URL + 'music/user-owned-playlists/') : urlslug

    try {
        const response = yield call(fetchJSON, url, options);
        yield put(getOwnedPlaylistsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getOwnedPlaylistsFailed(message));
    }
}

/**
 * Get reviews
 * @param {*} payload - none
 */
function* getUserReviewsInternal({ payload: urlslug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, urlslug, options);
        yield put(getUserReviewsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getUserReviewsFailed(message));
    }
}
/**
 * Get friendlist
 * @param {*} payload - none
 */
function* getFriendlistInternal({ payload: { from_user, urlslug } }) {
    const options = {
        method: 'POST',
        body: JSON.stringify({ "from_user": from_user }),
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = urlslug ? urlslug : BASE_URL + 'user/other-user-friendships/';
        let response = yield call(fetchJSON, url, options);
        yield put(getFriendlistSuccess(response));
        if (response.previous_page == null && response.next_page != null) {
            response = yield call(fetchJSON, response.next_page, options);
            yield put(getFriendlistSuccess(response));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getFriendlistFailed(message));
    }
}

function* updateProfileInternal({ payload: { profile, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    if (profile.profile_picture) {
        const formData = new FormData();
        formData.append("profile.profile_picture", profile.profile_picture);
        const optionsUpload = {
            body: formData,
            method: 'PATCH',
            requestToken: true,
            headers: { 'Content-Type': 'multipart/form-data' }
        };

        try {
            const responseUpload = yield call(fetchJSON, BASE_URL + "user/update/", optionsUpload);
            console.log(responseUpload.toString());
        } catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    message = 'Internal Server Error';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = error;
            }
            yield put(updateProfileFailed(message));

            if (callbackOnFailure) {
                yield call(callbackOnFailure, error);
            }

            return;
        }
    }

    const options = {
        method: 'PATCH',
        body: { profile: profile.profile },
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + "user/update/", options);
        if (response && response.pk) {
            yield put(updateProfileSuccess(response));
            if (callbackOnSuccess) {
                yield call(callbackOnSuccess, response);
            }

        }
        else {
            yield put(updateProfileFailed(response));
            if (callbackOnFailure) {
                yield call(callbackOnFailure, response);
            }

        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(updateProfileFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }

    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }

}

function* uploadMusicInternal({ payload: { uploadInfo, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const formData = new FormData();
    const data = {};
    data.song_name = uploadInfo.song_name;
    data.artist_name = uploadInfo.artist_name;
    if (uploadInfo.tags) {
        data.tags = uploadInfo.tags;
    }
    if (uploadInfo.description) {
        data.description = uploadInfo.description;
    }
    formData.append("media_file", uploadInfo.media_file);
    formData.append("cover_art_file", uploadInfo.cover_art_file);
    formData.append("data", JSON.stringify(data));
    const optionsUpload = {
        body: formData,
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    };

    try {
        const responseUpload = yield call(fetchJSON, BASE_URL + "music/post/public/", optionsUpload);
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, responseUpload);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(uploadMusicFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Change password
 * @param {*} payload - none
 */
function* changePasswordInternal({ payload: { currentPassword, newPassword, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const optionsUpload = {
        body: JSON.stringify({ "old_password": currentPassword, "new_password": newPassword }),
        method: 'PATCH',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'user/update-password/', optionsUpload);
        yield put(changePasswordSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(changePasswordFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Modify user friendship
 * @param {*} payload - none
 */
function* modifyUserFriendshipInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'POST',
        body: { to_user: params.userPk, action: params.action },
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = BASE_URL + 'user/modify-friendship/';
        const response = yield call(fetchJSON, url, options);
        yield put(modifyUserFrienshipSuccess(params));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(modifyUserFrienshipFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Subscribe to Artist
 * @param {*} payload - none
 */
function* subscribeToArtistInternal({ payload: { artist, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: JSON.stringify({ "artist": artist }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'notification/post/', options);
        yield put(subscribeToArtistSuccess(artist));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, artist);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(subscribeToArtistFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Unsubscribe to Artist
 * @param {*} payload - none
 */
function* unsubscribeToArtistInternal({ payload: { artist, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: JSON.stringify({ "artist": artist }),
        method: 'DELETE',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'notification/post/', options);
        yield put(unsubscribeToArtistSuccess(artist));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, artist);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(unsubscribeToArtistFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


export function* watchGetProfile() {
    yield takeEvery(GET_PROFILE, getProfileInternal);
}

export function* watchGetVault() {
    yield takeEvery(GET_VAULT, getVaultInternal);
}

export function* watchGetArchive() {
    yield takeEvery(GET_ARCHIVE, getArchiveInternal);
}

export function* watchGetPlaylists() {
    yield takeEvery(GET_PLAYLISTS, getPlaylistsInternal);
}

export function* watchGetOwnedPlaylists() {
    yield takeEvery(GET_OWNED_PLAYLISTS, getOwnedPlaylistsInternal);
}

export function* watchGetUserReviews() {
    yield takeEvery(GET_USER_REVIEWS, getUserReviewsInternal);
}

export function* watchGetFriendlist() {
    yield takeEvery(GET_FRIENDLIST, getFriendlistInternal);
}

export function* watchUpdateProfile() {
    yield takeEvery(UPDATE_PROFILE, updateProfileInternal);
}

export function* watchUploadMusic() {
    yield takeEvery(UPLOAD_MUSIC, uploadMusicInternal);
}

export function* watchChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, changePasswordInternal);
}

export function* watchModifyUserFriendship() {
    yield takeEvery(MODIFY_USER_FRIENDSHIP, modifyUserFriendshipInternal);
}

export function* watchSubscribeArtist() {
    yield takeEvery(SUBSCRIBE, subscribeToArtistInternal);
}

export function* watchUnsubscribeArtist() {
    yield takeEvery(UNSUBSCRIBE, unsubscribeToArtistInternal);
}

function* profileSaga() {
    yield all([fork(watchGetProfile),
    fork(watchGetVault),
    fork(watchGetArchive),
    fork(watchGetPlaylists),
    fork(watchGetOwnedPlaylists),
    fork(watchGetUserReviews),
    fork(watchUpdateProfile),
    fork(watchUploadMusic),
    fork(watchGetFriendlist),
    fork(watchChangePassword),
    fork(watchModifyUserFriendship),
    fork(watchSubscribeArtist),
    fork(watchUnsubscribeArtist),
    ]);
}

export default profileSaga;
