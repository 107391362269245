import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getLoggedInUser, isUserAuthenticated } from "./authUtils";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const user = getLoggedInUser();
      const isAuthTokenValid = (user != null);
      const pathname = props?.location?.pathname ?? '';
      const loginNeeded = pathname.length > 0 && pathname != '/login' && pathname != '/register' && pathname != '/register-options' && pathname != '/welcome';
      return (
        <Layout>
          {(!loginNeeded || (loginNeeded && isAuthTokenValid)) &&
            <Component {...props} />
          }

          {loginNeeded && !isAuthTokenValid &&
            <Redirect to={{ pathname: process.env.PUBLIC_URL + '/login', state: { nextPath: (props?.location?.pathname ?? '') + (props?.location?.search ?? '') } }} />
          }
        </Layout>
      )
    }}
  />
);

export default AppRoute;
