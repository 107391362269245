/* AUTH */
export const RESET_POSTDETAIL = 'RESET_POSTDETAIL';
export const RESET_POSTDETAIL_SUCCESS = 'RESET_POSTDETAIL_SUCCESS';
export const RESET_POSTDETAIL_FAILED = 'RESET_POSTDETAIL_FAILED';

export const GET_POSTDETAIL = 'GET_POSTDETAIL';
export const GET_POSTDETAIL_SUCCESS = 'GET_POSTDETAIL_SUCCESS';
export const GET_POSTDETAIL_FAILED = 'GET_POSTDETAIL_FAILED';

export const GET_REVIEWS = 'GET_REVIEWS';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILED = 'GET_REVIEWS_FAILED';

export const UP_VOTE = 'UP_VOTE';
export const UP_VOTE_SUCCESS = 'UP_VOTE_SUCCESS';
export const UP_VOTE_FAILED = 'UP_VOTE_FAILED';

export const GET_REVIEWDETAILS = 'GET_REVIEWDETAILS';
export const GET_REVIEWDETAILS_SUCCESS = 'GET_REVIEWDETAILS_SUCCESS';
export const GET_REVIEWDETAILS_FAILED = 'GET_REVIEWDETAILS_FAILED';

export const HIDE_REPLIES = 'HIDE_REPLIES';
export const HIDE_REPLIES_SUCCESS = 'HIDE_REPLIES_SUCCESS';
export const HIDE_REPLIES_FAILED = 'HIDE_REPLIES_FAILED';

export const UP_VOTE_REPLY = 'UP_VOTE_REPLY';
export const UP_VOTE_REPLY_SUCCESS = 'UP_VOTE_REPLY_SUCCESS';
export const UP_VOTE_REPLY_FAILED = 'UP_VOTE_REPLY_FAILED';

export const REPLY_REVIEW = 'REPLY_REVIEW';
export const REPLY_REVIEW_SUCCESS = 'REPLY_REVIEW_SUCCESS';
export const REPLY_REVIEW_FAILED = 'REPLY_REVIEW_FAILED';

export const POST_REVIEW = 'POST_REVIEW';
export const POST_REVIEW_SUCCESS = 'POST_REVIEW_SUCCESS';
export const POST_REVIEW_FAILED = 'POST_REVIEW_FAILED';

export const ADD_TO_ARCHIVE = 'ADD_TO_ARCHIVE';
export const ADD_TO_ARCHIVE_SUCCESS = 'ADD_TO_ARCHIVE_SUCCESS';
export const ADD_TO_ARCHIVE_FAILED = 'ADD_TO_ARCHIVE_FAILED';

export const ADD_TO_PLAYLIST = 'ADD_TO_PLAYLIST';
export const ADD_TO_PLAYLIST_SUCCESS = 'ADD_TO_PLAYLIST_SUCCESS';
export const ADD_TO_PLAYLIST_FAILED = 'ADD_TO_PLAYLIST_FAILED';

export const ADD_TO_MULTIPLE_PLAYLISTS = 'ADD_TO_MULTIPLE_PLAYLISTS';
export const ADD_TO_MULTIPLE_PLAYLISTS_SUCCESS = 'ADD_TO_MULTIPLE_PLAYLISTS_SUCCESS';
export const ADD_TO_MULTIPLE_PLAYLISTS_FAILED = 'ADD_TO_MULTIPLE_PLAYLISTS_FAILED';

export const EDIT_REVIEW = 'EDIT_REVIEW';
export const EDIT_REVIEW_SUCCESS = 'EDIT_REVIEW_SUCCESS';
export const EDIT_REVIEW_FAILED = 'EDIT_REVIEW_FAILED';

export const DELETE_REVIEW = 'DELETE_REVIEW';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILED = 'DELETE_REVIEW_FAILED';

export const EDIT_REPLY = 'EDIT_REPLY';
export const EDIT_REPLY_SUCCESS = 'EDIT_REPLY_SUCCESS';
export const EDIT_REPLY_FAILED = 'EDIT_REPLY_FAILED';

export const DELETE_REPLY = 'DELETE_REPLY';
export const DELETE_REPLY_SUCCESS = 'DELETE_REPLY_SUCCESS';
export const DELETE_REPLY_FAILED = 'DELETE_REPLY_FAILED';

export const GET_DIGFEEDS = 'GET_DIGFEEDS';
export const GET_DIGFEEDS_SUCCESS = 'GET_DIGFEEDS_SUCCESS';
export const GET_DIGFEEDS_FAILED = 'GET_DIGFEEDS_FAILED';

export const GET_DIGFEEDS_BY_TAG = 'GET_DIGFEEDS_BY_TAG';
export const GET_DIGFEEDS_BY_TAG_SUCCESS = 'GET_DIGFEEDS_BY_TAG_SUCCESS';
export const GET_DIGFEEDS_BY_TAG_FAILED = 'GET_DIGFEEDS_BY_TAG_FAILED';

export const GET_FOLLOWING_POSTS = 'GET_FOLLOWING_POSTS';
export const GET_FOLLOWING_POSTS_SUCCESS = 'GET_FOLLOWING_POSTS_SUCCESS';
export const GET_FOLLOWING_POSTS_FAILED = 'GET_FOLLOWING_POSTS_FAILED';

export const EDIT_POST = 'EDIT_POST';
export const EDIT_POST_SUCCESS = 'EDIT_POST_SUCCESS';
export const EDIT_POST_FAILED = 'EDIT_POST_FAILED';

export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILED = 'DELETE_POST_FAILED';

export const REPORT = 'REPORT';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_FAILED = 'REPORT_FAILED';

export const FOLLOW_USER = 'FOLLOW_USER';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAILED = 'FOLLOW_USER_FAILED';