import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { isMobile } from 'react-device-detect';

// import ImageViewer from 'react-image-viewer-zoom'
// import 'react-image-viewer-zoom/dist/style.css'

import { XCircle } from "react-feather";

export const SHARE_NOTHING = -1;
export const SHARE_POST_ACTION = 0;
export const SHARE_PROFILE_ACTION = 1;
export const SHARE_PLAYLIST_ACTION = 2;

class AttachmentImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
    };
    this.inputOpenFileRef = React.createRef()
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }



  onBack = () => {
    this.props.onClose()
  }


  render() {
    const bg = {
      modal: {
        background: "#000000",
        width: isMobile ? '90%' : '500px'
      },
      overlay: {
        background: "#c0c0c060"
      }
    };


    let content = (
      <Modal open={this.state.modalIsOpen} onClose={this.onBack} center
        styles={bg} showCloseIcon={true} closeIcon={<XCircle color="#ffffff" />}>
        <img className='full-width' src={this.props.image}/>

      </Modal>
    );

    return <Fragment>{content}</Fragment>;
  }
}



export default  AttachmentImageViewer;
