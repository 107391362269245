// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    GET_NOTIFICATIONS, MARK_AS_READ, GET_FRIENDSHIP_REQUESTS
} from './constants';
import { BASE_URL } from './../constants';
import { getLoggedInUser } from '../../helpers/authUtils';

import {
    getNotificationsSuccess,
    getNotificationsFailed,

    markAsReadSuccess,
    markAsReadFailed,

    getFriendshipRequestsSuccess,
    getFriendshipRequestsFailed,

} from './actions';

/**
 * Get profile
 * @param {*} payload - none
 */
function* getNotificationsInternal({ payload: url_slug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = url_slug ? (url_slug.startsWith(BASE_URL) ? url_slug : BASE_URL + url_slug.substring(1)) : BASE_URL + 'user/notifications/';
        const response = yield call(fetchJSON, url, options);
        yield put(getNotificationsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getNotificationsFailed(message));
    }
}

function* markAsReadInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'PATCH',
        body: params,
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = BASE_URL + 'user/notifications/';
        const response = yield call(fetchJSON, url, options);
        yield put(markAsReadSuccess(response));

        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(markAsReadFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Get profile
 * @param {*} payload - none
 */
function* getFriendshipRequestsInternal() {
    const options = {
        method: 'GET',
        body: null,
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = BASE_URL + 'user/friendship/request/';
        const response = yield call(fetchJSON, url, options);
        yield put(getFriendshipRequestsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getFriendshipRequestsFailed(message));
    }
}


export function* watchGetNotifications() {
    yield takeEvery(GET_NOTIFICATIONS, getNotificationsInternal);
}

export function* watchMarkAsRead() {
    yield takeEvery(MARK_AS_READ, markAsReadInternal);
}

export function* watchGetFriendshipRequests() {
    yield takeEvery(GET_FRIENDSHIP_REQUESTS, getFriendshipRequestsInternal);
}


function* notificationSaga() {
    yield all([fork(watchGetNotifications), 
        fork(watchMarkAsRead),
        fork(watchGetFriendshipRequests),
    ]);
}

export default notificationSaga;
