// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    LOGIN_USER, REFRESH_TOKEN, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD, CONFIRM_PASSWORD, REGISTER_VERIFICATION,
    RESEND_CODE,
    RESEND_CODE_SUCCESS
} from './constants';
import { BASE_URL } from './../constants';
import { getLoggedInUser, setLoggedInUser } from '../../helpers/authUtils';

import {
    loginUserSuccess,
    loginUserFailed,
    refreshTokenSuccess,
    refreshTokenFailed,
    registerUserSuccess,
    registerUserFailed,
    registerVerificationSuccess,
    registerVerificationFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    confirmPasswordSuccess,
    confirmPasswordFailed,
    confirmPassword,
    resendCodeSuccess,
    resendCodeFailed,
    refreshToken

} from './actions';


/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { info, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: info,
        method: 'POST',
        requestToken: false,
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'user/api-token-auth', options);
        setLoggedInUser(response);
        yield put(loginUserSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setLoggedInUser(null);
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Refresh Token
 * @param {*} payload - refresh
 */
function* refreshTokenInternal({ payload: { refresh, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: { refresh },
        method: 'POST',
        requestToken: false,
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'user/api-token-refresh/', options);
        yield put(refreshTokenSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(refreshTokenFailed(message));
        setLoggedInUser(null);
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setLoggedInUser(null);
        yield call(() => {
            history.push('/');
        });
    } catch (error) { }
}

/**
 * Register the user
 */
function* register({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: JSON.stringify(params),
        method: 'POST',
        requestToken: false,
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'user/users/', options);
        console.log(response.toString());
        yield put(registerUserSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Verify Registration
 */
function* verifyRegistrationInternal({ payload: { code, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: JSON.stringify({ code: code }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'user/phone-verify/', options);
        console.log(response.toString());
        yield put(registerVerificationSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerVerificationFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * forget password
 */
function* forgetPasswordInternal({ payload: { email, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: { email },
        method: 'POST',
        requestToken: false,
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'user/password_reset/', options);
        yield put(forgetPasswordSuccess(response.message));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * update password
 */
function* confirmPasswordInternal({ payload: { password, token, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: { password, token },
        method: 'POST',
        requestToken: false,
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'user/password_reset/confirm/', options);
        yield put(confirmPasswordSuccess(response.message));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(confirmPasswordFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}



/**
 * resend code
 */
function* resendCodeInternal({ payload: { callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'user/phone-verify/', options);
        yield put(resendCodeSuccess(response.message));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(resendCodeFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchRefreshToken() {
    yield takeEvery(REFRESH_TOKEN, refreshTokenInternal);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchRegisterVefirication() {
    yield takeEvery(REGISTER_VERIFICATION, verifyRegistrationInternal);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPasswordInternal);
}

export function* watchConfirmPassword() {
    yield takeEvery(CONFIRM_PASSWORD, confirmPasswordInternal);
}

export function* watchResendCode() {
    yield takeEvery(RESEND_CODE, resendCodeInternal);
}

function* authSaga() {
    yield all([fork(watchLoginUser),
    fork(watchRefreshToken),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchRegisterVefirication),
    fork(watchForgetPassword),
    fork(watchConfirmPassword),
    fork(watchResendCode)]);
}

export default authSaga;
