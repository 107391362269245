import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { ControlledMenu } from "@szhsin/react-menu";

import {
    getQueues,
    moveQueue,
    removeFromQueue,
    playPost,
    setPlayStatus,

} from "../../redux/actions";

import { Preloader, ErrorMessage } from "../../components";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { isMobile } from 'react-device-detect';
import { Container, Draggable } from "@richardrout/react-smooth-dnd";

class QueueDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true,
            selectedPks: [],
            loading: false,
            anchorPoint: null,
            isOpen: false,
            selectedIndex: -1
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        this.props.getQueues(null, false, {
            callbackOnBegin: () => {
                // Update loading state?
                this.setState({ loading: true });
            },
            callbackOnFailure: (error) => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnFinish: () => {
                console.log('callbackOnFinish');
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {

            },
        })
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const innerHeight = window.innerHeight;
        console.log('innerHeight: ' + innerHeight);
        const tab = document.getElementById('tab');
        if (tab) {
            const tabFrame = tab.getBoundingClientRect();
            console.log('tabFrame: ' + tabFrame.bottom);

            if (tabFrame.bottom < innerHeight * 1.5) {
                if (!this.props.loading) {
                    this.getNextQueues();
                }
            }
        }

    }

    getNextQueues = () => {
        const urlslug = this.props.queues ? this.props.queues.next_page : null;
        if (urlslug) {
            this.props.getQueues(urlslug, false, {
                callbackOnBegin: () => {
                    // Update loading state?
                },
                callbackOnFailure: (error) => {
                    // Update loading state?
                },
                callbackOnFinish: () => {
                    console.log('callbackOnFinish');
                },
                callbackOnSuccess: (response) => {
                },
            });
            console.log('getNextQueues');
        }
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal = () => {

    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
        this.props.onClose()
    }

    isSelectedPost = (pk) => {
        return this.state.selectedPks.includes(pk)
    }

    toogleSelection = (pk) => {
        const selectedPks = this.state.selectedPks;
        if (selectedPks.includes(pk)) {
            const index = selectedPks.indexOf(pk);
            if (index > -1) {
                selectedPks.splice(index, 1);
            }
        }
        else {
            selectedPks.push(pk);
        }

        this.setState({ selectedPks: selectedPks, isOpen: false })
    }

    onClear = (e) => {
        this.props.removeFromQueue([], {
            callbackOnBegin: () => {
                // Update loading state?
                this.setState({ loading: true });
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                if (this.props.posts && (this.props.index < this.props.posts.length - 1)) {
                    // Set status to next track
                    this.playNextQueueingOrCurrentIndex()
                }
                else {
                    // Set status to not play
                    this.props.setPlayStatus(0);
                }
                this.closeModal()
            },
        })
    }

    moveToTop = (e) => {
        let pk = e.pk
        if (pk == null) {
            return
        }

        this.props.moveQueue({ queue_song_pk: pk }, {
            callbackOnBegin: () => {
                // Update loading state?
                //this.setState({ loading: true });
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
            },
            callbackOnFinish: () => {
                // Update loading state?
                //this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {

            },
        })
    }

    removeFromQueue = (e) => {
        let pk = e.pk
        if (pk == null) {
            return
        }

        this.props.removeFromQueue([pk], {
            callbackOnBegin: () => {
                // Update loading state?
                this.setState({ loading: true });
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
            },
            callbackOnFinish: () => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {
                if ((response.posts_in_queue ?? []).length > 0) {
                    if (this.props.queueing == null) {
                        this.getQueueing()
                    }
                }
                else {
                    if (this.props.posts && (this.props.index < this.props.posts.length - 1)) {
                        // Set status to next track
                        this.playNextQueueingOrCurrentIndex()
                    }
                    else {
                        // Set status to not play
                        this.props.setPlayStatus(0);
                    }
                    this.closeModal()
                }
            },
        })
    }

    getQueueing = () => {
        this.props.getQueues(null, true, {
            callbackOnBegin: () => {
                // Update loading state?
                this.setState({ loading: true });
            },
            callbackOnFailure: (error) => {
                // Update loading state?
                this.setState({ loading: false });
            },
            callbackOnFinish: () => {
                console.log('callbackOnFinish');
            },
            callbackOnSuccess: (response) => {
                if ((response.posts_in_queue ?? []).length > 0) {
                    this.playNextQueueingOrCurrentIndex()
                }
                else if (this.props.posts && (this.props.index < this.props.posts.length - 1)) {
                    // Set status to next track
                    this.props.setPlayStatus(2);
                }
                else {
                    // Set status to not play
                    this.props.setPlayStatus(0);
                }
            },
        })
    }

    playNextQueueingOrCurrentIndex = () => {
        const posts = this.props.posts
        const index = this.props.index
        const played = this.props.played
        const visible = this.props.visible

        this.props.playPost(posts, index, played, visible)
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.addedIndex) {
            return;
        }

        var songPk = null;
        var queues = this.props.queues && this.props.queues.posts_in_queue ? this.props.queues.posts_in_queue : null
        if (queues) {
            queues = queues.filter(function (el) {
                return el.post != null;
            });
        }

        if (queues.length > result.removedIndex) {
            songPk = queues[result.removedIndex] && queues[result.removedIndex].pk
        }

        if (songPk == null) {
            return;
        }

        this.props.moveQueue({ queue_song_pk: songPk, to_position: result.addedIndex }, {
            callbackOnBegin: () => {
                // Update loading state?
                //this.setState({ loading: true });
            },
            callbackOnFailure: (error) => {
                // show error to your users or stay quiet
            },
            callbackOnFinish: () => {
                // Update loading state?
                //this.setState({ loading: false });
            },
            callbackOnSuccess: (response) => {

            },
        })

    }

    onCloseMenu = () => {
        this.setState({ anchorPoint: null, isOpen: false })
    }

    render() {
        const bg = {
            modal: {
                background: "#000000",
                height: '80vh',
                width: isMobile ? '90%' : '500px'
            },
            overlay: {
                background: "#c0c0c060"
            }
        };

        var queues = this.props.queues && this.props.queues.posts_in_queue ? this.props.queues.posts_in_queue : null
        if (queues) {
            queues = queues.filter(function (el) {
                return el.post != null;
            });
        }
        const className = isMobile ? " full-width" : " max-width-desktop";
        const selectedPost = (queues && (this.state.selectedIndex >= 0) && (queues.length > this.state.selectedIndex)) ? queues[this.state.selectedIndex] : null;

        let content = (
            <Modal open={this.state.modalIsOpen} onClose={this.closeModal}
                styles={bg} closeIcon={null} showCloseIcon={false} blockScroll={false}>
                {this.state.loading && <Preloader />}

                <div className={className}>
                    <ControlledMenu anchorPoint={this.state.anchorPoint} isOpen={this.state.isOpen}
                        onClose={() => this.onCloseMenu()}>
                        <MenuItem onClick={() => {
                            this.moveToTop(selectedPost)
                        }}>Move to top</MenuItem>
                        <MenuItem onClick={() => {
                            this.removeFromQueue(selectedPost)
                        }}>Remove from Queue</MenuItem>
                    </ControlledMenu>

                    <div className="modal-content">
                        <div className="item-sticky" >
                            <div className="header-container">
                                <button className="header-button" onClick={this.closeModal}>
                                    <img src={process.env.PUBLIC_URL + "/assets/img/buttons/back.png"}
                                        alt=""
                                    />
                                </button>
                                <label className="title-label">Queue</label>
                                <button className="feeds__pink-button" disabled={this.props.queues.length == 0}
                                    onClick={this.onClear}>Clear</button>
                            </div>
                        </div>

                        <div className={"row" + className} id='queues_content'>
                            <Container className='full-width' onDrop={this.onDragEnd} lockAxis="y">
                                {queues && queues.map((single, index) => {
                                    console.log(single ? single.toString() : '');
                                    const pk = (single.pk ? single.pk : single.post.pk).toString();
                                    return (
                                        <Draggable key={pk} draggableId={pk} index={index}>
                                            <div className="full-width space-mb--5">
                                                <div className="archive__container" onClick={() => { this.toogleSelection(single.post ? single.post.pk : null) }}>
                                                    <div className="left-view"
                                                        onContextMenu={e => {
                                                            e.preventDefault();
                                                            this.setState({ anchorPoint: { x: e.clientX, y: e.clientY }, isOpen: true, selectedIndex: index })
                                                        }}>
                                                        <div className="image">
                                                            <img
                                                                src={single?.post?.cover_art_file ?? (process.env.PUBLIC_URL + '/assets/img/common/ugs_logo.png')}
                                                                className="thumbnail"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="info">
                                                            <span className="song-name">
                                                                {single.post && single.post.song_name ? single.post.song_name : ''}
                                                            </span>
                                                            <span className="artist-name">
                                                                {single.post && single.post.artist_name ? single.post.artist_name : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="right-view">
                                                        <Menu menuButton={<MenuButton className="header-button">
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/buttons/more.png"}
                                                                alt=""
                                                                className="menu-button-image"
                                                            /></MenuButton>}>
                                                            <MenuItem onClick={() => {
                                                                this.moveToTop(single)
                                                            }}>Move to top</MenuItem>
                                                            <MenuItem onClick={() => {
                                                                this.removeFromQueue(single)
                                                            }}>Remove from Queue</MenuItem>
                                                        </Menu>
                                                    </div>
                                                </div>
                                            </div>
                                        </Draggable>
                                    );
                                })}
                            </Container>
                        </div>

                    </div>
                </div>

            </Modal>

        );

        return <Fragment>{content}</Fragment>;
    }
}


const mapStateToProps = (state) => {
    const { queues, loading, error } = state.Queue;
    const { queueing, posts, index, played, visible, status } = state.Player;
    return { queues, queueing, posts, index, played, visible, status, loading, error };
};

export default connect(mapStateToProps, { getQueues, moveQueue, removeFromQueue, playPost, setPlayStatus })(QueueDialog);

