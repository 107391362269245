// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,

    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILED,

    LOGOUT_USER,

    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    
    REGISTER_VERIFICATION,
    REGISTER_VERIFICATION_FAILED,
    REGISTER_VERIFICATION_SUCCESS,

    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,

    CONFIRM_PASSWORD,
    CONFIRM_PASSWORD_SUCCESS,
    CONFIRM_PASSWORD_FAILED,

    RESEND_CODE,
    RESEND_CODE_SUCCESS,
    RESEND_CODE_FAILED
     
} from './constants';


export const loginUser = (info, callbacks) => ({
    type: LOGIN_USER,
    payload: { info, callbacks },
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserFailed = (error) => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const refreshToken = (refresh, callbacks) => ({
    type: REFRESH_TOKEN,
    payload: { refresh, callbacks },
});

export const refreshTokenSuccess = (results) => ({
    type: REFRESH_TOKEN_SUCCESS,
    payload: results,
});

export const refreshTokenFailed = (error) => ({
    type: REFRESH_TOKEN_FAILED,
    payload: error,
});

export const registerUser = (params, callbacks) => ({
    type: REGISTER_USER,
    payload: { params, callbacks },
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});


export const registerVerification = (code, callbacks) => ({
    type: REGISTER_VERIFICATION,
    payload: { code, callbacks },
});

export const registerVerificationSuccess = (results) => ({
    type: REGISTER_VERIFICATION_SUCCESS,
    payload: results,
});

export const registerVerificationFailed = (error) => ({
    type: REGISTER_VERIFICATION_FAILED,
    payload: error,
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const forgetPassword = (email, callbacks) => ({
    type: FORGET_PASSWORD,
    payload: { email, callbacks },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});

export const confirmPassword = (password, token, callbacks) => ({
    type: CONFIRM_PASSWORD,
    payload: { password, token, callbacks },
});

export const confirmPasswordSuccess = (passwordConfirmStatus) => ({
    type: CONFIRM_PASSWORD_SUCCESS,
    payload: passwordConfirmStatus,
});

export const confirmPasswordFailed = (error) => ({
    type: CONFIRM_PASSWORD_FAILED,
    payload: error,
});


export const resendCode = (callbacks) => ({
    type: RESEND_CODE,
    payload: { callbacks },
});

export const resendCodeSuccess = (result) => ({
    type: RESEND_CODE_SUCCESS,
    payload: result,
});

export const resendCodeFailed = (error) => ({
    type: RESEND_CODE_FAILED,
    payload: error,
});
