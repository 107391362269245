import React, { Component, Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import MiniMediaPlayer from "./MiniMediaPlayer";

const Footer = (props) => {
  const hasNewMessage = useSelector((state) => state.Chat.hasNewMessage)
  const hasNewNotification = false;// useSelector((state) => state.Notification.hasNewNotification)
  const history = useHistory()
  return (
    <footer>
      <div className="footer-nav-wrapper">

        <div className='tab-bar full-width aligned-center'>
          <MiniMediaPlayer {...props} history={history}/>

          <div className="footer-nav-buttons">
            <Link
              to={process.env.PUBLIC_URL + "/home"}
              className="footer-nav-single"
            >
              <div className="menu-wrapper">
                <div className='icon-container'>
                  <img className="icon"
                    src={process.env.PUBLIC_URL + "/assets/img/tabbars/home.png"}
                  />
                  {hasNewNotification && <div className="red-dot"/>}
                </div>
                {(window.location.pathname == "/home") && <img className="indicator"
                  src={process.env.PUBLIC_URL + "/assets/img/tabbars/active.png"}
                />}
              </div>
            </Link>
            <Link
              to={process.env.PUBLIC_URL + "/search"}
              className="footer-nav-single"
            >
              <div className="menu-wrapper">
                <img className="icon"
                  src={process.env.PUBLIC_URL + "/assets/img/tabbars/search.png"}
                />
                {(window.location.pathname == "/search") && <img className="indicator"
                  src={process.env.PUBLIC_URL + "/assets/img/tabbars/active.png"}
                />}
              </div>
            </Link>

            <Link
              to={process.env.PUBLIC_URL + "/profile"}
              className="footer-nav-single"
            >
              <div className="menu-wrapper">
                <img className="icon"
                  src={process.env.PUBLIC_URL + "/assets/img/tabbars/profile.png"}
                />
                {(window.location.pathname == "/profile") && <img className="indicator"
                  src={process.env.PUBLIC_URL + "/assets/img/tabbars/active.png"}
                />}
              </div>
            </Link>

            <Link
              to={process.env.PUBLIC_URL + "/music"}
              className="footer-nav-single"
            >
              <div className="menu-wrapper">
                <img className="icon"
                  src={process.env.PUBLIC_URL + "/assets/img/tabbars/music.png"}
                />
                {(window.location.pathname == "/music") && <img className="indicator"
                  src={process.env.PUBLIC_URL + "/assets/img/tabbars/active.png"}
                />}
              </div>
            </Link>

            {/*           
          <Link
            to={process.env.PUBLIC_URL + "/notification"}
            className="footer-nav-single"
          >
            <div className="menu-wrapper">
              <img className="icon"
                src={process.env.PUBLIC_URL + "/assets/img/tabbars/notife.png"}
              />
              {(window.location.pathname == "/notification") && <img className="indicator"
                src={process.env.PUBLIC_URL + "/assets/img/tabbars/active.png"}
              />}
            </div>
          </Link> */}
            <Link
              to={process.env.PUBLIC_URL + "/chat"}
              className="footer-nav-single"
            >
              <div className="menu-wrapper">
                <div className='icon-container'>
                  <img className="icon"
                    src={process.env.PUBLIC_URL + "/assets/img/tabbars/chat.png"}
                  />
                  {hasNewMessage && <div className="red-dot"/>}
                </div>
                {(window.location.pathname == "/chat") && <img className="indicator"
                  src={process.env.PUBLIC_URL + "/assets/img/tabbars/active.png"}
                />}
              </div>
            </Link>

          </div>

        </div>

      </div>
    </footer>
  );
};

export default Footer;
