// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import profileSaga from './profile/saga';
import postSaga from './post/saga';
import playlistSaga from './playlist/saga';
import notificationSaga from './notification/saga';
import playerSaga from './player/saga';
import supportSaga from './support/saga';
import searchSaga from './search/saga';
import analyticSaga from './analytic/saga';
import queueSaga from './queue/saga';
import chatSaga from './chat/saga';

export default function* rootSaga(getState) {
    yield all([authSaga(), profileSaga(), postSaga(), playlistSaga(), notificationSaga(), 
        playerSaga(), supportSaga(), searchSaga(), analyticSaga(), queueSaga(), chatSaga()]);
}
