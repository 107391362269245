// @flow
import {
    ANALYTIC_POST_LISTEN_DURATION,
    ANALYTIC_POST_LISTEN_DURATION_SUCCESS,
    ANALYTIC_POST_LISTEN_DURATION_FAILED,

} from './constants';

const INIT_STATE = {
    // notifications: null,
    loading: false,
    error: null
};


const Analytic = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ANALYTIC_POST_LISTEN_DURATION:
            return { ...state, loading: true };
        case ANALYTIC_POST_LISTEN_DURATION_SUCCESS:
            return { ...state, loading: false, error: null };
        case ANALYTIC_POST_LISTEN_DURATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Analytic;
