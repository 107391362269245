/* AUTH */
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';

export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_FAILED = 'MARK_AS_READ_FAILED';

export const GET_FRIENDSHIP_REQUESTS = 'GET_FRIENDSHIP_REQUESTS';
export const GET_FRIENDSHIP_REQUESTS_SUCCESS = 'GET_FRIENDSHIP_REQUESTS_SUCCESS';
export const GET_FRIENDSHIP_REQUESTS_FAILED = 'GET_FRIENDSHIP_REQUESTS_FAILED';
