// @flow
import {
    RESET_POSTDETAIL,
    RESET_POSTDETAIL_SUCCESS,
    RESET_POSTDETAIL_FAILED,
    GET_POSTDETAIL,
    GET_POSTDETAIL_SUCCESS,
    GET_POSTDETAIL_FAILED,
    GET_REVIEWS,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAILED,
    UP_VOTE,
    UP_VOTE_SUCCESS,
    UP_VOTE_FAILED,
    GET_REVIEWDETAILS,
    GET_REVIEWDETAILS_SUCCESS,
    GET_REVIEWDETAILS_FAILED,
    HIDE_REPLIES,
    HIDE_REPLIES_SUCCESS,
    HIDE_REPLIES_FAILED,
    UP_VOTE_REPLY,
    UP_VOTE_REPLY_SUCCESS,
    UP_VOTE_REPLY_FAILED,
    REPLY_REVIEW,
    REPLY_REVIEW_SUCCESS,
    REPLY_REVIEW_FAILED,
    POST_REVIEW,
    POST_REVIEW_SUCCESS,
    POST_REVIEW_FAILED,

    ADD_TO_ARCHIVE,
    ADD_TO_ARCHIVE_SUCCESS,
    ADD_TO_ARCHIVE_FAILED,

    ADD_TO_PLAYLIST,
    ADD_TO_PLAYLIST_SUCCESS,
    ADD_TO_PLAYLIST_FAILED,

    ADD_TO_MULTIPLE_PLAYLISTS,
    ADD_TO_MULTIPLE_PLAYLISTS_SUCCESS,
    ADD_TO_MULTIPLE_PLAYLISTS_FAILED,

    EDIT_REVIEW,
    EDIT_REVIEW_SUCCESS,
    EDIT_REVIEW_FAILED,

    DELETE_REVIEW,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAILED,

    EDIT_REPLY,
    EDIT_REPLY_SUCCESS,
    EDIT_REPLY_FAILED,

    DELETE_REPLY,
    DELETE_REPLY_SUCCESS,
    DELETE_REPLY_FAILED,

    GET_DIGFEEDS,
    GET_DIGFEEDS_SUCCESS,
    GET_DIGFEEDS_FAILED,

    GET_DIGFEEDS_BY_TAG,
    GET_DIGFEEDS_BY_TAG_SUCCESS,
    GET_DIGFEEDS_BY_TAG_FAILED,

    GET_FOLLOWING_POSTS,
    GET_FOLLOWING_POSTS_SUCCESS,
    GET_FOLLOWING_POSTS_FAILED,

    EDIT_POST,
    EDIT_POST_SUCCESS,
    EDIT_POST_FAILED,

    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILED,

    REPORT,
    REPORT_SUCCESS,
    REPORT_FAILED,

    FOLLOW_USER,
    FOLLOW_USER_SUCCESS,
    FOLLOW_USER_FAILED,

} from './constants';

import {

    REQUEST_FRIENDSHIP,
    REQUEST_FRIENDSHIP_SUCCESS,
    REQUEST_FRIENDSHIP_FAILED,

    DELETE_FRIENDSHIP_REQUEST,
    DELETE_FRIENDSHIP_REQUEST_SUCCESS,
    DELETE_FRIENDSHIP_REQUEST_FAILED,

} from '../search/constants';

const INIT_STATE = {
    postdetail: null,
    loading: false,
    loadingReviews: false,
    error: null,
    feeds: null,
    feedsByTag: null,
    followingPosts: null,
    loadingFollowingPosts: false,
    reportStatus: null
};


const Post = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_POSTDETAIL:
            return { ...state, loading: true };
        case RESET_POSTDETAIL_SUCCESS:
            return { ...state, postdetail: null, loading: false, error: null };
        case RESET_POSTDETAIL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_POSTDETAIL:
            return { ...state, loading: true };
        case GET_POSTDETAIL_SUCCESS:
            var currentFeeds = state.feeds;
            if (currentFeeds?.results) {
                let index = currentFeeds.results.findIndex(element => { return element.pk == action.payload.post?.pk });
                if (index >= 0) {
                    currentFeeds.results[index] = action.payload.post
                }
            }
            return { ...state, feeds: currentFeeds, postdetail: action.payload, loading: false, error: null };
        case GET_POSTDETAIL_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_REVIEWS:
            return { ...state, loadingReviews: true };
        case GET_REVIEWS_SUCCESS:
            var pd = state.postdetail;
            pd.reviews = pd.reviews.concat(action.payload.reviews);
            pd.previous_page = action.payload.previous_page;
            pd.next_page = action.payload.next_page;
            return { ...state, postdetail: pd, loadingReviews: false, error: null };
        case GET_REVIEWS_FAILED:
            return { ...state, error: action.payload, loadingReviews: false };

        case UP_VOTE:
            return { ...state, loading: true };
        case UP_VOTE_SUCCESS:
            var pd = state.postdetail; //Object.assign({}, state.postdetail);
            var review = pd.reviews.find(element => element.pk == action.payload.parent_review_pk);
            review.current_user_upvoted = action.payload.upVote.current_user_upvoted;
            review.current_user_downvoted = action.payload.upVote.current_user_downvoted;
            review.num_upvotes = action.payload.upVote.num_upvotes;
            review.num_downvotes = action.payload.upVote.num_downvotes;

            return { ...state, postdetail: pd, loading: false, error: null };
        case UP_VOTE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_REVIEWDETAILS:
            return { ...state, loading: true };
        case GET_REVIEWDETAILS_SUCCESS:
            var pd = state.postdetail;
            var review = pd.reviews.find(element => element.pk == action.payload.parent_review.pk);
            if (review.replies == null) {
                review.replies = [];
            }
            let newReplies = action.payload.replies ?? [];
            for (let i = 0; i < newReplies.length; i++) {
                if (review.replies.findIndex(element => element.pk == newReplies[i].pk) < 0) {
                    review.replies = review.replies.concat(newReplies[i]);
                }
            }
            review.next_page = action.payload.next_page;
            return { ...state, postdetail: pd, loading: false, error: null };
        case GET_REVIEWDETAILS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case HIDE_REPLIES:
            return { ...state, loading: true };
        case HIDE_REPLIES_SUCCESS:
            var pd = state.postdetail;
            let reviews = pd.reviews;
            if (reviews) {
                let index = reviews.findIndex(element => element.pk == action.payload);
                if (index >= 0) {
                    reviews[index].replies = [];
                    reviews[index].next_page = null;
                }
                pd.reviews = reviews;
            }
            return { ...state, postdetail: pd, loading: false, error: null };
        case HIDE_REPLIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UP_VOTE_REPLY:
            return { ...state, loading: true };
        case UP_VOTE_REPLY_SUCCESS:
            var pd = state.postdetail;
            var review = pd.reviews.find(element => element.pk == action.payload.parent_review_pk);
            var reply = review.replies.find(element => element.pk == action.payload.parent_reply_pk);
            reply.current_user_upvoted = action.payload.upVote.current_user_upvoted;
            reply.current_user_downvoted = action.payload.upVote.current_user_downvoted;
            reply.num_upvotes = action.payload.upVote.num_upvotes;
            reply.num_downvotes = action.payload.upVote.num_downvotes;

            return { ...state, postdetail: pd, loading: false, error: null };
        case UP_VOTE_REPLY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case REPLY_REVIEW:
            return { ...state, loading: true };
        case REPLY_REVIEW_SUCCESS:
            // Update replies
            var pd = state.postdetail;
            var reviewIndex = pd.reviews.findIndex(element => element.pk == action.payload.parent_review_pk);
            if (reviewIndex >= 0) {
                reply = action.payload.reply
                reply.type = 1
                if (pd.reviews[reviewIndex].replies != null) {
                    pd.reviews[reviewIndex].replies.push(reply);
                }
                else {
                    pd.reviews[reviewIndex].replies = [reply]
                }
                pd.reviews[reviewIndex].num_of_replies = pd.reviews[reviewIndex].num_of_replies + 1;
            }
            return { ...state, postdetail: pd, loading: false, error: null };
        case REPLY_REVIEW_FAILED:
            return { ...state, error: action.payload, loading: false };

        case POST_REVIEW:
            return { ...state, loading: true };
        case POST_REVIEW_SUCCESS:
            // var pd = state.postdetail;
            // if (pd) {
            //     if (pd.reviews == null) {
            //         pd.reviews = [];
            //     }
            //     pd.reviews.push(action.payload);
            //     pd.post.reviewed_by_me = true;
            //     pd.post.number_of_reviews += 1
            // }
            // return { ...state, postdetail: pd, loading: false, error: null };
            return { ...state, loading: false, error: null };
        case POST_REVIEW_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ADD_TO_ARCHIVE:
            return { ...state, loading: true };
        case ADD_TO_ARCHIVE_SUCCESS:
            const response = action.payload
            if (response.action == null) {
                return { ...state, postdetail: state.postdetail, loading: false, error: null };
            }

            const isAdded = response.action ? (response.action == 'add') : false
            const pks = response.posts ? response.posts : [];

            if (state.postdetail != null) {
                for (let i = 0; i < pks.length; i++) {
                    if (state.postdetail.post.pk == pks[i]) {
                        state.postdetail.post.archived_by_me = isAdded
                        break
                    }
                }
            }
            // Feed
            var current = state.feeds;
            if (state.feeds != null) {
                if (response.action == null) {
                    return { ...state, feeds: current, loading: false };
                }

                const isAdded = response.action ? (response.action == 'add') : false
                const pks = response.posts ? response.posts : [];
                var posts = state.feeds && state.feeds.results ? state.feeds.results : []

                for (let i = 0; i < pks.length; i++) {
                    const index = posts.findIndex(post => post.pk === pks[i]);

                    if (index >= 0) {
                        posts[index].archived_by_me = isAdded
                    }
                }

                current.results = posts
            }

            return { ...state, postdetail: state.postdetail, feeds: current, loading: false, error: null };
        case ADD_TO_ARCHIVE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ADD_TO_PLAYLIST:
            return { ...state, loading: true };
        case ADD_TO_PLAYLIST_SUCCESS:
            return { ...state, action: action.payload, loading: false, error: null };
        case ADD_TO_PLAYLIST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_REVIEW:
            return { ...state, loading: true };
        case EDIT_REVIEW_SUCCESS:
            var pd = state.postdetail;
            var review = pd.reviews.find(element => element.pk == action.payload.pk);
            review.rating_given = action.payload.rating_given
            review.comment = action.payload.comment
            return { ...state, postdetail: pd, loading: false, error: null };

        case EDIT_REVIEW_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_REVIEW:
            return { ...state, loading: true };
        case DELETE_REVIEW_SUCCESS:
            var pd = state.postdetail;
            if (pd) {
                let index = pd.reviews.findIndex(element => element.pk == action.payload);
                if (index >= 0) {
                    pd.reviews.splice(index, 1);
                }
                pd.post.reviewed_by_me = false;
            }
            return { ...state, postdetail: pd, loading: false, error: null };
        case DELETE_REVIEW_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_REPLY:
            return { ...state, loading: true };
        case EDIT_REPLY_SUCCESS:
            var pd = state.postdetail;
            var review = pd.reviews.find(element => element.pk == action.payload.parent_review);
            var reply = review.replies.find(element => element.pk == action.payload.pk);
            reply.comment = action.payload.comment;
            return { ...state, postdetail: pd, loading: false, error: null };

        case DELETE_REPLY:
            return { ...state, loading: true };
        case DELETE_REPLY_SUCCESS:
            var pd = state.postdetail;
            for (let i = 0; i < pd.reviews.length; i++) {
                let index = pd.reviews[i].replies.findIndex(element => element.pk == action.payload);
                if (index >= 0) {
                    pd.reviews[i].replies.splice(index, 1);
                    pd.reviews[i].num_of_replies -= 1
                    break
                }
            }
            return { ...state, postdetail: pd, loading: false, error: null };
        case DELETE_REPLY_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_DIGFEEDS:
            return { ...state, loading: true };
        case GET_DIGFEEDS_SUCCESS:
            var current = state.feeds;
            if (current) {
                current.next_page = action.payload.next_page;
                current.previous_page = action.payload.previous_page;

                if (action.payload.previous_page == null) {
                    current.results = []
                }

                action.payload.results.forEach(post => {
                    var isExisting = false;
                    for (var i = 0; i < current.results.length; i++) {
                        if (current.results[i].pk == post.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        current.results.push(post);
                    }
                });
            }
            else {
                current = action.payload;
            }

            return { ...state, feeds: current, loading: false, error: null };
        case GET_DIGFEEDS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_DIGFEEDS_BY_TAG:
            return { ...state, loading: true };
        case GET_DIGFEEDS_BY_TAG_SUCCESS:
            var current = state.feedsByTag;
            if (current) {
                current.next_page = action.payload.next_page;
                current.previous_page = action.payload.previous_page;

                if (action.payload.previous_page == null) {
                    current.results = []
                }

                action.payload.results.forEach(post => {
                    var isExisting = false;
                    for (var i = 0; i < current.results.length; i++) {
                        if (current.results[i].pk == post.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        current.results.push(post);
                    }
                });
            }
            else {
                current = action.payload;
            }

            return { ...state, feedsByTag: current, loading: false, error: null };
        case GET_DIGFEEDS_BY_TAG_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_FOLLOWING_POSTS:
            return { ...state, loadingFollowingPosts: true };
        case GET_FOLLOWING_POSTS_SUCCESS:
            var current = state.followingPosts;
            if (current) {
                current.next_page = action.payload.next_page;
                current.previous_page = action.payload.previous_page;

                if (action.payload.previous_page == null) {
                    current.results = []
                }

                action.payload.results.forEach(post => {
                    var isExisting = false;
                    for (var i = 0; i < current.results.length; i++) {
                        if (current.results[i].pk == post.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        current.results.push(post);
                    }
                });
            }
            else {
                current = action.payload;
            }
            return { ...state, followingPosts: current, loadingFollowingPosts: false, error: null };
        case GET_FOLLOWING_POSTS_FAILED:
            return { ...state, error: action.payload, loadingFollowingPosts: false };

        case EDIT_POST:
            return { ...state, loading: true };
        case EDIT_POST_SUCCESS:
            const post = action.payload
            var pd = state.postdetail;
            if (pd.post.pk == post.pk) {
                pd.post = post
            }
            return { ...state, postdetail: pd, loading: false, error: null };
        case EDIT_POST_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };

        case DELETE_POST:
            return { ...state, loading: true };
        case DELETE_POST_SUCCESS:
            return { ...state, postdetail: null, loading: false, error: null };
        case DELETE_POST_FAILED:
            return { ...state, errorUploading: action.payload, loading: false };

        case REPORT:
            return { ...state, loading: true };
        case REPORT_SUCCESS:
            return { ...state, reportStatus: action.payload, loading: false, error: null };
        case REPORT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case FOLLOW_USER:
            return { ...state, loading: true };
        case FOLLOW_USER_SUCCESS:
            const author = action.payload.userPk
            const isFollowing = action.payload.isFollowing

            var currentFeeds = state.feeds;
            var digs = currentFeeds.results;
            for (let i = 0; i < digs.length; i++) {
                if (digs[i].author == author) {
                    digs[i].is_following_author = isFollowing;
                    break
                }
            }
            currentFeeds.results = digs;

            var currentFollowingPosts = state.followingPosts;
            var followingDigs = currentFollowingPosts.results;
            for (let i = 0; i < followingDigs.length; i++) {
                if (followingDigs[i].author == author) {
                    followingDigs[i].is_following_author = isFollowing;
                    break
                }
            }
            currentFollowingPosts.results = followingDigs;

            return { ...state, feeds: currentFeeds, followingPosts: currentFollowingPosts, loading: false };

        case FOLLOW_USER_FAILED:
            return { ...state, error: action.payload, loading: false };


        case REQUEST_FRIENDSHIP:
            return { ...state, loading: true };
        case REQUEST_FRIENDSHIP_SUCCESS:
            const friendshipRequest = action.payload;
            const pk = friendshipRequest.to_user;

            var currentFeeds = state.feeds;
            var digs = currentFeeds.results;
            for (let i = 0; i < digs.length; i++) {
                if (digs[i].author == pk) {
                    digs[i].author_connection_requested = true
                    digs[i].friend_pk = friendshipRequest.pk
                    digs[i].is_following_author = true
                    break
                }
            }
            currentFeeds.results = digs;

            var currentFollowingPosts = state.followingPosts;
            var followingDigs = currentFollowingPosts.results;
            for (let i = 0; i < followingDigs.length; i++) {
                if (followingDigs[i].author == pk) {
                    followingDigs[i].author_connection_requested = true
                    followingDigs[i].friend_pk = friendshipRequest.pk
                    followingDigs[i].is_following_author = true
                    break
                }
            }
            currentFollowingPosts.results = followingDigs;

            return { ...state, feeds: currentFeeds, followingPosts: currentFollowingPosts, loading: false };
        case REQUEST_FRIENDSHIP_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_FRIENDSHIP_REQUEST:
            return { ...state, loading: true };
        case DELETE_FRIENDSHIP_REQUEST_SUCCESS:
            const requestPk = action.payload;

            var currentFeeds = state.feeds;
            var digs = currentFeeds.results;
            for (let i = 0; i < digs.length; i++) {
                if (digs[i].friend_pk == requestPk) {
                    digs[i].author_connection_requested = false
                    digs[i].friend_pk = null
                    break
                }
            }
            currentFeeds.results = digs;

            var currentFollowingPosts = state.followingPosts;
            var followingDigs = currentFollowingPosts.results;
            for (let i = 0; i < followingDigs.length; i++) {
                if (followingDigs[i].friend_pk == requestPk) {
                    followingDigs[i].author_connection_requested = false
                    followingDigs[i].friend_pk = null
                    break
                }
            }
            currentFollowingPosts.results = followingDigs;

            return { ...state, feeds: currentFeeds, followingPosts: currentFollowingPosts, loading: false };

        case DELETE_FRIENDSHIP_REQUEST_FAILED:
            return { ...state, error: action.payload, loading: false };


        default:
            return { ...state };
    }
};

export default Post;
