// @flow
import { hasUnreadNotification } from '../../helpers/utils';
import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILED,

    MARK_AS_READ,
    MARK_AS_READ_SUCCESS,
    MARK_AS_READ_FAILED,

    GET_FRIENDSHIP_REQUESTS,
    GET_FRIENDSHIP_REQUESTS_SUCCESS,
    GET_FRIENDSHIP_REQUESTS_FAILED,

} from './constants';

const INIT_STATE = {
    friendshipRequests: [],
    notifications: null,
    hasNewNotification: false,
    loading: false,
    error: null
};


const Notification = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return { ...state, loading: true };
        case GET_NOTIFICATIONS_SUCCESS:
            var current = state.notifications;
            if (current) {
                if (current.results == null) {
                    current.results = action.payload.results;
                }
                else {
                    current.results = current.results.concat(action.payload.results);
                }

                current.next_page = action.payload.next_page;
                current.previous_page = action.payload.previous_page;
            }
            else {
                current = action.payload;
            }
            const hasNew = hasUnreadNotification(current?.results ?? []);

            return { ...state, notifications: current, hasNewNotification: hasNew, loading: false, error: null };
        case GET_NOTIFICATIONS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case MARK_AS_READ:
            return { ...state, loading: true };
        case MARK_AS_READ_SUCCESS:
            const hasNewNoti = hasUnreadNotification(action.payload?.results ?? []);
            return { ...state, notifications: action.payload, hasNewNotification: hasNewNoti, loading: false, error: null };
        case MARK_AS_READ_FAILED:
            return { ...state, error: action.payload, loading: false };


        case GET_FRIENDSHIP_REQUESTS:
            return { ...state, loading: true };
        case GET_FRIENDSHIP_REQUESTS_SUCCESS:
            return { ...state, friendshipRequests: action.payload ?? [], loading: false, error: null };
        case GET_FRIENDSHIP_REQUESTS_FAILED:
            return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Notification;
