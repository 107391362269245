// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    GET_WALLET, SUPPORT,
    CASHOUT,
    GET_TRANSACTION
} from './constants';
import { BASE_URL } from './../constants';

import {
    getWalletSuccess,
    getWalletFailed,

    supportSuccess,
    supportFailed,

    cashoutSuccess,
    cashoutFailed,
    getTransactionSuccess,
    getTransactionFailed

} from './actions';

/**
 * Get profile
 * @param {*} payload - none
 */

function* getWalletInternal({ payload: urlslug }) {
    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = urlslug ? (urlslug.startsWith(BASE_URL) ? urlslug : BASE_URL + urlslug.substring(1)) : BASE_URL + 'payment/wallet/'
        const response = yield call(fetchJSON, url, options);
        yield put(getWalletSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getWalletFailed(message));
    }
}

function* supportInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'POST',
        body: params,
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = BASE_URL + 'payment/donate/';
        const response = yield call(fetchJSON, url, options);
        yield put(supportSuccess(response));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(supportFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


function* cashoutInternal({ payload: { params, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'POST',
        body: params,
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = BASE_URL + 'cashout/';
        const response = yield call(fetchJSON, url, options);
        yield put(cashoutSuccess(response));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(cashoutFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

function* getTransactionInternal({ payload: {url_slug, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = (url_slug.startsWith(BASE_URL) ? url_slug : BASE_URL + url_slug.substring(1))
        const response = yield call(fetchJSON, url, options);
        yield put(getTransactionSuccess(response));
        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getTransactionFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchGetWallet() {
    yield takeEvery(GET_WALLET, getWalletInternal);
}

export function* watchSupport() {
    yield takeEvery(SUPPORT, supportInternal);
}

export function* watchCashout() {
    yield takeEvery(CASHOUT, cashoutInternal);
}

export function* watchGetTransaction() {
    yield takeEvery(GET_TRANSACTION, getTransactionInternal);
}

function* notificationSaga() {
    yield all([fork(watchGetWallet), fork(watchSupport), fork(watchCashout), fork(watchGetTransaction)]);
}

export default notificationSaga;
