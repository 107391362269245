// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    SEARCH_CATEGORY_CHANGE, SEARCH_KEY_CHANGE, SEARCH, PATCH_FRIENDSHIP_REQUEST, REQUEST_FRIENDSHIP, DELETE_FRIENDSHIP_REQUEST, CHARTS, SHARE,
} from './constants';
import { BASE_URL } from './../constants';

import {
    changeSearchCategorySuccess,
    changeSearchCategoryFailed,
    changeSearchKeyWordSuccess,
    changeSearchKeyWordFailed,
    searchSuccess,
    searchFailed,
    requestFriendshipSuccess,
    requestFriendshipFailed,
    deleteFriendshipRequestSuccess,
    deleteFriendshipRequestFailed,
    patchFriendshipRequestSuccess,
    patchFriendshipRequestFailed,
    getChartsSuccess,
    getChartsFailed,
    shareSuccess,
    shareFailed
} from './actions';
import { toast } from 'react-toastify';

/**
 * Change Search Category
 * @param {*} payload - url, type, keyword
 */
 function* changeSearchCategoryInternal({ payload: {category, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    yield put(changeSearchCategorySuccess(category));
    if (callbackOnSuccess) {
        yield call(callbackOnSuccess, category);
    }
}

/**
 * Change Search Keyword
 * @param {*} payload - url, type, keyword
 */
 function* changeSearchKeyWordInternal({ payload: {key_word, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    yield put(changeSearchKeyWordSuccess(key_word));
    if (callbackOnSuccess) {
        yield call(callbackOnSuccess, key_word);
    }
}

/**
 * Search
 * @param {*} payload - url, type, keyword
 */
function* searchInternal({ payload: {url, type, keyword, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: { "query": keyword, "category": type },
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, url ? url : (BASE_URL + 'search/'), options);
        yield put(searchSuccess(type, response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(searchFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Request friendship
 * @param {*} payload - none
 */
 function* requestFriendshipInternal({ payload: {params, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'POST',
        body: params,
        requestToken: true,
        headers: { 'Content-Type': 'application/json'}
    };

    try {
        const url = BASE_URL + 'user/friendship/request/';
        const response = yield call(fetchJSON, url, options);
        yield put(requestFriendshipSuccess(response));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(requestFriendshipFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Delete friendship request
 * @param {*} payload - none
 */
 function* deleteFriendshipRequestInternal({ payload: {params, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'DELETE',
        requestToken: true,
        headers: { 'Content-Type': 'application/json'}
    };

    try {
        const url = BASE_URL + 'user/friendship/request/' + params.request_pk + '/';
        const response = yield call(fetchJSON, url, options);
        yield put(deleteFriendshipRequestSuccess(params.request_pk));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(deleteFriendshipRequestFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Patch friendship request
 * @param {*} payload - none
 */
 function* patchFriendshipRequestInternal({ payload: {params, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'PATCH',
        body: {pk:params.pk, action:params.accepted ? "accept" : "cancel"},
        requestToken: true,
        headers: { 'Content-Type': 'application/json'}
    };

    try {
        const url = BASE_URL + 'user/friendship/request/';
        const response = yield call(fetchJSON, url, options);
        yield put(patchFriendshipRequestSuccess(params));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(patchFriendshipRequestFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Get charts
 * @param {*} payload - none
 */
 function* getChartsInternal({ payload: {url, timeframe, category, callbacks } }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: JSON.stringify({ "timeframe": timeframe, "category": category }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, url ? url : (BASE_URL + 'stats/charts/'), options);
        yield put(getChartsSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getChartsFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

/**
 * Share Post, Profile or Playlist
 * @param {*} payload - none
 */
 function* shareInternal({ payload: {object_pk, action, list_of_users, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;


    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        body: JSON.stringify({ "object_pk": object_pk, "action": action, "list_of_users":list_of_users }),
        method: 'POST',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'user/share/', options);
        yield put(shareSuccess(response));
        if (callbackOnSuccess) {
            yield call(callbackOnSuccess, response);
        }
        toast.info('Sent')
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(shareFailed(message));
        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchChangeSearchCategory() {
    yield takeEvery(SEARCH_CATEGORY_CHANGE, changeSearchCategoryInternal);
}

export function* watchChangeSearchKeyWord() {
    yield takeEvery(SEARCH_KEY_CHANGE, changeSearchKeyWordInternal);
}

export function* watchSearch() {
    yield takeEvery(SEARCH, searchInternal);
}

export function* watchRequestFriendship() {
    yield takeEvery(REQUEST_FRIENDSHIP, requestFriendshipInternal);
}

export function* watchDeleteFriendshipRequest() {
    yield takeEvery(DELETE_FRIENDSHIP_REQUEST, deleteFriendshipRequestInternal);
}

export function* watchPatchFriendshipRequest() {
    yield takeEvery(PATCH_FRIENDSHIP_REQUEST, patchFriendshipRequestInternal);
}

export function* watchGetCharts() {
    yield takeEvery(CHARTS, getChartsInternal);
}

export function* watchShare() {
    yield takeEvery(SHARE, shareInternal);
}


function* searchSaga() {
    yield all([
        fork(watchChangeSearchCategory),
        fork(watchChangeSearchKeyWord),
        fork(watchSearch),
        fork(watchRequestFriendship),
        fork(watchDeleteFriendshipRequest),
        fork(watchPatchFriendshipRequest),
        fork(watchGetCharts),
        fork(watchShare)
    ]);
}

export default searchSaga;
