/* AUTH */
export const PLAY_POST = 'PLAY_POST';
export const PLAY_POST_SUCCESS = 'PLAY_POST_SUCCESS';
export const PLAY_POST_FAILED = 'PLAY_POST_FAILED';

export const SET_PLAYSTATUS = 'SET_PLAYSTATUS';
export const SET_PLAYSTATUS_SUCCESS = 'SET_PLAYSTATUS_SUCCESS';
export const SET_PLAYSTATUS_FAILED = 'SET_PLAYSTATUS_FAILED';

export const SET_MINIPLAYERVISIBLE = 'SET_MINIPLAYERVISIBLE';
export const SET_MINIPLAYERVISIBLE_SUCCESS = 'SET_MINIPLAYERVISIBLE_SUCCESS';
export const SET_MINIPLAYERVISIBLE_FAILED = 'SET_MINIPLAYERVISIBLE_FAILED';

export const SET_MINIPLAYER_MODE = 'SET_MINIPLAYER_MODE';
export const SET_MINIPLAYER_MODE_SUCCESS = 'SET_MINIPLAYER_MODE_SUCCESS';
export const SET_MINIPLAYER_MODE_FAILED = 'SET_MINIPLAYER_MODE_FAILED';

export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_PLAYER_SUCCESS = 'UPDATE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_FAILED = 'UPDATE_PLAYER_FAILED';