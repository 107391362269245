// @flow
import {
    GET_WALLET,
    GET_WALLET_SUCCESS,
    GET_WALLET_FAILED,

    SUPPORT,
    SUPPORT_SUCCESS,
    SUPPORT_FAILED,

    CASHOUT,
    CASHOUT_SUCCESS,
    CASHOUT_FAILED,

    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_FAILED

} from './constants';

export const getWallet = (url_slug) => ({
    type: GET_WALLET,
    payload: url_slug,
});

export const getWalletSuccess = (wallet) => ({
    type: GET_WALLET_SUCCESS,
    payload: wallet,
});

export const getWalletFailed = (error) => ({
    type: GET_WALLET_FAILED,
    payload: error,
});

export const support = (params, callbacks) => ({
    type: SUPPORT,
    payload: {params, callbacks},
});

export const supportSuccess = (transaction) => ({
    type: SUPPORT_SUCCESS,
    payload: transaction,
});

export const supportFailed = (error) => ({
    type: SUPPORT_FAILED,
    payload: error,
});

//--Cashout--
export const cashout = (params, callbacks) => ({
    type: CASHOUT,
    payload: {params, callbacks},
});

export const cashoutSuccess = (cashout) => ({
    type: CASHOUT_SUCCESS,
    payload: cashout,
});

export const cashoutFailed = (error) => ({
    type: CASHOUT_FAILED,
    payload: error,
});
//------

//--Get Transaction--
export const getTransaction = (url_slug, callbacks) => ({
    type: GET_TRANSACTION,
    payload: {url_slug, callbacks},
});

export const getTransactionSuccess = (transaction) => ({
    type: GET_TRANSACTION_SUCCESS,
    payload: transaction,
});

export const getTransactionFailed = (error) => ({
    type: GET_TRANSACTION_FAILED,
    payload: error,
});