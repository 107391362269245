// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { 
    ANALYTIC_POST_LISTEN_DURATION
} from './constants';
import { BASE_URL} from './../constants';
import { getLoggedInUser } from '../../helpers/authUtils';

import {
    analyticsPostListenDurationSuccess,
    analyticsPostListenDurationFailed,

} from './actions';

function* analyticsPostListenDurationInternal({ payload: {params, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'POST',
        body: params,
        requestToken: true,
        headers: { 'Content-Type': 'application/json'}
    };

    try {
        const url = BASE_URL + 'stats/post/';
        const response = yield call(fetchJSON, url, options);
        yield put(analyticsPostListenDurationSuccess(response));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(analyticsPostListenDurationFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchanAlyticsPostListenDuration() {
    yield takeEvery(ANALYTIC_POST_LISTEN_DURATION, analyticsPostListenDurationInternal);
}

function* analyticSaga() {
    yield all([fork(
        watchanAlyticsPostListenDuration)
    ]);
}

export default analyticSaga;
