import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Offcanvas from "./Offcanvas";
import SearchKeywords from "./SearchKeywords";
import { isMobile } from 'react-device-detect';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activateOffcanvas: false,
      activateSearch: false
    };
    this.getMenuActiveStatus = this.getMenuActiveStatus.bind(this);
  }

  handleClickOffcanvas(e) {
    e.preventDefault();
    this.setState({ activateOffcanvas: !this.state.activateOffcanvas });
  }

  handleSearch(e) {
    e.preventDefault();
    this.setState({ activateSearch: !this.state.activateSearch });
  }

  getMenuActiveStatus(status) {
    this.setState({
      activateOffcanvas: status
    });
  }

  render() {
    const iconMargin = (this.props.notificationEnalble ?? false) ? ' ml-4' : ''

    return (
      <header className="box-center">
          <div className="full-width">
          <div className="header-wrapper align-horizontal">
            <div className="header-container space-y--20">
              <div className={"header-logo bg-color-test" + iconMargin}>
                {/* <Link to={process.env.PUBLIC_URL + "/wallet"}> */}
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/welcome/textUS.png"}
                  className="logo space-ml--20"
                  alt=""
                  onClick={() => { this.props.onLogo() }}
                />
                {/* </Link> */}
              </div>
            </div>

            {(this.props.notificationEnalble ?? false) &&
              <Link to={{ pathname: process.env.PUBLIC_URL + "/notification" }}>
                <div className="size-px-40 box-center pointer-style mr-2">
                  <img
                    className="size-px-15 object-fit-contain"
                    src={process.env.PUBLIC_URL + "/assets/img/buttons/subscribe.png"}
                    alt=""
                  />
                  {/* {this.props.hasNewNotification &&
                  <div className="notification_reddot"></div>
                } */}
                </div>
              </Link>
            }
          </div>
          </div>
        {/* search keywords */}
        {/* <SearchKeywords show={this.state.activateSearch} /> */}
        {/* offcanvas menu */}
        {/* <Offcanvas
          show={this.state.activateOffcanvas}
          activeStatus={this.getMenuActiveStatus}
        /> */}
      </header>
    );
  }
}

export default Header;
