// @flow
import {
    GET_WALLET,
    GET_WALLET_SUCCESS,
    GET_WALLET_FAILED,

    SUPPORT,
    SUPPORT_SUCCESS,
    SUPPORT_FAILED,

    CASHOUT,
    CASHOUT_SUCCESS,
    CASHOUT_FAILED,

    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_FAILED

} from './constants';

const INIT_STATE = {
    wallet: null,
    transaction: null,
    loading: false,
    error: null,
    gettingTransaction: false,
    transaction: null
};


const Support = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_WALLET:
            return { ...state, loading: true };
        case GET_WALLET_SUCCESS:
            var current = state.wallet;
            if (current) {
                current.account_balance = action.payload.account_balance;
                current.next_page = action.payload.next_page;
                current.previous_page = action.payload.previous_page;
                if (action.payload.previous_page == null) {
                    current.results = []
                }

                action.payload.results.forEach(transaction => {
                    var isExisting = false;
                    for (var i = 0; i < current.results.length; i++) {
                        if (current.results[i].pk == transaction.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        current.results.push(transaction);
                    }
                });
            }
            else {
                current = action.payload;
            }
            return { ...state, wallet: current, loading: false, error: null };
        case GET_WALLET_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUPPORT:
            return { ...state, loading: true };
        case SUPPORT_SUCCESS:
            var current = state.wallet;
            if (current?.account_balance && action.payload.amount) {
                current.account_balance -= parseFloat(action.payload.amount)
            }
            return { ...state, wallet: current, transaction: action.payload, loading: false, error: null };
        case SUPPORT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CASHOUT:
            return { ...state, loading: true };
        case CASHOUT_SUCCESS:
            return { ...state, cashout: action.payload, loading: false, error: null };
        case CASHOUT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_TRANSACTION:
            return { ...state, gettingTransaction: true };
        case GET_TRANSACTION_SUCCESS:
            return { ...state, transaction: action.payload, gettingTransaction: false, error: null };
        case GET_TRANSACTION_FAILED:
            return { ...state, error: action.payload, gettingTransaction: false };

        default:
            return { ...state };
    }
};

export default Support;
