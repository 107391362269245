import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { isMobile } from 'react-device-detect';


class SearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      searchKey: ''
    };
  }

  componentDidMount() {
    this.setState({ searchKey: this.props.key_word, isEditing: (this.props.key_word ?? '').length > 0 })
  }

  componentWillUnmount() {
  }

  render() {
    const className = isMobile ? " full-width" : " max-width-desktop";

    let content = (
      <div className="body-wrapper search_field-container">
        <div className={"search_field-container box-side" + className}>
          <div className="search_field">
            <input
              className="search_field_text"
              type="search"
              name="name"
              id="name"
              autocomplete="off"
              placeholder="Search"
              value={this.state.searchKey}
              onChange={(e) => {
                this.setState({ searchKey: e.target.value });
                this.props.onSearchKeyChanged(e.target.value)
              }
              }
              onFocus={(e) => {
                this.setState({ isEditing: true });
                this.props.onSearchStarted()
              }
              }
            />
          </div>

          {this.state.isEditing && <button className="cancel-button" onClick={() => {
            this.setState({ isEditing: false, searchKey: '' });
            this.props.onSearchEnded()
          }}>
            Cancel
          </button>}
        </div>
      </div>
    );

    return <Fragment>{content}</Fragment>;
  }
}

export default SearchField;