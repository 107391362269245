// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    PLAY_POST, SET_PLAYSTATUS, SET_MINIPLAYERVISIBLE, SET_MINIPLAYER_MODE, UPDATE_PLAYER,
} from './constants';

import {
    playPostSuccess,
    playPostFailed,
    setPlayStatusSuccess,
    setPlayStatusFailed,
    setMiniPlayerVisibleSuccess,
    setMiniPlayerVisibleFailed,
    setMiniPlayerModeSuccess,
    setMiniPlayerModeFailed,
    updatePlayerSuccess,
    updatePlayerFailed,

} from './actions';

import { getQueues } from '../actions';

/**
 * Get profile
 * @param {*} payload - none
 */
function* playPostInternal({ payload: { posts, index, played, showMiniPlayer } }) {
    try {
        yield put(playPostSuccess({ posts, index, played, showMiniPlayer }));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(playPostFailed(message));
    }
}

function* setPlayStatusInternal({ payload: status }) {
    try {
        yield put(setPlayStatusSuccess(status));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(setPlayStatusFailed(message));
    }
}


function* setMiniPlayerVisibleInternal({ payload: status }) {
    try {
        yield put(setMiniPlayerVisibleSuccess(status));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(setMiniPlayerVisibleFailed(message));
    }
}

function* setMiniPlayerModeInternal({ payload: mode }) {
    try {
        yield put(setMiniPlayerModeSuccess(mode));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(setMiniPlayerModeFailed(message));
    }
}

function* updatePlayerInternal({ payload: { duration, played, seek } }) {
    try {
        yield put(updatePlayerSuccess({ duration, played, seek }));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(updatePlayerFailed(message));
    }
}


export function* watchplayPost() {
    yield takeEvery(PLAY_POST, playPostInternal);
}

export function* watchsetPlayStatus() {
    yield takeEvery(SET_PLAYSTATUS, setPlayStatusInternal);
}

export function* watchsetMiniPlayerVisible() {
    yield takeEvery(SET_MINIPLAYERVISIBLE, setMiniPlayerVisibleInternal);
}

export function* watchsetMiniPlayerMode() {
    yield takeEvery(SET_MINIPLAYER_MODE, setMiniPlayerModeInternal);
}

export function* watchUpdatePlayer() {
    yield takeEvery(UPDATE_PLAYER, updatePlayerInternal);
}


function* playerSaga() {
    yield all([fork(watchplayPost),
    fork(watchsetPlayStatus),
    fork(watchsetMiniPlayerVisible),
    fork(watchsetMiniPlayerMode),
    fork(watchUpdatePlayer),
    ]);
}

export default playerSaga;
