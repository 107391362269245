// @flow
import { act } from 'react-dom/test-utils';
import { getLoggedInUserPk } from '../../helpers/authUtils';
import { isNewMessageInRoom, updateLobbyRoom } from '../../helpers/lobbyUtils';
import {
    GET_CHATROOMS,
    GET_CHATROOMS_SUCCESS,
    GET_CHATROOMS_FAILED,
    GET_MESSAGES,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAILED,

    DELETE_MESSAGE,
    DELETE_MESSAGE_SUCCESS,
    DELETE_MESSAGE_FAILED,

    CREATE_CHATROOM,
    CREATE_CHATROOM_SUCCESS,
    CREATE_CHATROOM_FAILED,

    GET_CHATROOM_DETAILS,
    GET_CHATROOM_DETAILS_SUCCESS,
    GET_CHATROOM_DETAILS_FAILED,

    UPDATE_CHATROOM_DETAILS,
    UPDATE_CHATROOM_DETAILS_SUCCESS,
    UPDATE_CHATROOM_DETAILS_FAILED,

    MUTE_CHATROOM,
    MUTE_CHATROOM_SUCCESS,
    MUTE_CHATROOM_FAILED,

    DELETE_CHATROOM,
    DELETE_CHATROOM_SUCCESS,
    DELETE_CHATROOM_FAILED,

    PATCH_CHATROOM_MEMBERS,
    PATCH_CHATROOM_MEMBERS_SUCCESS,
    PATCH_CHATROOM_MEMBERS_FAILED,

    GET_LINKPREVIEW,
    GET_LINKPREVIEW_SUCCESS,
    GET_LINKPREVIEW_FAILED,

    UPLOAD_ATTACHMENT,
    UPLOAD_ATTACHMENT_SUCCESS,
    UPLOAD_ATTACHMENT_FAILED,

    UPDATE_LATEST_MESSAGE_IN_ROOM,
    UPDATE_LATEST_MESSAGE_IN_ROOM_SUCCESS,
    UPDATE_LATEST_MESSAGE_IN_ROOM_FAILED,

    DID_RECEIVE_LOBBY_MESSAGE,
    DID_RECEIVE_LOBBY_MESSAGE_SUCCESS,
    DID_RECEIVE_LOBBY_MESSAGE_FAILED,

    GET_UPLOADED_FILES,
    GET_UPLOADED_FILES_SUCCESS,
    GET_UPLOADED_FILES_FAILED,

    GET_SHARED_POSTS,
    GET_SHARED_POSTS_SUCCESS,
    GET_SHARED_POSTS_FAILED,

    EDIT_CAPTION,
    EDIT_CAPTION_SUCCESS,
    EDIT_CAPTION_FAILED,

    DELETE_ATTACHMENT,
    DELETE_ATTACHMENT_SUCCESS,
    DELETE_ATTACHMENT_FAILED,
    ADD_MESSAGES,
    ADD_MESSAGES_SUCCESS,
    RECEIVED_MESSAGES,
    RECEIVED_MESSAGES_SUCCESS
} from './constants';

const INIT_STATE = {
    rooms: null,
    messages: null,
    allMessagesCount: 0,
    roomDetails: null,
    loading: false,
    error: null,
    linkPreview: {},
    attachments: [],
    uploadedFiles: null,
    sharedPosts: null,
    hasNewMessage: false,
};


const Chat = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CHATROOMS:
            return { ...state, loading: true };
        case GET_CHATROOMS_SUCCESS:
            var current = state.rooms;
            if (current) {
                if (action.payload.previous_page == null) {
                    current.rooms = null
                }

                if (current.rooms == null) {
                    current.rooms = action.payload.rooms;
                }
                else {
                    current.rooms = current.rooms.concat(action.payload.rooms);
                }

                current.next_page = action.payload.next_page;
                current.previous_page = action.payload.previous_page;
            }
            else {
                current = action.payload;
            }

            current.rooms = current.rooms.filter(
                room => room.peer_username == null || room.latest_message != null
            )

            // Update hasNewMessage state
            current.rooms = current.rooms.map(
                room => {
                    room.hasNewMessage = isNewMessageInRoom(room.guid, room?.latest_message);
                    return room;
                }
            )

            const hasNew = (current.rooms ?? []).findIndex((room) => { return (room.hasNewMessage ?? false) }) >= 0;

            return { ...state, rooms: current, hasNewMessage: hasNew, loading: false, error: null };
        case GET_CHATROOMS_FAILED:
            return { ...state, error: action.payload, loading: false };


        case PATCH_CHATROOM_MEMBERS:
            return { ...state, loading: true };
        case PATCH_CHATROOM_MEMBERS_SUCCESS:
            var current = state.rooms;
            let params = action.payload;
            if (current) {
                let action = params.action //"remove_user"
                let user_list = params.user_list ?? []
                let room_guid = params.room_guid ?? ''

                if (action === "remove_user" && user_list.includes(getLoggedInUserPk()) && room_guid.length > 0) {
                    let index = (current.rooms ?? []).findIndex((room) => { return room.guid == room_guid })
                    if (index >= 0) {
                        current.rooms.splice(index, 1)
                        return { ...state, rooms: current, loading: false, error: null };
                    }
                }
            }
            return { ...state, loading: false, error: null };
        case PATCH_CHATROOM_MEMBERS_FAILED:
            return { ...state, error: action.payload, loading: false };


        case UPDATE_CHATROOM_DETAILS:
            return { ...state, loading: true };
        case UPDATE_CHATROOM_DETAILS_SUCCESS:
            var current = state.rooms;
            let index = (current?.rooms ?? []).findIndex((room) => { return room.guid == action.payload.details.room.guid })
            if (index >= 0) {
                current.rooms[index] = action.payload.details.room
                return { ...state, rooms: current, loading: false, error: null };
            }
            return { ...state, loading: false, error: null };
        case UPDATE_CHATROOM_DETAILS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case MUTE_CHATROOM:
            return { ...state };
        case MUTE_CHATROOM_SUCCESS:
            var current = state.rooms;
            let idx = (current?.rooms ?? []).findIndex((room) => { return room.guid == action.payload.details.room.guid })
            if (idx >= 0) {
                current.rooms[idx] = action.payload.details.room
                return { ...state, rooms: current, loading: false, error: null };
            }
            return { ...state, loading: false, error: null };
        case MUTE_CHATROOM_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_CHATROOM:
            return { ...state, loading: true };
        case DELETE_CHATROOM_SUCCESS:
            var current = state.rooms;
            var rooms = current.room;
            if (current, rooms) {
                let index = rooms.findIndex(element => element.guid == action.payload);
                if (index >= 0) {
                    rooms.splice(index, 1);
                    current.rooms = rooms
                }
            }
            return { ...state, rooms: current, loading: false, error: null };
        case DELETE_CHATROOM_FAILED:
            return { ...state, error: action.payload, loading: false };


        case GET_MESSAGES:
            if (action.payload.url_slug === null) {
                return { ...state, messages: null, allMessagesCount: 0, loading: true };
            }
            else {
                return { ...state, loading: true };
            }
        case GET_MESSAGES_SUCCESS:
            //let newCount = state.actualMessageCount + action.payload.messages.length;

            var current = state.messages;
            if (current) {
                if (current.messages == null) {
                    current = action.payload;
                    let messages = [];
                    //for (let i = 0; i < action.payload.total_num - action.payload.messages.length; i++) {
                    //    messages.push({});
                    //}
                    messages = messages.concat(action.payload.messages);
                    current.messages = messages;
                }
                else {
                    let newMessage = action.payload.messages;
                    /*
                    let startIdx = state.messages.messages.length - state.actualMessageCount - 1 - newMessage.length + 1;
                    if (startIdx < 0) {
                        startIdx = 0;
                    }
                    let endIdx = state.messages.messages.length - state.actualMessageCount - 1;
                    for (let i = startIdx; i <= endIdx; i++) {
                        current.messages[i] = newMessage[i - startIdx];
                    }
                    */
                    current.messages = current.messages.concat(newMessage);
                }

                current.next_page = action.payload.next_page;
                current.previous_page = action.payload.previous_page;
            }
            else {
                current = action.payload;
                //let messages = [];
                //for (let i = 0; i < action.payload.total_num - action.payload.messages.length; i++) {
                //    messages.push({});
                //}
                //messages = action.payload.messages.reverse();
                //current.messages = messages;
            }


            return { ...state, messages: current, allMessagesCount: action.payload.total_num, loading: false, error: null };

        case GET_MESSAGES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ADD_MESSAGES:
            return { ...state, loading: true };
        case ADD_MESSAGES_SUCCESS:
            var current = state.messages;
            if (current && (current.messages != null)) {
                let messages = [];
                messages.push(action.payload);
                current.messages = messages.concat(current.messages);
            }
            return { ...state, messages: current, loading: false, error: null };

        case RECEIVED_MESSAGES:
            return { ...state, loading: true };
        case RECEIVED_MESSAGES_SUCCESS:
            var current = state.messages;
            if (current && (current.messages != null)) {
                let add = true
                if (current.messages.length > 0) {
                    let m = current.messages[0];
                    if (m.guid === null && (m.text === action.payload.text) && (m.from_user_pk == action.payload.from_user_pk)) {
                        current.messages[0] = action.payload;
                        add = false
                    }
                }
                if (add) {
                    let messages = [];
                    messages.push(action.payload);
                    current.messages = messages.concat(current.messages);
                }
            }
            return { ...state, messages: current, loading: false, error: null };


        case DELETE_MESSAGE:
            return { ...state, loading: true };
        case DELETE_MESSAGE_SUCCESS:
            var current = state.messages;

            if (current) {
                let index = current.messages.findIndex(element => element.message_id == action.payload);
                if (index >= 0) {
                    current.messages.splice(index, 1);
                }
            }
            return { ...state, messages: current, loading: false, error: null };
        case DELETE_MESSAGE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_LINKPREVIEW:
            return { ...state, loading: true };
        case GET_LINKPREVIEW_SUCCESS:

            return { ...state, linkPreview: action.payload, loading: false, error: null };
        case GET_LINKPREVIEW_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPLOAD_ATTACHMENT:
            return { ...state, loading: true };
        case UPLOAD_ATTACHMENT_SUCCESS:

            return { ...state, attachments: action.payload, loading: false, error: null };
        case UPLOAD_ATTACHMENT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UPDATE_LATEST_MESSAGE_IN_ROOM:
            return { ...state };

        case UPDATE_LATEST_MESSAGE_IN_ROOM_SUCCESS:
            var current = state.rooms;
            if (current) {
                // Update hasNewMessage state
                let index = current.rooms.findIndex(element => element.guid == action.payload.roomId);
                if (index >= 0) {
                    current.rooms[index].hasNewMessage = isNewMessageInRoom(current.rooms[index].guid, current.rooms[index].latest_message);
                }
            }
            const hasNewMes = (current.rooms ?? []).findIndex((room) => { return (room.hasNewMessage ?? false) }) >= 0;
            return { ...state, rooms: current, hasNewMessage: hasNewMes, loading: false, error: null };
        case UPDATE_LATEST_MESSAGE_IN_ROOM_FAILED:
            return { ...state, error: action.payload };

        case DID_RECEIVE_LOBBY_MESSAGE:
            return { ...state, loading: true };

        case DID_RECEIVE_LOBBY_MESSAGE_SUCCESS:
            var current = state.rooms;
            if (current) {
                var room = action.payload.room;
                let index = current.rooms.findIndex(element => element.guid == action.payload.room.guid);
                if (room && index >= 0) {
                    if (room.guid && room.latest_message?.from_user_pk && room.latest_message?.from_user_pk == getLoggedInUserPk()) {
                        updateLobbyRoom(room.guid, room.latest_message?.id)
                    }                    
                    room.hasNewMessage = isNewMessageInRoom(room.guid, room.latest_message);
                    current.rooms.splice(index, 1);
                    current.rooms.splice(0, 0, room);
                }
            }
            const hasNewMsg = (current.rooms ?? []).findIndex((room) => { return (room.hasNewMessage ?? false) }) >= 0;
            return { ...state, rooms: current, hasNewMessage: hasNewMsg, loading: false, error: null };
        case DID_RECEIVE_LOBBY_MESSAGE_FAILED:
            return { ...state, error: action.payload, loading: false };


        case GET_UPLOADED_FILES:
            return { ...state, loading: true };
        case GET_UPLOADED_FILES_SUCCESS:
            var current = state.uploadedFiles;
            if (current) {
                if (action.payload.files.previous_page == null) {
                    current.files = null
                }

                if (current.files == null) {
                    current.files = action.payload.files.files;
                }
                else {
                    current.files = current.files.concat(action.payload.files.files);
                }

                current.next_page = action.payload.files.next_page;
                current.previous_page = action.payload.files.previous_page;
            }
            else {
                current = action.payload.files;
            }



            return { ...state, uploadedFiles: current, loading: false, error: null };
        case GET_UPLOADED_FILES_FAILED:
            return { ...state, error: action.payload, loading: false };


        case GET_SHARED_POSTS:
            return { ...state, loading: true };
        case GET_SHARED_POSTS_SUCCESS:
            var current = state.sharedPosts;
            if (current) {
                if (action.payload.posts.previous_page == null) {
                    current.posts = null
                }

                if (current.posts == null) {
                    current.posts = action.payload.posts.posts;
                }
                else {
                    current.posts = current.posts.concat(action.payload.posts.posts);
                }

                current.next_page = action.payload.posts.next_page;
                current.previous_page = action.payload.posts.previous_page;
            }
            else {
                current = action.payload.posts;
            }

            return { ...state, sharedPosts: current, loading: false, error: null };
        case GET_SHARED_POSTS_FAILED:
            return { ...state, error: action.payload, loading: false };


        case EDIT_CAPTION:
            return { ...state, loading: true };
        case EDIT_CAPTION_SUCCESS:
            var current = state.messages;
            if (current) {
                let index = current.messages.findIndex(element => element.id == action.payload.message.id);
                if (index >= 0) {
                    current.messages[index] = action.payload.message;
                }
            }
            return { ...state, messages: current, loading: false, error: null };
        case EDIT_CAPTION_FAILED:
            return { ...state, error: action.payload, loading: false };


        case DELETE_ATTACHMENT:
            return { ...state, loading: true };
        case DELETE_ATTACHMENT_SUCCESS:
            var current = state.messages;
            if (current) {
                let index = current.messages.findIndex(element => element.message_id == action.payload.messageId);
                if (index >= 0) {
                    let attachmentIndex = current.messages[index].file_uploads.findIndex(element => element.id == action.payload.attachmentId);
                    if (attachmentIndex >= 0) {
                        current.messages[index].file_uploads.splice(attachmentIndex, 1);

                        if (current.messages[index].file_uploads.length == 0) {
                            return { ...state, loading: false, error: null };
                        }
                        else {
                            return { ...state, messages: current, loading: false, error: null };
                        }
                    }
                }
            }
        case DELETE_ATTACHMENT_FAILED:
            return { ...state, error: action.payload, loading: false };


        default:
            return { ...state };
    }
};

export default Chat;
