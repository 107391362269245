// @flow
import {
    GET_PROFILE,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAILED,
    GET_VAULT,
    GET_VAULT_SUCCESS,
    GET_VAULT_FAILED,
    GET_ARCHIVE,
    GET_ARCHIVE_SUCCESS,
    GET_ARCHIVE_FAILED,
    GET_PLAYLISTS,
    GET_PLAYLISTS_SUCCESS,
    GET_PLAYLISTS_FAILED,
    GET_OWNED_PLAYLISTS,
    GET_OWNED_PLAYLISTS_SUCCESS,
    GET_OWNED_PLAYLISTS_FAILED,
    GET_USER_REVIEWS,
    GET_USER_REVIEWS_SUCCESS,
    GET_USER_REVIEWS_FAILED,

    GET_FRIENDLIST,
    GET_FRIENDLIST_SUCCESS,
    GET_FRIENDLIST_FAILED,

    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILED,
    UPLOAD_MUSIC,
    UPLOAD_MUSIC_SUCCESS,
    UPLOAD_MUSIC_FAILED,

    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILED,

    MODIFY_USER_FRIENDSHIP,
    MODIFY_USER_FRIENDSHIP_SUCCESS,
    MODIFY_USER_FRIENDSHIP_FAILED,

    SUBSCRIBE,
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_FAILED,

    UNSUBSCRIBE,
    UNSUBSCRIBE_SUCCESS,
    UNSUBSCRIBE_FAILED,

} from './constants';

export const getProfile = (urlslug, callbacks) => ({
    type: GET_PROFILE,
    payload: {urlslug, callbacks},
});

export const getProfileSuccess = (user) => ({
    type: GET_PROFILE_SUCCESS,
    payload: user,
});

export const getProfileFailed = (error) => ({
    type: GET_PROFILE_FAILED,
    payload: error,
});


export const getVault = ({user_pk, urlslug}) => ({
    type: GET_VAULT,
    payload: {user_pk, urlslug},
});

export const getVaultSuccess = (vault) => ({
    type: GET_VAULT_SUCCESS,
    payload: vault,
});

export const getVaultFailed = (error) => ({
    type: GET_VAULT_FAILED,
    payload: error,
});

export const getArchive = (urlslug) => ({
    type: GET_ARCHIVE,
    payload: urlslug,
});

export const getArchiveSuccess = (vault) => ({
    type: GET_ARCHIVE_SUCCESS,
    payload: vault,
});

export const getArchiveFailed = (error) => ({
    type: GET_ARCHIVE_FAILED,
    payload: error,
});

export const getPlaylists = (urlslug, isFirstPage) => ({
    type: GET_PLAYLISTS,
    payload: urlslug, isFirstPage
});

export const getPlaylistsSuccess = (vault) => ({
    type: GET_PLAYLISTS_SUCCESS,
    payload: vault,
});

export const getPlaylistsFailed = (error) => ({
    type: GET_PLAYLISTS_FAILED,
    payload: error,
});

export const getOwnedPlaylists = (urlslug, isFirstPage) => ({
    type: GET_OWNED_PLAYLISTS,
    payload: urlslug, isFirstPage
});

export const getOwnedPlaylistsSuccess = (vault) => ({
    type: GET_OWNED_PLAYLISTS_SUCCESS,
    payload: vault,
});

export const getOwnedPlaylistsFailed = (error) => ({
    type: GET_OWNED_PLAYLISTS_FAILED,
    payload: error,
});

export const getUserReviews = (urlslug) => ({
    type: GET_USER_REVIEWS,
    payload: urlslug,
});

export const getUserReviewsSuccess = (reviews) => ({
    type: GET_USER_REVIEWS_SUCCESS,
    payload: reviews,
});

export const getUserReviewsFailed = (error) => ({
    type: GET_USER_REVIEWS_FAILED,
    payload: error,
});

export const getFriendlist = ({from_user, urlslug}) => ({
    type: GET_FRIENDLIST,
    payload: {from_user, urlslug}
});

export const getFriendlistSuccess = (friendlist) => ({
    type: GET_FRIENDLIST_SUCCESS,
    payload: friendlist,
});

export const getFriendlistFailed = (error) => ({
    type: GET_FRIENDLIST_FAILED,
    payload: error,
});

export const updateProfile = (profile,callbacks) => ({
    type: UPDATE_PROFILE,
    payload: {profile, callbacks},
});

export const updateProfileSuccess = (profile) => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: profile,
});

export const updateProfileFailed = (error) => ({
    type: UPDATE_PROFILE_FAILED,
    payload: error,
});


export const uploadMusic = (uploadInfo,callbacks) => ({
    type: UPLOAD_MUSIC,
    payload: {uploadInfo, callbacks},
});

export const uploadMusicSuccess = (uploadInfo) => ({
    type: UPLOAD_MUSIC_SUCCESS,
    payload: uploadInfo,
});

export const uploadMusicFailed = (error) => ({
    type: UPLOAD_MUSIC_FAILED,
    payload: error,
});

export const changePassword = (currentPassword, newPassword, callbacks) => ({
    type: CHANGE_PASSWORD,
    payload: {currentPassword, newPassword, callbacks},
});

export const changePasswordSuccess = (action) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: action,
});

export const changePasswordFailed = (error) => ({
    type: CHANGE_PASSWORD_FAILED,
    payload: error,
});

export const modifyUserFrienship = (params, callbacks) => ({
    type: MODIFY_USER_FRIENDSHIP,
    payload: {params, callbacks},
});

export const modifyUserFrienshipSuccess = (action) => ({
    type: MODIFY_USER_FRIENDSHIP_SUCCESS,
    payload: action,
});

export const modifyUserFrienshipFailed = (error) => ({
    type: MODIFY_USER_FRIENDSHIP_FAILED,
    payload: error,
});


export const subscribeToArtist = (artist, callbacks) => ({
    type: SUBSCRIBE,
    payload: {artist, callbacks},
});

export const subscribeToArtistSuccess = (artist) => ({
    type: SUBSCRIBE_SUCCESS,
    payload: {artist},
});

export const subscribeToArtistFailed = (error) => ({
    type: SUBSCRIBE_FAILED,
    payload: error,
});


export const unsubscribeToArtist = (artist, callbacks) => ({
    type: UNSUBSCRIBE,
    payload: {artist, callbacks},
});

export const unsubscribeToArtistSuccess = (artist) => ({
    type: UNSUBSCRIBE_SUCCESS,
    payload: {artist},
});

export const unsubscribeToArtistFailed = (error) => ({
    type: UNSUBSCRIBE_FAILED,
    payload: error,
});
