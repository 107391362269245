/* AUTH */
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const GET_VAULT = 'GET_VAULT';
export const GET_VAULT_SUCCESS = 'GET_VAULT_SUCCESS';
export const GET_VAULT_FAILED = 'GET_VAULT_FAILED';

export const GET_ARCHIVE = 'GET_ARCHIVE';
export const GET_ARCHIVE_SUCCESS = 'GET_ARCHIVE_SUCCESS';
export const GET_ARCHIVE_FAILED = 'GET_ARCHIVE_FAILED';

export const GET_PLAYLISTS = 'GET_PLAYLISTS';
export const GET_PLAYLISTS_SUCCESS = 'GET_PLAYLISTS_SUCCESS';
export const GET_PLAYLISTS_FAILED = 'GET_PLAYLISTS_FAILED';

export const GET_OWNED_PLAYLISTS = 'GET_OWNED_PLAYLISTS';
export const GET_OWNED_PLAYLISTS_SUCCESS = 'GET_OWNED_PLAYLISTS_SUCCESS';
export const GET_OWNED_PLAYLISTS_FAILED = 'GET_OWNED_PLAYLISTS_FAILED';

export const GET_USER_REVIEWS = 'GET_USER_REVIEWS';
export const GET_USER_REVIEWS_SUCCESS = 'GET_USER_REVIEWS_SUCCESS';
export const GET_USER_REVIEWS_FAILED = 'GET_USER_REVIEWS_FAILED';

export const GET_FRIENDLIST = 'GET_FRIENDLIST';
export const GET_FRIENDLIST_SUCCESS = 'GET_FRIENDLIST_SUCCESS';
export const GET_FRIENDLIST_FAILED = 'GET_FRIENDLIST_FAILED';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const UPLOAD_MUSIC = 'UPLOAD_MUSIC';
export const UPLOAD_MUSIC_SUCCESS = 'UPLOAD_MUSIC_SUCCESS';
export const UPLOAD_MUSIC_FAILED = 'UPLOAD_MUSIC_FAILED';
    
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const MODIFY_USER_FRIENDSHIP = 'MODIFY_USER_FRIENDSHIP';
export const MODIFY_USER_FRIENDSHIP_SUCCESS = 'MODIFY_USER_FRIENDSHIP_SUCCESS';
export const MODIFY_USER_FRIENDSHIP_FAILED = 'MODIFY_USER_FRIENDSHIP_FAILED';

export const SUBSCRIBE = 'SUBSCRIBE';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';

export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAILED = 'UNSUBSCRIBE_FAILED';

export const MUSIC_TAB_LIBRARY = 0;
export const MUSIC_TAB_PLAYLISTS = 1;
