import { BASE_URL } from './../redux/constants';
import { isTokenExpired, getLoggedInUser, saveTokenAndIssueTime } from './../helpers/authUtils'

const axios = require('axios').default;

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    /*
    return fetch(url, options)
        .then(response => {
            if (response.status === 204) return '';

            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
    */

        
    if (options.requestToken && isTokenExpired()) {
        const user = getLoggedInUser();
        var baseURL = BASE_URL;
        var path = "user/api-token-refresh/";
        const optionsRefresh = {
            body: {refresh: user.refresh},
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        const instance = axios.create({
            baseURL: baseURL,
            timeout: 10000,
            headers: optionsRefresh.headers
        });
        return instance.post(path, optionsRefresh.body).then((response) => {
            console.log(response)
            return response.data;
        })
            .then(json => {
                saveTokenAndIssueTime(json.access);
                return fetchJSON(url, options);
            })
            .catch(error => {
                throw error;
            });
    }
    else {
        //'Authorization': "Bearer " + user.access
        const user = getLoggedInUser();
        if (options.requestToken && user){
            options.headers.Authorization = "Bearer " + user.access;
        }

        var baseURL = url;
        var path = "/";
        if (url.startsWith(BASE_URL)) {
            baseURL = BASE_URL;
            path = url.substring(BASE_URL.length);
        }

        if (options.method == 'POST') {
            const instance = axios.create({
                baseURL: baseURL,
                timeout: 1000000,
                headers: options.headers
            });
            return instance.post(path, options.body).then((response) => {
                console.log(response)
                return response.data;
            })
                .then(json => {
                    return json;
                })
                .catch(error => {
                    throw error;
                });
        }
        else if (options.method == 'GET') {
            const instance = axios.create({
                baseURL: baseURL,
                timeout: 10000,
                headers: options.headers
            });
            return instance.get(path).then((response) => {
                console.log(response)
                return response.data;
            })
                .then(json => {
                    return json;
                })
                .catch(error => {
                    throw error;
                });
        }
        else if (options.method == 'PATCH') {
            const instance = axios.create({
                baseURL: baseURL,
                timeout: 1000000,
                headers: options.headers
            });
            return instance.patch(path, options.body).then((response) => {
                console.log(response)
                return response.data;
            })
                .then(json => {
                    return json;
                })
                .catch(error => {
                    throw error;
                });
        }
        else if (options.method == 'DELETE') {
            const instance = axios.create({
                baseURL: baseURL,
                timeout: 1000000,
                headers: options.headers
            });
            return instance.delete(path, { data: options.body }).then((response) => {
                console.log(response)
                return response.data;
            })
                .then(json => {
                    return json;
                })
                .catch(error => {
                    throw error;
                });
        }
    }
};

export { fetchJSON };
