// @flow
import {
    SEARCH_CATEGORY_CHANGE,
    SEARCH_CATEGORY_CHANGE_SUCCESS,
    SEARCH_CATEGORY_CHANGE_FAILED,

    SEARCH_KEY_CHANGE,
    SEARCH_KEY_CHANGE_SUCCESS,
    SEARCH_KEY_CHANGE_FAILED,

    SEARCH,
    SEARCH_SUCCESS,
    SEARCH_FAILED,

    REQUEST_FRIENDSHIP,
    REQUEST_FRIENDSHIP_SUCCESS,
    REQUEST_FRIENDSHIP_FAILED,

    DELETE_FRIENDSHIP_REQUEST,
    DELETE_FRIENDSHIP_REQUEST_SUCCESS,
    DELETE_FRIENDSHIP_REQUEST_FAILED,

    PATCH_FRIENDSHIP_REQUEST,
    PATCH_FRIENDSHIP_REQUEST_SUCCESS,
    PATCH_FRIENDSHIP_REQUEST_FAILED,

    CHARTS,
    CHARTS_SUCCESS,
    CHARTS_FAILED,

    SHARE,
    SHARE_SUCCESS,
    SHARE_FAILED

} from './constants';

import {
    ADD_TO_ARCHIVE,
    ADD_TO_ARCHIVE_SUCCESS,
    ADD_TO_ARCHIVE_FAILED,

} from '../post/constants';


const INIT_STATE = {
    category: 1,
    key_word: '',
    found_songs: null,
    found_accounts: null,
    found_playlists: null,
    found_tags: null,
    isSearchingSongs: false,
    isSearchingAccounts: false,
    isSearchingPlaylists: false,
    isSearchingTags: false,
    friendshipRequest: null,
    results: null,
    loading: false,
    error: null,
    trending: null,
    charts: null
};


const Search = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEARCH_CATEGORY_CHANGE:
            return { ...state, loading: true };
        case SEARCH_CATEGORY_CHANGE_SUCCESS:
                return { ...state, category: action.payload.category, loading: false, error: null };
        case SEARCH_CATEGORY_CHANGE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SEARCH_KEY_CHANGE:
            return { ...state, loading: true };
        case SEARCH_KEY_CHANGE_SUCCESS:
            if (action.payload.key_word == '') {
                return { ...state, key_word: action.payload.key_word, found_songs: null, found_accounts: null, found_playlists: null, found_tags: null, loading: false, error: null };
            }
            else {
                return { ...state, key_word: action.payload.key_word, loading: false, error: null };
            }
        case SEARCH_KEY_CHANGE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SEARCH:
            return { ...state, loading: true };
        case SEARCH_SUCCESS:
            let results = action.payload.result
            if ((results?.search_query ?? '') != state.key_word) {
                return { ...state };
            }

            if (action.payload.type == 1) {
                var current = state.found_songs;
                if (current) {
                    current.next_page = results.next_page;
                    current.previous_page = results.previous_page;

                    if (current.previous_page == null || current.results == null) {
                        current.results = results.results;
                    }
                    else {
                        current.results = current.results.concat(results.results);
                    }
                }
                else {
                    current = results;
                }
                return { ...state, found_songs: current, loading: false, error: null };
            }
            else if (action.payload.type == 2) {
                var current = state.found_accounts;
                if (current) {
                    current.next_page = results.next_page;
                    current.previous_page = results.previous_page;

                    if (current.previous_page == null || current.results == null) {
                        current.results = results.results;
                    }
                    else {
                        current.results = current.results.concat(results.results);
                    }
                }
                else {
                    current = results;
                }
                return { ...state, found_accounts: current, loading: false, error: null };
            }
            else if (action.payload.type == 3) {
                var current = state.found_playlists;
                if (current) {
                    current.next_page = results.next_page;
                    current.previous_page = results.previous_page;

                    if (current.previous_page == null || current.results == null) {
                        current.results = results.results;
                    }
                    else {
                        current.results = current.results.concat(results.results);
                    }
                }
                else {
                    current = results;
                }
                return { ...state, found_playlists: current, loading: false, error: null };
            }
            else if (action.payload.type == 4) {
                var current = state.found_tags;
                if (current) {
                    current.next_page = results.next_page;
                    current.previous_page = results.previous_page;

                    if (current.previous_page == null || current.results == null) {
                        current.results = results.results;
                    }
                    else {
                        current.results = current.results.concat(results.results);
                    }
                }
                else {
                    current = results;
                }
                return { ...state, found_tags: current, loading: false, error: null };
            }
        case SEARCH_FAILED:
            return { ...state, error: action.payload, loading: false };

        case REQUEST_FRIENDSHIP:
            return { ...state, loading: true };
        case REQUEST_FRIENDSHIP_SUCCESS:
            const friendshipRequest = action.payload;
            const pk = friendshipRequest.to_user;
            var current = state.found_accounts;
            var friends = current && current.results ? current.results : [];
            for (let i = 0; i < friends.length; i++) {
                if (friends[i].pk == pk) {
                    friends[i].user_connection_requested = true
                    friends[i].our_friendship_pk = friendshipRequest.pk
                    current.results = friends
                    break
                }
            }
            return { ...state, found_accounts: current, loading: false, error: null };
        case REQUEST_FRIENDSHIP_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_FRIENDSHIP_REQUEST:
            return { ...state, loading: true };
        case DELETE_FRIENDSHIP_REQUEST_SUCCESS:
            const requestPk = action.payload;
            var current = state.found_accounts;
            var friends = current && current.results ? current.results : [];
            for (let i = 0; i < friends.length; i++) {
                if (friends[i].our_friendship_pk == requestPk) {
                    friends[i].user_connection_requested = false
                    friends[i].our_friendship_pk = null
                    current.results = friends
                    break
                }
            }
            return { ...state, found_accounts: current, loading: false, error: null };

        case DELETE_FRIENDSHIP_REQUEST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case PATCH_FRIENDSHIP_REQUEST:
            return { ...state, loading: true };
        case PATCH_FRIENDSHIP_REQUEST_SUCCESS:
            const params = action.payload;
            var current = state.found_accounts;
            var friends = current && current.results ? current.results : [];
            for (let i = 0; i < friends.length; i++) {
                if (friends[i].our_friendship_pk == params.pk) {
                    if (params.accepted) {
                        friends[i].our_friendship_exists = true
                    }
                    else {
                        friends[i].user_connection_requested = false
                        friends[i].our_friendship_pk = null
                    }
                    current.results = friends
                    break
                }
            }
            return { ...state, found_accounts: current, loading: false, error: null };
        case PATCH_FRIENDSHIP_REQUEST_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CHARTS:
            return { ...state, loading: true };
        case CHARTS_SUCCESS:
            var current = state.charts;
            if (current) {
                current.next_page = action.payload.result.next_page;
                current.previous_page = action.payload.result.previous_page;

                if (action.payload.result.previous_page == null) {
                    current.results = []
                }

                action.payload.result.results.forEach(post => {
                    var isExisting = false;
                    for (var i = 0; i < current.results.length; i++) {
                        if (current.results[i].pk == post.pk) {
                            isExisting = true;
                            break;
                        }
                    }
                    if (!isExisting) {
                        current.results.push(post);
                    }
                });
            }
            else {
                current = action.payload.result;
            }

            return { ...state, charts: current, loading: false, error: null };
        case CHARTS_FAILED:
            return { ...state, error: action.payload, loading: false };


        case ADD_TO_ARCHIVE:
            return { ...state, loading: true };
        case ADD_TO_ARCHIVE_SUCCESS:
            var current = state.charts;
            const response = action.payload
            if (response.action == null) {
                return { ...state, charts: current, loading: false, error: null };
            }

            const isAdded = response.action ? (response.action == 'add') : false
            const pks = response.posts ? response.posts : [];

            if (current && current.results && current.results.length > 0) {
                for (let i = 0; i < pks.length; i++) {
                    const index = current.results.findIndex(post => post.pk === pks[i]);

                    if (index >= 0) {
                        current.results[index].archived_by_me = isAdded
                    }
                }
            }
            return { ...state, charts: current, feeds: current, loading: false, error: null };
        case ADD_TO_ARCHIVE_FAILED:
            return { ...state, error: action.payload, loading: false };


        case SHARE:
            return { ...state, loading: true };
        case SHARE_SUCCESS:
            return { ...state, loading: false, error: null };
        case SHARE_FAILED:
            return { ...state, error: action.payload, loading: false };


        default:
            return { ...state };
    }
};

export default Search;
