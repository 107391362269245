// @flow

import { getLoggedInUserPk } from "./authUtils";

const lobbyLatestMessagesKey = 'lobbyLatestMessages';
const updateLobbyRoom = (room, latestMessage) => {
    if ((room ?? '').length == 0) {
        return
    }
    var lobbyLatestMessages = getLobbyLatestMessages() ?? {}
    if (Array.isArray(lobbyLatestMessages)) {
        lobbyLatestMessages = {};
    }

    lobbyLatestMessages[room] = latestMessage;
    console.log(lobbyLatestMessages);
    const json = JSON.stringify(lobbyLatestMessages);
    localStorage.setItem(lobbyLatestMessagesKey, json);

    const savedLobby = localStorage.getItem(lobbyLatestMessagesKey);
    console.log(savedLobby);
};

const isNewMessageInRoom = (room, latestMessage) => {
    if (latestMessage == null) {
        return false
    }

    if (latestMessage.from_user_pk?.toString() === getLoggedInUserPk()) {
        return false
    }

    let id = latestMessage.id
    var lobbyLatestMessages = getLobbyLatestMessages() ?? {}
    if (lobbyLatestMessages[room] == null && id == null) {
        return false
    }
    return lobbyLatestMessages[room] != id;
};

const getLobbyLatestMessages = () => {
    var lobbyLatestMessages = localStorage.getItem(lobbyLatestMessagesKey);
    return lobbyLatestMessages ? (typeof lobbyLatestMessages == 'object' ? lobbyLatestMessages : JSON.parse(lobbyLatestMessages)) : null;
};


export { getLobbyLatestMessages, updateLobbyRoom, isNewMessageInRoom };
