import { Cookies } from 'react-cookie';
import { MUSIC_TAB_LIBRARY } from "../redux/profile/constants";

const getGenres = (genres_list) => {
  var arr = [];

  for (var key in genres_list) {
    arr.push(key);
  }

  return '#' + arr.join(' #');
}

const getSkills = (skills_list) => {
  return skills_list.join(' • ');
}

const getSkillsForEditing = (skills_list) => {
  return skills_list.join(', ');
}


const getTags = (tags) => {
  let t = '#' + tags.join(' #');
  return '#' + tags.join(' #');
}

const itemsSplitedFromString = (string) => {
  const itemsByComma = splitString(string, ',')
  const itemsByDot = splitArray(itemsByComma, '•')
  const itemsByTask = splitArray(itemsByDot, '#')
  return itemsByTask
}

const splitString = (string, split) => {
  var items = string.split(split);
  for (let i = 0; i < items.length; i++) {
    items[i] = items[i].trim()
  }
  items = items.filter(function (el) {
    return el != '';
  });
  return items
}

const splitArray = (array, split) => {
  var returnArray = []
  for (let i = 0; i < array.length; i++) {
    const splited = splitString(array[i], split)
    returnArray = returnArray.concat(splited)
  }
  return returnArray
}

const isDifferent = (current, next) => {
  if (current.length != next.length) {
    return true
  }

  var isNew = false
  for (let i = 0; i < next.length; i++) {
    if (!current.includes(next[i])) {
      isNew = true
      break
    }
  }
  return isNew
}

const isDifferentGenres = (current, next) => {
  if (current.length != next.length) {
    return true
  }

  var isDuplicated = false
  for (var key2 in next) {
    for (var key1 in current) {
      if (key1 == key2) {
        isDuplicated = true
        if (next[key2] == current[key1]) {
          break
        }
        else {
          return true
        }
      }
    }
  }
  return !isDuplicated
}

const getFileExtensions = (filepath) => {
  return (filepath ?? '').toLowerCase().split("?")[0].split("#")[0].split('.').pop();
}

const isVideo = (filepath) => {
  let possible = ["mp4", "mov", "mpeg", "avi", "m3u8"];
  return possible.includes(getFileExtensions(filepath))
}

const isAudio = (filepath) => {
  let possible = ["mp3", "m4a", "wav"];
  return possible.includes(getFileExtensions(filepath))
}

const isImage = (filepath) => {
  let possible = ["gif", "jpg", "jpeg", "png", "bmp"];
  return possible.includes(getFileExtensions(filepath))
}

const lastPathComponent = (path) => {
  var parts = path.split("?")[0].split('/');
  var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash
  return lastSegment;
}

const hasUnreadNotification = (notifications) => {
  return (notifications ?? []).findIndex((notification) => { return (notification.unread ?? true) }) >= 0;
}

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const validatePhoneNumber = (phone_number) => {
  const re = /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?/
  return re.test(phone_number);
}


const musicTabKey = 'musicTabKey';
const selectMusicTab = tab => {
  if (tab) {
    localStorage.setItem(musicTabKey, tab);
  }
  else {
    localStorage.removeItem(musicTabKey)
  }
};
const getSelectedMusicTab = () => {
  const tab = localStorage.getItem(musicTabKey);
  return parseInt(tab ?? MUSIC_TAB_LIBRARY, 10);
};

const pageOfPath = (path) => {
  if (path.includes('?page=')) {
    const page = parseInt(path.split('?page=').pop(), 10);
    return page == NaN ? 0 : page;
  }
  else {
    return 0
  }
}

export {
  getGenres, getSkills, getSkillsForEditing, getTags, itemsSplitedFromString, splitString, splitArray,
  isDifferent, isDifferentGenres, getFileExtensions, isVideo, isAudio, isImage, lastPathComponent, hasUnreadNotification,
  validateEmail, validatePhoneNumber,
  selectMusicTab, getSelectedMusicTab,
  pageOfPath
};