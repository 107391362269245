// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { 
    ADD_TO_QUEUE, REMOVE_FROM_QUEUE, MOVE_QUEUE, GET_QUEUES
} from './constants';
import { BASE_URL} from './../constants';
import { getLoggedInUser } from '../../helpers/authUtils';

import {
    addToQueueSuccess,
    addToQueueFailed,
    removeFromQueueSuccess,
    removeFromQueueFailed,
    moveQueueSuccess,
    moveQueueFailed,
    getQueuesSuccess,
    getQueuesFailed
} from './actions';

/**
 * Add to Queue
 * @param {*} payload - none
 */
 function* addToQueueInternal({ payload: {postPks, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'POST',
        body: {action:'add', posts_to_action:postPks},
        requestToken: true,
        headers: { 'Content-Type': 'application/json'}
    };

    try {
        const url = BASE_URL + 'music/queue/';
        const response = yield call(fetchJSON, url, options);
        yield put(addToQueueSuccess(response));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(addToQueueFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Remove from Queue
 * @param {*} payload - none
 */
 function* removeFromQueueInternal({ payload: {postPks, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    var dictionary = {action:'remove', posts_to_action:postPks}
    if (postPks.length == 0) {
        dictionary = {action:'clear'}
    }

    const options = {
        method: 'POST',
        body: dictionary,
        requestToken: true,
        headers: { 'Content-Type': 'application/json'}
    };

    try {
        const url = BASE_URL + 'music/queue/';
        const response = yield call(fetchJSON, url, options);
        yield put(removeFromQueueSuccess(postPks));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(removeFromQueueFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Move Queue
 * @param {*} payload - none
 */
 function* moveQueueInternal({ payload: {params, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    var dictionary = {queue_song_pk:params.queue_song_pk}
    if (params.to_position != null) {
        dictionary.action = 'move'
        dictionary.to_position = params.to_position
    }
    else {
        dictionary.action = 'move_to_top'
    }

    const options = {
        method: 'POST',
        body: dictionary,
        requestToken: true,
        headers: { 'Content-Type': 'application/json'}
    };

    try {
        const url = BASE_URL + 'music/queue/';
        const response = yield call(fetchJSON, url, options);
        yield put(moveQueueSuccess(params));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(moveQueueFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}


/**
 * Get Queues
 * @param {*} payload - none
 */
 function* getQueuesInternal({ payload: {url_slug, updateQueueing, callbacks} }) {
    const {
        callbackOnBegin,
        callbackOnFailure,
        callbackOnFinish,
        callbackOnSuccess
    } = callbacks;

    if (callbackOnBegin) {
        yield call(callbackOnBegin);
    }

    const options = {
        method: 'GET',
        requestToken: true,
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const url = url_slug == null ? (BASE_URL + 'music/queue/list/') : url_slug;
        const response = yield call(fetchJSON, url, options);
        yield put(getQueuesSuccess(response, updateQueueing));

        if (callbackOnSuccess) { 
            yield call(callbackOnSuccess, response); 
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getQueuesFailed(message));

        if (callbackOnFailure) {
            yield call(callbackOnFailure, error);
        }
    } finally {
        if (callbackOnFinish) {
            yield call(callbackOnFinish);
        }
    }
}

export function* watchAddToQueue() {
    yield takeEvery(ADD_TO_QUEUE, addToQueueInternal);
}

export function* watchRemoveFromQueue() {
    yield takeEvery(REMOVE_FROM_QUEUE, removeFromQueueInternal);
}

export function* watchMoveQueue() {
    yield takeEvery(MOVE_QUEUE, moveQueueInternal);
}

export function* watchGetQueues() {
    yield takeEvery(GET_QUEUES, getQueuesInternal);
}

function* queueSaga() {
    yield all([
        fork(watchAddToQueue),
        fork(watchRemoveFromQueue),
        fork(watchMoveQueue),
        fork(watchGetQueues)
    ]);
}

export default queueSaga;
