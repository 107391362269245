// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }

    try {
        const decoded = jwtDecode(user.access);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    } catch (error) {
        return false;
    }

};

const isUserAuthenticatedAndVerified = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }

    try {
        const decoded = jwtDecode(user.accessToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            if (user.isEmailVerified) {
                return true;
            }
            else {
                return false;
            }
        }
    } catch (error) {
        return false;
    }

};

/**
 * Sets the session
 * @param {*} user
 */

const userKey = 'userKey';
const tokenIssueTimeKey = 'tokenIssueTimeKey';
const pkKey = 'pkKey';
const rememberThisDeviceKey = 'rememberThisDeviceKey';
const loginCredentialsKey = 'loginCredentialsKey';

const setLoggedInUser = user => {
    // let cookies = new Cookies();
    if (user) {
        // cookies.set('user', JSON.stringify(user), { path: '/' });
        // let time = Date.now();
        // if (time) cookies.set('tokenIssueTime', JSON.stringify(time), { path: '/' });
        localStorage.setItem(userKey, JSON.stringify(user));
        let time = Date.now();
        if (time) localStorage.setItem(tokenIssueTimeKey, JSON.stringify(time));
    }
    else {
        // cookies.remove('user', { path: '/' });
        // cookies.remove('tokenIssueTime', { path: '/' });
        localStorage.removeItem(userKey)
        localStorage.removeItem(tokenIssueTimeKey)
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    // const cookies = new Cookies();
    // const user = cookies.get('user');
    // return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;

    const user = localStorage.getItem(userKey);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const setLoggedInUserPk = user => {
    // let cookies = new Cookies();
    // if (user) {
    //     cookies.set('pk', user, { path: '/' });
    // }
    // else {
    //     cookies.remove('pk', { path: '/' });
    // }

    if (user) {
        localStorage.setItem(pkKey, user);
    }
    else {
        localStorage.removeItem(pkKey)
    }
};
const getLoggedInUserPk = () => {
    // const cookies = new Cookies();
    // const user = cookies.get('pk');
    // return user;

    return localStorage.getItem(pkKey);
};

const rememberThisDevice = (reload) => {
    // let cookies = new Cookies();
    // if (reload) cookies.set('rememberThisDevice', JSON.stringify(reload), { path: '/' });
    // else cookies.remove('rememberThisDevice', { path: '/' });

    if (reload) localStorage.setItem(rememberThisDeviceKey, JSON.stringify(reload));
    else localStorage.removeItem(rememberThisDeviceKey);
}

const isRememberThisDevice = () => {
    // const cookies = new Cookies();
    // const reload = cookies.get('rememberThisDevice');
    // return reload ? true : false;

    const reload = localStorage.getItem(rememberThisDeviceKey);
    return reload ? true : false;
}

const saveLoginCredentials = (email, password) => {
    const cred = { email, password };
    // let cookies = new Cookies();
    // if (cred) cookies.set('loginCredentials', JSON.stringify(cred), { path: '/' });
    // else cookies.remove('loginCredentials', { path: '/' });

    if (cred) localStorage.setItem(loginCredentialsKey, JSON.stringify(cred));
    else localStorage.removeItem(loginCredentialsKey);
}

const getLoginCredentials = () => {
    // const cookies = new Cookies();
    // const cred = cookies.get('loginCredentials');
    // return cred

    const cred = localStorage.getItem(loginCredentialsKey);
    return cred
}

const saveTokenAndIssueTime = (access) => {
    const user = getLoggedInUser();
    user.access = access;
    setLoggedInUser(user);
}

const isTokenExpired = () => {
    // const cookies = new Cookies();
    // const time = cookies.get('tokenIssueTime');
    // if (time) {
    //     const currentTime = Date.now();
    //     const diffs = currentTime - time;
    //     const expired = (diffs > 295000); // 295 secs;
    //     return expired;
    // }
    // return false;

    const time = localStorage.getItem(tokenIssueTimeKey);
    if (time) {
        const currentTime = Date.now();
        const diffs = currentTime - time;
        const expired = (diffs > 295000); // 295 secs;
        return expired;
    }
    return false;
}


export {
    isUserAuthenticated, isUserAuthenticatedAndVerified, getLoggedInUser,
    rememberThisDevice, isRememberThisDevice, saveLoginCredentials, getLoginCredentials,
    setLoggedInUser, isTokenExpired, saveTokenAndIssueTime, setLoggedInUserPk, getLoggedInUserPk
};
