export const ADD_TO_QUEUE = 'ADD_TO_QUEUE';
export const ADD_TO_QUEUE_SUCCESS = 'ADD_TO_QUEUE_SUCCESS';
export const ADD_TO_QUEUE_FAILED = 'ADD_TO_QUEUE_FAILED';

export const REMOVE_FROM_QUEUE = 'REMOVE_FROM_QUEUE';
export const REMOVE_FROM_QUEUE_SUCCESS = 'REMOVE_FROM_QUEUE_SUCCESS';
export const REMOVE_FROM_QUEUE_FAILED = 'REMOVE_FROM_QUEUE_FAILED';

export const MOVE_QUEUE = 'MOVE_QUEUE';
export const MOVE_QUEUE_SUCCESS = 'MOVE_QUEUE_SUCCESS';
export const MOVE_QUEUE_FAILED = 'MOVE_QUEUE_FAILED';

export const GET_QUEUES = 'GET_QUEUES';
export const GET_QUEUES_SUCCESS = 'GET_QUEUES_SUCCESS';
export const GET_QUEUES_FAILED = 'GET_QUEUES_FAILED';