// @flow
import {
    ANALYTIC_POST_LISTEN_DURATION,
    ANALYTIC_POST_LISTEN_DURATION_SUCCESS,
    ANALYTIC_POST_LISTEN_DURATION_FAILED,

} from './constants';


export const analyticsPostListenDuration = (params, callbacks) => ({
    type: ANALYTIC_POST_LISTEN_DURATION,
    payload: {params, callbacks},
});

export const analyticsPostListenDurationSuccess = (action) => ({
    type: ANALYTIC_POST_LISTEN_DURATION_SUCCESS,
    payload: action,
});

export const analyticsPostListenDurationFailed = (error) => ({
    type: ANALYTIC_POST_LISTEN_DURATION_FAILED,
    payload: error,
});