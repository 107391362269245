/* AUTH */

export const GET_WALLET = 'GET_WALLET';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const GET_WALLET_FAILED = 'GET_WALLET_FAILED';

export const SUPPORT = 'SUPPORT';
export const SUPPORT_SUCCESS = 'SUPPORT_SUCCESS';
export const SUPPORT_FAILED = 'SUPPORT_FAILED';

export const CASHOUT = 'CASHOUT';
export const CASHOUT_SUCCESS = 'CASHOUT_SUCCESS';
export const CASHOUT_FAILED = 'CASHOUT_FAILED';

export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_FAILED = 'GET_TRANSACTION_FAILED';
