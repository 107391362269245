import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppRoute from "./helpers/AppRoute";

import { DefaultLayout } from "./layouts";
import "./assets/scss/style.scss";
import { Preloader } from "./components";
import ScrollToTop from "./helpers/scroll-top";
import { Upload } from "react-feather";
import history from './history';
import { useDispatch } from "react-redux";
import { getChatRooms, getNotifications } from "./redux/actions";
import 'react-confirm-alert/src/react-confirm-alert.css';

const Welcome = lazy(() => import("./pages/Welcome"));
const Register = lazy(() => import("./pages/Register"));
const SignupVerification = lazy(() => import("./pages/SignupVerification"));
const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));
const Shop = lazy(() => import("./pages/Shop"));
const Product = lazy(() => import("./pages/Product"));
const Chat = lazy(() => import("./pages/Chat"));
const Conversation = lazy(() => import("./pages/Chat/Conversation"));
const Cart = lazy(() => import("./pages/Cart"));
const Wishlist = lazy(() => import("./pages/Wishlist"));
const Checkout = lazy(() => import("./pages/Checkout"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Search = lazy(() => import("./pages/Search"));
const Profile = lazy(() => import("./pages/Profile"));
const ProfileViewer = lazy(() => import("./pages/Profile/ProfileViewer"));
const PostDetail = lazy(() => import("./pages/PostDetail"));
const PlaylistDetail = lazy(() => import("./pages/PlaylistDetail"));
const EditProfile = lazy(() => import("./pages/EditProfile"));
const Music = lazy(() => import("./pages/Music"));
const Notification = lazy(() => import("./pages/Notification"));
const Contact = lazy(() => import("./pages/Contact"));
const Order = lazy(() => import("./pages/Order"));
const UploadMusic = lazy(() => import("./pages/UploadMusic"));
const Wallet = lazy(() => import("./pages/Wallet"));
const Friendlist = lazy(() => import("./pages/Friendlist"));
const EditPost = lazy(() => import("./pages/EditPost"));
const DigsByTag = lazy(() => import("./pages/DigsByTag"));
const FriendshipRequests = lazy(() => import("./pages/FriendshipRequests"));
const ViewSharedContent = lazy(() => import("./pages/ViewSharedContent"));
const RegisterOptions = lazy(() => import("./pages/RegisterOptions"));

require('dotenv').config()

function App() {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotifications()); 
    dispatch(getChatRooms(null,() => {}))
  },[]);

  return (
    <Router history={history}>
      <ScrollToTop>
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              exact
              component={Welcome}
            />
            <Route
              path={process.env.PUBLIC_URL + "/welcome"}
              component={Welcome}
            />
            <Route
              path={process.env.PUBLIC_URL + "/register/:user_type"}
              component={Register}
              exact={true}
            />
            <Route
              path={process.env.PUBLIC_URL + "/verify"}
              component={SignupVerification}
            />
            <Route path={process.env.PUBLIC_URL + "/login"} component={Login} />
            <AppRoute
              path={process.env.PUBLIC_URL + "/home"}
              component={Home}
              layout={DefaultLayout}
            />
            {/* <AppRoute
              path={process.env.PUBLIC_URL + "/shop"}
              component={Shop}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/product/:id"}
              component={Product}
              layout={DefaultLayout}
            /> */}
            <AppRoute
              path={process.env.PUBLIC_URL + "/chat"}
              component={Chat}
              layout={DefaultLayout}
            />
            {/* <AppRoute
              path={process.env.PUBLIC_URL + "/cart"}
              component={Cart}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/wishlist"}
              component={Wishlist}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/checkout"}
              component={Checkout}
              layout={DefaultLayout}
            /> */}
            <AppRoute
              path={process.env.PUBLIC_URL + "/not-found"}
              component={NotFound}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/search"}
              component={Search}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/profile"}
              component={Profile}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/view-profile"}
              component={ProfileViewer}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/postdetail/:id"}
              component={PostDetail}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/playlistdetail"}
              component={PlaylistDetail}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/music"}
              component={Music}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/edit-profile"}
              component={EditProfile}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/notification"}
              component={Notification}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/contact"}
              component={Contact}
              layout={DefaultLayout}
            />
            {/* <AppRoute
              path={process.env.PUBLIC_URL + "/order"}
              component={Order}
              layout={DefaultLayout}
            /> */}
            <AppRoute
              path={process.env.PUBLIC_URL + "/upload-music"}
              component={UploadMusic}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/wallet"}
              component={Wallet}
              layout={DefaultLayout}
            />

            <AppRoute
              path={process.env.PUBLIC_URL + "/friendlist"}
              component={Friendlist}
              layout={DefaultLayout}
            />

            <AppRoute
              path={process.env.PUBLIC_URL + "/edit-post"}
              component={EditPost}
              layout={DefaultLayout}
            />

            <AppRoute
              path={process.env.PUBLIC_URL + "/digs-by-tag"}
              component={DigsByTag}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/view-conversation"}
              component={Conversation}
              layout={DefaultLayout}
            />

            <AppRoute
              path={process.env.PUBLIC_URL + "/friendship"}
              component={FriendshipRequests}
              layout={DefaultLayout}
            />

            <AppRoute
              path={process.env.PUBLIC_URL + "/view"}
              component={ViewSharedContent}
              layout={DefaultLayout}
            />

            <AppRoute
              path={process.env.PUBLIC_URL + "/register-options"}
              component={RegisterOptions}
              layout={DefaultLayout}
            />

            <AppRoute exact component={NotFound} layout={DefaultLayout} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
