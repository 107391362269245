/* AUTH */
export const GET_PLAYLISTDETAIL = 'GET_PLAYLISTDETAIL';
export const GET_PLAYLISTDETAIL_SUCCESS = 'GET_PLAYLISTDETAIL_SUCCESS';
export const GET_PLAYLISTDETAIL_FAILED = 'GET_PLAYLISTDETAIL_FAILED';
export const CREATE_PLAYLIST = 'CREATE_PLAYLIST';
export const CREATE_PLAYLIST_SUCCESS = 'CREATE_PLAYLIST_SUCCESS';
export const CREATE_PLAYLIST_FAILED = 'CREATE_PLAYLIST_FAILED';
export const EDIT_PLAYLIST_DETAILS = 'EDIT_PLAYLIST_DETAILS';
export const EDIT_PLAYLIST_DETAILS_SUCCESS = 'EDIT_PLAYLIST_DETAILS_SUCCESS';
export const EDIT_PLAYLIST_DETAILS_FAILED = 'EDIT_PLAYLIST_DETAILS_FAILED';
export const DELETE_PLAYLIST = 'DELETE_PLAYLIST';
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_FAILED = 'DELETE_PLAYLIST_FAILED';
export const HEART_PLAYLIST = 'HEART_PLAYLIST';
export const HEART_PLAYLIST_SUCCESS = 'HEART_PLAYLIST_SUCCESS';
export const HEART_PLAYLIST_FAILED = 'HEART_PLAYLIST_FAILED';
export const MOVE_PLAYLIST_SONG = 'MOVE_PLAYLIST_SONG';
export const MOVE_PLAYLIST_SONG_SUCCESS = 'MOVE_PLAYLIST_SONG_SUCCESS';
export const MOVE_PLAYLIST_SONG_FAILED = 'MOVE_PLAYLIST_SONG_FAILED';

/*
export const UP_VOTE = 'UP_VOTE';
export const UP_VOTE_SUCCESS = 'UP_VOTE_SUCCESS';
export const UP_VOTE_FAILED = 'UP_VOTE_FAILED';
export const GET_REVIEWDETAILS = 'GET_REVIEWDETAILS';
export const GET_REVIEWDETAILS_SUCCESS = 'GET_REVIEWDETAILS_SUCCESS';
export const GET_REVIEWDETAILS_FAILED = 'GET_REVIEWDETAILS_FAILED';
export const HIDE_REPLIES = 'HIDE_REPLIES';
export const HIDE_REPLIES_SUCCESS = 'HIDE_REPLIES_SUCCESS';
export const HIDE_REPLIES_FAILED = 'HIDE_REPLIES_FAILED';
export const UP_VOTE_REPLY = 'UP_VOTE_REPLY';
export const UP_VOTE_REPLY_SUCCESS = 'UP_VOTE_REPLY_SUCCESS';
export const UP_VOTE_REPLY_FAILED = 'UP_VOTE_REPLY_FAILED';
*/