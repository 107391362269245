/* AUTH */
export const GET_CHATROOMS = 'GET_CHATROOMS';
export const GET_CHATROOMS_SUCCESS = 'GET_CHATROOMS_SUCCESS';
export const GET_CHATROOMS_FAILED = 'GET_CHATROOMS_FAILED';

export const CREATE_CHATROOM = 'CREATE_CHATROOM';
export const CREATE_CHATROOM_SUCCESS = 'CREATE_CHATROOM_SUCCESS';
export const CREATE_CHATROOM_FAILED = 'CREATE_CHATROOM_FAILED';

export const GET_CHATROOM_DETAILS = 'GET_CHATROOM_DETAILS';
export const GET_CHATROOM_DETAILS_SUCCESS = 'GET_CHATROOM_DETAILS_SUCCESS';
export const GET_CHATROOM_DETAILS_FAILED = 'GET_CHATROOM_DETAILS_FAILED';

export const UPDATE_CHATROOM_DETAILS = 'UPDATE_CHATROOM_DETAILS';
export const UPDATE_CHATROOM_DETAILS_SUCCESS = 'UPDATE_CHATROOM_DETAILS_SUCCESS';
export const UPDATE_CHATROOM_DETAILS_FAILED = 'UPDATE_CHATROOM_DETAILS_FAILED';

export const MUTE_CHATROOM = 'MUTE_CHATROOM';
export const MUTE_CHATROOM_SUCCESS = 'MUTE_CHATROOM_SUCCESS';
export const MUTE_CHATROOM_FAILED = 'MUTE_CHATROOM_FAILED';

export const DELETE_CHATROOM = 'DELETE_CHATROOM';
export const DELETE_CHATROOM_SUCCESS = 'DELETE_CHATROOM_SUCCESS';
export const DELETE_CHATROOM_FAILED = 'DELETE_CHATROOM_FAILED';

export const PATCH_CHATROOM_MEMBERS = 'PATCH_CHATROOM_MEMBERS';
export const PATCH_CHATROOM_MEMBERS_SUCCESS = 'PATCH_CHATROOM_MEMBERS_SUCCESS';
export const PATCH_CHATROOM_MEMBERS_FAILED = 'PATCH_CHATROOM_MEMBERS_FAILED';


export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';

export const ADD_MESSAGES = 'ADD_MESSAGES';
export const ADD_MESSAGES_SUCCESS = 'ADD_MESSAGES_SUCCESS';
export const ADD_MESSAGES_FAILED = 'ADD_MESSAGES_FAILED';

export const RECEIVED_MESSAGES = 'RECEIVED_MESSAGES';
export const RECEIVED_MESSAGES_SUCCESS = 'RECEIVED_MESSAGES_SUCCESS';
export const RECEIVED_MESSAGES_FAILED = 'RECEIVED_MESSAGES_FAILED';

export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILED = 'DELETE_MESSAGE_FAILED';

export const GET_LINKPREVIEW = 'GET_LINKPREVIEW';
export const GET_LINKPREVIEW_SUCCESS = 'GET_LINKPREVIEW_SUCCESS';
export const GET_LINKPREVIEW_FAILED = 'GET_LINKPREVIEW_FAILED';

export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT';
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS';
export const UPLOAD_ATTACHMENT_FAILED = 'UPLOAD_ATTACHMENT_FAILED';

export const UPDATE_LATEST_MESSAGE_IN_ROOM = 'UPDATE_LATEST_MESSAGE_IN_ROOM';
export const UPDATE_LATEST_MESSAGE_IN_ROOM_SUCCESS = 'UPDATE_LATEST_MESSAGE_IN_ROOM_SUCCESS';
export const UPDATE_LATEST_MESSAGE_IN_ROOM_FAILED = 'UPDATE_LATEST_MESSAGE_IN_ROOM_FAILED';

export const DID_RECEIVE_LOBBY_MESSAGE = 'DID_RECEIVE_LOBBY_MESSAGE';
export const DID_RECEIVE_LOBBY_MESSAGE_SUCCESS = 'DID_RECEIVE_LOBBY_MESSAGE_SUCCESS';
export const DID_RECEIVE_LOBBY_MESSAGE_FAILED = 'DID_RECEIVE_LOBBY_MESSAGE_FAILED';

export const GET_UPLOADED_FILES = 'GET_UPLOADED_FILES';
export const GET_UPLOADED_FILES_SUCCESS = 'GET_UPLOADED_FILES_SUCCESS';
export const GET_UPLOADED_FILES_FAILED = 'GET_UPLOADED_FILES_FAILED';

export const GET_SHARED_POSTS = 'GET_SHARED_POSTS';
export const GET_SHARED_POSTS_SUCCESS = 'GET_SHARED_POSTS_SUCCESS';
export const GET_SHARED_POSTS_FAILED = 'GET_SHARED_POSTS_FAILED';

export const EDIT_CAPTION = 'EDIT_CAPTION';
export const EDIT_CAPTION_SUCCESS = 'EDIT_CAPTION_SUCCESS';
export const EDIT_CAPTION_FAILED = 'EDIT_CAPTION_FAILED';

export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAILED = 'DELETE_ATTACHMENT_FAILED';
