// @flow
import {
    GET_PLAYLISTDETAIL,
    GET_PLAYLISTDETAIL_SUCCESS,
    GET_PLAYLISTDETAIL_FAILED,
    CREATE_PLAYLIST,
    CREATE_PLAYLIST_SUCCESS,
    CREATE_PLAYLIST_FAILED,
    EDIT_PLAYLIST_DETAILS,
    EDIT_PLAYLIST_DETAILS_SUCCESS,
    EDIT_PLAYLIST_DETAILS_FAILED,
    DELETE_PLAYLIST,
    DELETE_PLAYLIST_SUCCESS,
    DELETE_PLAYLIST_FAILED,
    HEART_PLAYLIST,
    HEART_PLAYLIST_SUCCESS,
    HEART_PLAYLIST_FAILED,
    MOVE_PLAYLIST_SONG,
    MOVE_PLAYLIST_SONG_SUCCESS,
    MOVE_PLAYLIST_SONG_FAILED
    /*
    UP_VOTE,
    UP_VOTE_SUCCESS,
    UP_VOTE_FAILED,
    GET_REVIEWDETAILS,
    GET_REVIEWDETAILS_SUCCESS,
    GET_REVIEWDETAILS_FAILED,
    HIDE_REPLIES,
    HIDE_REPLIES_SUCCESS,
    HIDE_REPLIES_FAILED,
    UP_VOTE_REPLY,
    UP_VOTE_REPLY_SUCCESS,
    UP_VOTE_REPLY_FAILED*/
} from './constants';


export const getPlaylistDetail = (url_slug, isFirstPage, callbacks) => ({
    type: GET_PLAYLISTDETAIL,
    payload: url_slug, isFirstPage, callbacks
});

export const getPlaylistDetailSuccess = (user) => ({
    type: GET_PLAYLISTDETAIL_SUCCESS,
    payload: user,
});

export const getPlaylistDetailFailed = (error) => ({
    type: GET_PLAYLISTDETAIL_FAILED,
    payload: error,
});


export const createPlaylist = (playlist, callbacks) => ({
    type: CREATE_PLAYLIST,
    payload: {playlist, callbacks},
});

export const createPlaylistSuccess = (playlist) => ({
    type: CREATE_PLAYLIST_SUCCESS,
    payload: playlist,
});

export const createPlaylistFailed = (error) => ({
    type: CREATE_PLAYLIST_FAILED,
    payload: error,
});

export const editPlaylistDetails = (playlist, callbacks) => ({
    type: EDIT_PLAYLIST_DETAILS,
    payload: {playlist, callbacks},
});

export const editPlaylistDetailsSuccess = (playlistDetails, pk) => ({
    type: EDIT_PLAYLIST_DETAILS_SUCCESS,
    payload: playlistDetails, pk,
});

export const editPlaylistDetailsFailed = (error) => ({
    type: EDIT_PLAYLIST_DETAILS_FAILED,
    payload: error,
});

export const deletePlaylist = (playlistPk, callbacks) => ({
    type: DELETE_PLAYLIST,
    payload: {playlistPk, callbacks},
});

export const deletePlaylistSuccess = (playlistPk) => ({
    type: DELETE_PLAYLIST_SUCCESS,
    payload: playlistPk,
});

export const deletePlaylistFailed = (error) => ({
    type: DELETE_PLAYLIST_FAILED,
    payload: error,
});

export const heartPlaylist = (playlistPk, heart, callbacks) => ({
    type: HEART_PLAYLIST,
    payload: {playlistPk, heart, callbacks},
});

export const heartPlaylistSuccess = (action) => ({
    type: HEART_PLAYLIST_SUCCESS,
    payload: action,
});

export const heartPlaylistFailed = (error) => ({
    type: HEART_PLAYLIST_FAILED,
    payload: error,
});

export const movePlaylistSong = (params, callbacks) => ({
    type: MOVE_PLAYLIST_SONG,
    payload: {params, callbacks},
});

export const movePlaylistSongSuccess = (action) => ({
    type: MOVE_PLAYLIST_SONG_SUCCESS,
    payload: action,
});

export const movePlaylistSongFailed = (error) => ({
    type: MOVE_PLAYLIST_SONG_FAILED,
    payload: error,
});


/*
export const upVote = ({parent_review_pk, action}) => ({
    type: UP_VOTE,
    payload: {parent_review_pk, action},
});

export const upVoteSuccess = ({parent_review_pk, upVote}) => ({
    type: UP_VOTE_SUCCESS,
    payload: {parent_review_pk, upVote},
});

export const upVoteFailed = (error) => ({
    type: UP_VOTE_FAILED,
    payload: error,
});

export const getReviewDetails = (urlslug) => ({
    type: GET_REVIEWDETAILS,
    payload: urlslug,
});

export const getReviewDetailsSuccess = (vault) => ({
    type: GET_REVIEWDETAILS_SUCCESS,
    payload: vault,
});

export const getReviewDetailsFailed = (error) => ({
    type: GET_REVIEWDETAILS_FAILED,
    payload: error,
});

export const hideReplies = (parent_review_pk) => ({
    type: HIDE_REPLIES,
    payload: parent_review_pk,
});

export const hideRepliesSuccess = (parent_review_pk) => ({
    type: HIDE_REPLIES_SUCCESS,
    payload: parent_review_pk,
});

export const hideRepliesFailed = (error) => ({
    type: HIDE_REPLIES_FAILED,
    payload: error,
});


export const upVoteReply = ({parent_review_pk, parent_reply_pk, action}) => ({
    type: UP_VOTE_REPLY,
    payload: {parent_review_pk, parent_reply_pk, action},
});

export const upVoteReplySuccess = ({parent_review_pk, parent_reply_pk, upVote}) => ({
    type: UP_VOTE_REPLY_SUCCESS,
    payload: {parent_review_pk, parent_reply_pk, upVote},
});

export const upVoteReplyFailed = (error) => ({
    type: UP_VOTE_REPLY_FAILED,
    payload: error,
});
*/