// @flow
import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILED,

    MARK_AS_READ,
    MARK_AS_READ_SUCCESS,
    MARK_AS_READ_FAILED,

    GET_FRIENDSHIP_REQUESTS,
    GET_FRIENDSHIP_REQUESTS_SUCCESS, 
    GET_FRIENDSHIP_REQUESTS_FAILED,

} from './constants';


export const getNotifications = (url_slug) => ({
    type: GET_NOTIFICATIONS,
    payload: url_slug,
});

export const getNotificationsSuccess = (notifications) => ({
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: notifications,
});

export const getNotificationsFailed = (error) => ({
    type: GET_NOTIFICATIONS_FAILED,
    payload: error,
});




export const markAsRead = ( params, callbacks ) => ({
    type: MARK_AS_READ,
    payload: {params, callbacks},
});

export const markAsReadSuccess = (notifications) => ({
    type: MARK_AS_READ_SUCCESS,
    payload: notifications,
});

export const markAsReadFailed = (error) => ({
    type: MARK_AS_READ_FAILED,
    payload: error,
});



export const getFriendshipRequests = () => ({
    type: GET_FRIENDSHIP_REQUESTS,
    payload: null,
});

export const getFriendshipRequestsSuccess = (results) => ({
    type: GET_FRIENDSHIP_REQUESTS_SUCCESS,
    payload: results,
});

export const getFriendshipRequestsFailed = (error) => ({
    type: GET_FRIENDSHIP_REQUESTS_FAILED,
    payload: error,
});